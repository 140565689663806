import { Injectable } from '@angular/core'
import { StripeLimitEnforcementService } from './stripe-limit-enforcement.service'
import { filter, map, Subscription } from 'rxjs'
import { RouteHeaderUrl } from '../../../shared/components/one-header/header-navigation.model'
import { CampaignPluginName } from '../../../shared/models/campaign/campaign'
import { MatDialog } from '@angular/material/dialog'
import { ConfirmModalComponent } from '../../../shared/components/modals/confirm.modal/confirm.modal.component'
import { Router } from '@angular/router'
import { CampaignLocal } from '../../../pages/popups/services/campaigns-page.service'
import { CampaignCardValues } from '../../../shared/modules/campaign-cards/models/campaign-cards.model'
import { ApiDenoService } from '../api/api-deno.service'
import { UserService } from '../user.service'
import { SafeLocalStorageService } from '../safe-local-storage.service'

export interface UpgradePopupParams {
  factualAkc?: number
  subscribedToAkc?: number
  akcExceedsLimit?: boolean
  silent?: boolean
}
@Injectable({providedIn: 'root'})
export class LimitBlockerService {
  private subscription = new Subscription()
  private calledMethods = new Set<string>()

  private campaignCardValuesMapping = {
    [CampaignCardValues.Announcement]: RouteHeaderUrl.announcements,
    [CampaignCardValues.NewsLetterNotification]: RouteHeaderUrl.subscribe_forms,
    [CampaignCardValues.CouponBoxNotification]: RouteHeaderUrl.subscribe_forms,
    [CampaignCardValues.FreeShippingNotification]: RouteHeaderUrl.announcements,
    [CampaignCardValues.SalesNotification]: RouteHeaderUrl.announcements,
    [CampaignCardValues.ProductPopup]: RouteHeaderUrl.announcements,

    [CampaignCardValues.Gamification]: RouteHeaderUrl.subscribe_forms,
    [CampaignCardValues.FortuneWheel]: RouteHeaderUrl.subscribe_forms,
    [CampaignCardValues.SocialProof]: RouteHeaderUrl.social_proof,
    [CampaignCardValues.RecentSalesPop]: RouteHeaderUrl.social_proof,
    [CampaignCardValues.LiveVisitorCount]: RouteHeaderUrl.social_proof,
    [CampaignCardValues.AggregateSalesPop]: RouteHeaderUrl.social_proof,
    [CampaignCardValues.AggregateVisitorCount]: RouteHeaderUrl.social_proof,
    [CampaignCardValues.EmailAutomation]: RouteHeaderUrl.emails,
    [CampaignCardValues.CampaignEmails]: RouteHeaderUrl.emails,
    [CampaignCardValues.BroadcastEmails]: RouteHeaderUrl.emails,
    [CampaignCardValues.BroadcastCoupon]: RouteHeaderUrl.emails,
    [CampaignCardValues.BroadcastNewsletter]: RouteHeaderUrl.emails,
    [CampaignCardValues.BirthdayEmails]: RouteHeaderUrl.emails,
    [CampaignCardValues.UpsellEmails]: RouteHeaderUrl.emails,
    [CampaignCardValues.WinbackEmails]: RouteHeaderUrl.emails,
    [CampaignCardValues.CartRecoveryEmails]: RouteHeaderUrl.emails,

    [CampaignCardValues.UpsellCard]: RouteHeaderUrl.upsell,
    [CampaignCardValues.RecoveryCard]: RouteHeaderUrl.emails,
    [CampaignCardValues.RecommendationsCard]: RouteHeaderUrl.emails,
    [CampaignCardValues.BirthdayCard]: RouteHeaderUrl.emails,
    [CampaignCardValues.WinbackCard]: RouteHeaderUrl.emails,
    [CampaignCardValues.WelcomeNewSubscriberCard]: RouteHeaderUrl.emails,
    [CampaignCardValues.WelcomeNewCustomerCard]: RouteHeaderUrl.emails,
    [CampaignCardValues.WelcomeNewAccountsCard]: RouteHeaderUrl.emails,
    [CampaignCardValues.Welcome]: RouteHeaderUrl.emails,
    [CampaignCardValues.ThankYou]: RouteHeaderUrl.emails,
  }


  constructor(
    private stripeLimitEnforcementService: StripeLimitEnforcementService,
    private apiDenoService: ApiDenoService,
    private userService: UserService,
    private safeLocalStorageService: SafeLocalStorageService,
    private router: Router,
    private dialog: MatDialog,
  ) {
  }

  public resolveFeature(id: string) {
    // popups has different id's so this is a small workaround
    const featureNameMapping = {
      [RouteHeaderUrl.emails]: RouteHeaderUrl.emails,

      [RouteHeaderUrl.subscribe_forms]: RouteHeaderUrl.subscribe_forms,
      [CampaignPluginName.NewsLetterNotification]: RouteHeaderUrl.subscribe_forms,
      [CampaignPluginName.CouponBoxNotification]: RouteHeaderUrl.subscribe_forms,
      [CampaignPluginName.FortuneWheel]: RouteHeaderUrl.subscribe_forms,
      
      [RouteHeaderUrl.announcements]: RouteHeaderUrl.subscribe_forms,
      [CampaignPluginName.FreeShipping]: RouteHeaderUrl.subscribe_forms,
      [CampaignPluginName.ProductPopup]: RouteHeaderUrl.subscribe_forms,
      [CampaignPluginName.SalesPopup]: RouteHeaderUrl.subscribe_forms,

      [RouteHeaderUrl.social_proof]: RouteHeaderUrl.subscribe_forms,
      [CampaignPluginName.AggregateActivity]: RouteHeaderUrl.subscribe_forms,
      [CampaignPluginName.AggregateVisitorCount]: RouteHeaderUrl.subscribe_forms,
      [CampaignPluginName.LiveVisitorCount]: RouteHeaderUrl.subscribe_forms,
      [CampaignPluginName.RecentActivity]: RouteHeaderUrl.subscribe_forms,


      [RouteHeaderUrl.upsell]: RouteHeaderUrl.upsell,
      [RouteHeaderUrl.coupons]: RouteHeaderUrl.coupons,
      [RouteHeaderUrl.gift_cards]: RouteHeaderUrl.gift_cards,
      [RouteHeaderUrl.sms]: RouteHeaderUrl.sms,
    }
    return this.stripeLimitEnforcementService.feature$.pipe(
      filter(feature => feature !== null),
      map(feature => feature[featureNameMapping[id]])
    )
  }

  public validateFeatures() {
    this.subscription.add(
      this.stripeLimitEnforcementService.feature$.pipe(
        filter(feature => feature !== null)
      ).subscribe({
        next: (features) => {
          // Once app loads, go through features and check if any are blocked
          // Those that are blocked, should be processed separately 
          // where all of them would be switched off
          Object.keys(features).forEach(featureName => {
            if (features[featureName].blocked) {
              switch (featureName) {
                case RouteHeaderUrl.emails:
                  if (!this.calledMethods.has('emails')) {
                    this.deactivateEmails()
                    this.calledMethods.add('emails')
                  }
                  break
                case RouteHeaderUrl.subscribe_forms:
                  if (!this.calledMethods.has('popups')) {
                    this.deactivatePopups()
                    this.calledMethods.add('popups')
                  }
                  break
                case RouteHeaderUrl.upsell:
                  if (!this.calledMethods.has('upsell')) {
                    this.deactivateUpsellOffers()
                    this.calledMethods.add('upsell')
                  }
                  // Fetch all active upsell (pages) campaigns and disable them
                  break
                case RouteHeaderUrl.coupons:
                  if (!this.calledMethods.has('coupons')) {
                    this.calledMethods.add('coupons')
                  }
                  break
                case RouteHeaderUrl.gift_cards:
                  if (!this.calledMethods.has('gift_cards')) {
                    this.calledMethods.add('gift_cards')
                  }
                  break
                case RouteHeaderUrl.sms:
                  if (!this.calledMethods.has('sms')) {
                    this.calledMethods.add('sms')
                  }
                  break
              }
            }
          })
          this.calledMethods.clear()
        }
      })
    )
  }

  private deactivatePopups(popupParams?: UpgradePopupParams) {
    this.subscription.add(
      this.apiDenoService.post(`usage/pause/pop-ups`, {}).subscribe(res => {
        if (res || (popupParams?.akcExceedsLimit && !popupParams?.silent)) {
          // Show popup that user has reached the limit for popups with reload button
          this.showUpgradePopup(popupParams)
        }
      })
    )
  }

  private deactivateEmails(popupParams?: UpgradePopupParams) {
    this.subscription.add(
      this.apiDenoService.post(`usage/pause/emails`, {}).subscribe({
        next: (result) => {
          if (result || (popupParams?.akcExceedsLimit && !popupParams?.silent)) {
            // Show popup that user has reached the limit for emails with reload button
            this.showUpgradePopup(popupParams)
          }
        },
        error: (error) => {
          console.error(error)
        },
      })
    )
  }

  private deactivateUpsellOffers(popupParams?: UpgradePopupParams) {
    this.subscription.add(
      this.apiDenoService.post(`usage/pause/upsell`, {}).subscribe({
        next: (result) => {
          if (result || (popupParams?.akcExceedsLimit && !popupParams?.silent)) {
            // Show popup that user has reached the limit for emails with reload button
            this.showUpgradePopup(popupParams)
          }
        },
        error: (error) => {
          console.error(error)
        },
      })
    )
  }

  private isOverrideForAdmin(): boolean {
    return this.safeLocalStorageService.getItem('one-suppress-enforcement-popup') === this.userService?.userInfo?.id
  }

  public showUpgradePopup(popupParams?: UpgradePopupParams) {
    if (this.dialog?.openDialogs?.length && this.dialog.openDialogs[0].id === 'limit-reached') {
      return
    }
    if (popupParams?.silent || this.isOverrideForAdmin()) {
      return
    }
    const isStripeCustomer = this.userService.isStripeCustomer
    const isFreeV3Plan = this.userService.isNewFreeV3PlanUser
    let title, html, redirectURL
    if (isStripeCustomer) {
      title = 'Action Required | Limit Reached'
      html = `You've reached the limit for one of the features included in your current plan. 
        As a result, your active campaigns using this feature have been paused.
        <br> 
        <br> 
        <strong>
        Once your plan is upgraded, kindly remember to re-enable your campaigns to resume operations.
        </strong>
        <br> 
        <br> 
        If you have any questions or need assistance, feel free to reach out to our chat support or contact us via email support@one.store`
      redirectURL = `/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}/${RouteHeaderUrl.custom}`
    } else if (typeof popupParams?.factualAkc === 'number' && typeof popupParams?.subscribedToAkc === 'number' && !isFreeV3Plan) {
      title = 'Action Required | Limit Reached'
      html = `Your current Customer count (${ popupParams.factualAkc }) exceeds your plan's limit (${ popupParams.subscribedToAkc }). 
        As a result, your active campaigns have been paused. To continue running your campaigns, please upgrade to a higher plan.
        <br>
        <br>
        <strong>
        Once your plan is upgraded, kindly remember to re-enable your campaigns to resume operations.
        </strong>
        <br>
        <br>
        If you have any questions or need assistance, feel free to reach out to our chat support or contact us via email support@one.store`
      redirectURL = `/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}`
    } else {
      title = 'Action Required | Limit Reached'
      html = `Your plan does not support this feature. Please upgrade your plan to continue using it.
        As a result, your active campaigns have been paused. To continue running your campaigns, please upgrade to a higher plan.
        <br> 
        <br> 
        <strong>
        Once your plan is upgraded, kindly remember to re-enable your campaigns to resume operations.
        </strong>
        <br> 
        <br> 
        If you have any questions or need assistance, feel free to reach out to our chat support or contact us via email support@one.store`
      redirectURL = `/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}`
    }
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '530px',
      id: 'limit-reached',
      data: {
        title: title,
        html: html,
        disableCancel: true,
        acceptButton: {
          text: 'View Plans',
          classes: 'pf-button filled blue md',
        },
        extraButton: {
          text: 'Contact us',
          classes: 'pf-button outline black md',
          value: false
        },
      },
    })
    this.subscription.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate([redirectURL]).then(() => window.location.reload())
        } else if (!this.isOverrideForAdmin()) {
          const crisp = window['$crisp']
          crisp.push(['do', 'chat:open'])
          crisp.push(['do', 'message:send', ['text', 'I need help with upgrading my plan']])
        }
      })
    )
  }

  // For cases when popup campaign is archived or paused and user tries to publish it
  // but it includes a plugin that is blocked
  public popupCampaignCanBePublished(campaign: CampaignLocal): boolean {
    const blockerState = this.stripeLimitEnforcementService.feature$.value
    if (blockerState) {
      const subscribeFormsBlocked = blockerState[RouteHeaderUrl.subscribe_forms].blocked
      const hasSubscribeForms = campaign?.pluginTypes?.[CampaignPluginName.NewsLetterNotification] ||
                                campaign?.pluginTypes?.[CampaignPluginName.CouponBoxNotification] ||
                                campaign?.pluginTypes?.[CampaignPluginName.FortuneWheel]
      const hasAnnouncements = campaign?.pluginTypes?.[CampaignPluginName.FreeShipping] ||
                               campaign?.pluginTypes?.[CampaignPluginName.ProductPopup] ||
                               campaign?.pluginTypes?.[CampaignPluginName.SalesPopup]
      const hasSocialProof = campaign?.pluginTypes?.[CampaignPluginName.AggregateActivity] ||
                             campaign?.pluginTypes?.[CampaignPluginName.AggregateVisitorCount] ||
                             campaign?.pluginTypes?.[CampaignPluginName.LiveVisitorCount] ||
                             campaign?.pluginTypes?.[CampaignPluginName.RecentActivity]
      if (subscribeFormsBlocked && (hasSubscribeForms || hasAnnouncements ||  hasSocialProof)) {
        this.showUpgradePopup()
        return false
      }
    }
    return true
  }

  public appCardShouldBeBlocked(cardValue: CampaignCardValues): boolean {
    const blockerState = this.stripeLimitEnforcementService.feature$.value
    if (blockerState) {
      const featureName = this.campaignCardValuesMapping[cardValue]
      if (featureName) {
        return blockerState[featureName].blocked
      }
    }
    return false
  }

  public deactivateAll(popupParams?: UpgradePopupParams) {
    this.deactivateEmails(popupParams)
    this.deactivatePopups(popupParams)
    this.deactivateUpsellOffers(popupParams)
  }

}
