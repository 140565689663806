import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { filter, Subscription } from 'rxjs'
import { RouteHeaderUrl } from '../../header-navigation.model'
import { EmailsService } from '../../../../../pages/emails/services/emails.service'
import { DashboardAltApiService } from '../../../../../pages/dashboard-alt/services/dashboard-alt-api.service'
import { OneIconComponent } from '../../../one-icon/one-icon.component'
import { OneTooltipDirective } from '../../../../directives/one-tooltip.directive'
import { Router, RouterModule } from '@angular/router'
import { FeatureStatus, StripeLimitEnforcementService } from '../../../../../core/services/enforcement/stripe-limit-enforcement.service'

export enum LimitType {
  limitBased = 'limitBased',
  creditBased = 'creditBased',
}

export interface LimitItem {
  title: string
  id: RouteHeaderUrl
  icon: string
  value?: {used: number, percent: number, limit: number, credits?: number}
  buttonText: string
  upgradeUrl?: string
  redirectUrl?: string
  groupWith?: any
  titleWhenGrouped?: string
  blocked?: boolean
  type?: LimitType
  isDanger?: boolean
  isWarning?: boolean
  isEmpty?: boolean
}

@Component({
    selector: 'one-main-nav-limits',
    imports: [
        CommonModule,
        OneIconComponent,
        OneTooltipDirective,
        RouterModule,
    ],
    providers: [
        EmailsService,
        DashboardAltApiService,
    ],
    templateUrl: './one-main-nav-limits.component.html',
    styleUrl: './one-main-nav-limits.component.scss'
})
export class OneMainNavLimitsComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription()
  displayLimitsConfig: LimitItem[] = [
    {
      title: 'Emails',
      id: RouteHeaderUrl.emails,
      icon: 'mail-outline',
      redirectUrl: `/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.emails}/${RouteHeaderUrl.campaigns}`,
      type: LimitType.limitBased,
      buttonText: 'Upgrade',
      isDanger: false,
      isWarning: false,
    },
    {
      title: 'Pop Ups',
      id: RouteHeaderUrl.subscribe_forms,
      icon: 'albums-outline',
      redirectUrl: `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}`,
      type: LimitType.limitBased,
      buttonText: 'Upgrade',
      isDanger: false,
      isWarning: false,
      titleWhenGrouped: 'Subscribe Forms',
      // groupWith: [
      //   {
      //     id: RouteHeaderUrl.announcements,
      //     title: 'Announcements',
      //     icon: 'albums-outline',
      //     buttonText: 'Upgrade',
      //     type: LimitType.limitBased,
      //     isDanger: false,
      //     isWarning: false,
      //   }, 
      //   {
      //     id: RouteHeaderUrl.social_proof,
      //     title: 'Social Proof',
      //     icon: 'albums-outline',
      //     buttonText: 'Upgrade',
      //     type: LimitType.limitBased,
      //     isDanger: false,
      //     isWarning: false,
      //   },
      // ],
    },
    {
      title: 'Upsell Pages',
      id: RouteHeaderUrl.upsell,
      icon: 'cart-outline',
      redirectUrl: `/${RouteHeaderUrl.upsell}`,
      type: LimitType.limitBased,
      buttonText: 'Upgrade',
      isDanger: false,
      isWarning: false,
    },
    // {
    //   title: 'Coupons',
    //   id: RouteHeaderUrl.coupons,
    //   icon: 'ticket-outline',
    //   redirectUrl: `/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.coupons}`,
    //   type: LimitType.limitBased,
    // },
    // {
    //   title: 'Gift Cards',
    //   id: RouteHeaderUrl.gift_cards,
    //   icon: 'card-outline',
    //   redirectUrl: `/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.gift_cards}`,
    //   type: LimitType.limitBased,
    // },
    {
      title: 'SMS Credits',
      id: RouteHeaderUrl.sms,
      icon: 'chatbox-ellipses-outline',
      redirectUrl: `/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.sms}/${RouteHeaderUrl.campaigns}`,
      type: LimitType.creditBased,
      buttonText: 'Buy More Credits',
      isDanger: false,
      isWarning: false,
    }
  ]
  LimitType = LimitType
  
  constructor(
    private router: Router,
    private stripeLimitEnforcementService: StripeLimitEnforcementService,
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.stripeLimitEnforcementService.feature$.pipe(
        filter(feature => feature !== null),
      ).subscribe((feature: {[key: string]: FeatureStatus}) => {
        this.displayLimitsConfig.forEach(item => {
          if (feature.hasOwnProperty(item.id)) {
            this.applyStats(item, feature)
          }
          // if (item.hasOwnProperty('groupWith')) {
          //   item.groupWith?.forEach(groupItem => {
          //     if (feature.hasOwnProperty(groupItem.id)) {
          //       this.applyStats(groupItem, feature)
          //     }
          //   })
          // }
        })
        // Store config in shared service so it can be reused by custom plans page
        this.stripeLimitEnforcementService.displayLimitsConfig$.next(this.displayLimitsConfig)
      })
    )
  }

  applyStats(item: LimitItem, feature: {[key: string]: FeatureStatus}) {
    item.value = this.getValue(feature[item.id].used, feature[item.id].limit, item.type) 
    item.upgradeUrl = feature[item.id].upgradeUrl
    item.blocked = feature[item.id].blocked
    item.isWarning = item.value?.percent > -1 && item.value?.percent < 50
    item.isDanger = item.value?.percent > -1 && item.value?.percent < 25
    item.isEmpty = item.value?.percent === 0 && item.value?.used !== 0
    if (feature[item.id].used === 0 && feature[item.id].limit === 0) {
      item.blocked = false
      item.isWarning = false
      item.isDanger = false
      item.isEmpty = false
    }
  }

  upgrade(url: string) {
    this.router.navigate([url])
  }

  getValue(used: number, limit: number, type: LimitType): {used: number, percent: number, limit: number, credits?: number} {
    used = Number(used)
    limit = Number(limit)
    if (isNaN(used) || isNaN(limit)) {
      return {
        percent: 0,
        used: 0,
        limit: limit,
      }
    }
    const percent = 100 - (used / limit * 100)
    const creditBasedLimit = (limit - used > 0) ? limit - used : 0
    if (type === LimitType.limitBased) {
      return {
        percent: percent > 0 ? percent : 0,
        used: used,
        limit: limit,
      }
    } else if (type === LimitType.creditBased) {
      return {
        percent: 0,
        used: used,
        limit: limit,
        credits: creditBasedLimit,
      }
    }
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
