import { LinkedTypeId } from './../../../../core/services/api/api-rewards.service'
import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal'
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Actions, ofType } from '@ngrx/effects'
import { Subscription } from 'rxjs'
import { NewCouponCodesService } from '../../../../pages/coupon-codes/services/new-coupon-code.service'
import {
  CouponCode,
  CouponCodeState,
  CouponCodeType,
  CouponStatus,
  ShopifyCoupon,
  UICouponCodeTypeName,
} from '../../../../pages/coupon-codes/models/coupon-code'
import { Logger } from '../../../../core/services/logger.service'
import { CONTAINER_DATA } from '../../../../pages/coupon-codes/services/overlay-container.data'
import { OverlayCouponCodeComponent } from '../../../../pages/coupon-codes/components/overlay-coupon-code/overlay-coupon-code.component'
import { select, Store } from '@ngrx/store'
import { StoreState } from '../../../../store/store.state'
import { GetUserCouponsRequest } from '../../../../store/user/user.actions'
import { UserActionTypes } from '../../../../store/user/user.action-types'
import * as _ from 'lodash'
import { getUserShopType } from '../../../../store/user/user.selectors'
import { filter } from 'rxjs/operators'
import { CouponsImportComponent } from '../../../modules/coupons-import/coupons-import.component'
import { UserShopType } from '../../../models/user/user-shop-type.model'
import { WooCommerceCouponService } from '../../../../pages/coupon-codes/components/new-coupon-code/components/woo-commerce-coupon/services/woo-commerce-coupon.service'
import { WooCommerceCoupon } from '../../../../pages/coupon-codes/components/new-coupon-code/components/woo-commerce-coupon/interfaces/woo-commerce-coupon.interface'
import { RewardsService } from '../../../../core/services/rewards-service.service'
import { LogLabel } from '../../../models/logger/log-label.model'
import { GiftCard, GiftCardStatus } from '../../../../pages/gift-cards/models/gift-card'
import { RewardsStatus, RewardsType } from '../../../../pages/coupon-codes/models/rewards-model'
import { OverlayGiftCardComponent } from '../../../../pages/gift-cards/components/overlay-gift-card/overlay-gift-card.component'
import { UserService } from '../../../../core/services/user.service'
import { CampaignPluginName } from '../../../models/campaign/campaign'
import { ActivatedRoute } from '@angular/router'
import { CommonModule } from '@angular/common';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator'
import { OneIconComponent } from '../../one-icon/one-icon.component';
import { MatDialog } from '@angular/material/dialog'

interface RewardSelectItem extends CouponCode {
  texts: {
    discount?: string
    limit?: string
    state?: CouponCodeState
    dateStart?: string
    dateEnd?: string
    type?: string
  }
}

@Component({
    selector: 'pf-reward-select',
    templateUrl: './reward-select.component.html',
    styleUrls: ['./reward-select.component.scss'],
    providers: [
        NewCouponCodesService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RewardSelectComponent),
            multi: true,
        },
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        MatPaginatorModule,
        OneIconComponent,
    ]
})
export class RewardSelectComponent implements ControlValueAccessor, OnChanges, OnDestroy, OnInit {
  @Input() rewardCodeList: CouponCode[] | GiftCard[] | any[]
  @Input() rewardType: RewardsType = RewardsType.coupon_code
  @Input() linked: { id: string, type: LinkedTypeId } = { id: null, type: LinkedTypeId.notificationId}
  @Input() itemsPerPage: number = 25;
  @Input() itemsQuantity: number = 0;
  @Input() readonly: any
  @Input() dropdownPosition: 'bottom' | 'top' | 'auto' = 'auto'
  @Input() short: boolean = false
  @Input() appendTo: 'body' | null = null
  @Input() noHeader: boolean = false
  @Input() clearable: boolean = true;
  @Input() clearOnBackspace: boolean = false;
  @Input() customClass: string = ''
  @Input() disabled: boolean = false
  @Input() missingReward: boolean = false
  @Input() expiredCoupon: boolean = false
  @Input() label: string = ''
  @Input() searchable: boolean = true
  @Output() change = new EventEmitter<{value: string}>()
  @Output() rewardChanged = new EventEmitter<CouponCode | GiftCard>()
  @Output() rewardCreated = new EventEmitter<object>()
  @Output() createClicked = new EventEmitter<any>()
  @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent
  resizeTimeout: any
  isTyping: boolean = false

  uniqueShopCouponsArray = [
    CouponCodeType.woo_commerce_unique,
    CouponCodeType.big_commerce_unique,
    CouponCodeType.shopify_unique,
    CouponCodeType.shopify_gift_card_unique
  ]
  masterShopCouponsArray = [
    CouponCodeType.woo_commerce_master,
    CouponCodeType.woo_commerce_master,
    CouponCodeType.shopify_master,
    CouponCodeType.shopify_gift_card // may change in future
  ]
  manualCoupons = [
    CouponCodeType.manual_master,
    CouponCodeType.manual_unique
  ]

  currentPage: number = 1;

  couponCodeState = CouponCodeState
  giftCardCodeState = GiftCardStatus // may need in future

  rewardSelectItems: RewardSelectItem[] = []

  currency: string

  private _value: any
  private _onChange: any
  isDisabled = false
  isInvalid = false
  isEmpty = false // may need in future
  isLoading: boolean = false

  createdReward: CouponCode | GiftCard
  overlayRef: OverlayRef

  private subscription: Subscription = new Subscription()

  shopType: UserShopType
  userShopType = UserShopType

  selectType = RewardsType

  polingTimeout: NodeJS.Timeout = null
  numPollAttempts: number = 0

  couponCount: number
  openOverlay = false

  @HostListener('window:resize', ['$event']) closeSelect(_event?: any) {
    if (!this.resizeTimeout && this.ngSelect && this.ngSelect.isOpen) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null
        this.ngSelect.close()
      }, 200)
    }
  }

  constructor(
    private newCouponCodesService: NewCouponCodesService,
    private wooCommerceCouponService: WooCommerceCouponService,
    private logger: Logger,
    private injector: Injector,
    public viewContainerRef: ViewContainerRef,
    private store: Store<StoreState>,
    private overlay: Overlay,
    private actions$: Actions,
    public dialog: MatDialog,
    private userService: UserService,
    private rewardsService: RewardsService,
    private route: ActivatedRoute
  ) {
    this.isLoading = true
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setInvalid(this.value)
    this.setEmpty(this.value)
    if (changes.rewardCodeList && changes.rewardCodeList.currentValue) {
      this.isLoading = false
      const user = this.userService.userInfo
      this.currency = _.get(user, 'info.shop.profile.currency', '')
      this.rewardSelectItems = this.adaptItems(this.rewardCodeList)
      this.logger.log(<LogLabel>'reward-select', 'rewardSelectItems:', this.rewardSelectItems)
    }
    if (changes.missingReward) {
      this.missingReward = _.get(changes.missingReward, 'currentValue', false)
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.actions$.pipe(ofType(UserActionTypes.GET_COUPONS_SUCCESS))
        .subscribe((data: any) => {
          if (this.createdReward) {
            this.value = this.createdReward.id
            this.setInvalid(this.value)
            this.setEmpty(this.value)
            this.createdReward = null
          }
        }),
    )
    if (this.short) {
      // necessary to make dropdown shorter
      // [class.short] or class="{{short}}" on ng-select doesn't work
      document.body.classList.add('_ng-select-custom-short')
    }

    this.subscription.add(this.store.pipe(
      select(getUserShopType),
      filter((next) => !!next),
    ).subscribe(next => {
      this.shopType = next
    }))
  }


  setInvalid(value) {
    if (value && this.rewardCodeList && this.itemsQuantity > 0) {
      const currentReward = this.rewardCodeList.find(reward => reward.id === value)
      this.isInvalid = currentReward && currentReward.status !== RewardsStatus.active
    } else {
      this.isInvalid = false
    }
  }

  setEmpty(value) {
    this.isEmpty = value && this.rewardSelectItems.find(reward => reward.id === value) === undefined
  }

  adaptItems(rewards: CouponCode[] | GiftCard[]): RewardSelectItem[] {
    const items = []
    if (this.rewardType === RewardsType.coupon_code) {
      if (this.rewardSelectItems) {
        rewards.forEach(coupon => {
          const dateStart = (coupon as WooCommerceCoupon).data ? (coupon as WooCommerceCoupon).data.starts_at : null
          const dateEnd = (coupon as WooCommerceCoupon).data ? (coupon as WooCommerceCoupon).data.ends_at : null
          const state = this.wooCommerceCouponService.couponStatus(coupon as WooCommerceCoupon)
          items.push({
            ...coupon,
            texts: {
              discount: this.wooCommerceCouponService.getDiscountText(coupon, this.currency),
              limit: this.wooCommerceCouponService.summaryUsageLimit(coupon as WooCommerceCoupon),
              state: state,
              type: this.wooCommerceCouponService.getCouponTypeName(coupon.type),
              dateStart: this.wooCommerceCouponService.summaryDate('', dateStart, 'L'),
              dateEnd: this.wooCommerceCouponService.summaryDate('', dateEnd, 'L'),
            },
            disabled: state === CouponCodeState.expired,
          })
        })
      } else {
        rewards.forEach(coupon => {
          const dateStart = (coupon as ShopifyCoupon).data ? (coupon as ShopifyCoupon).data.starts_at : null
          const dateEnd = (coupon as ShopifyCoupon).data ? (coupon as ShopifyCoupon).data.ends_at : null
          const state = this.newCouponCodesService.couponStatus(coupon as ShopifyCoupon)
          items.push({
            ...coupon,
            texts: {
              discount: this.newCouponCodesService.getDiscountText(coupon, this.currency),
              limit: this.newCouponCodesService.summaryUsageLimit(coupon as ShopifyCoupon),
              state: state,
              type: this.newCouponCodesService.getCouponTypeName(coupon.type),
              dateStart: this.newCouponCodesService.summaryDate('', dateStart, 'L'),
              dateEnd: this.newCouponCodesService.summaryDate('', dateEnd, 'L'),
            },
            disabled: state === CouponCodeState.expired,
          })
        })
      }
    }
    if (this.rewardType === RewardsType.gift_card) {
      rewards.forEach(reward => {
        items.push({
          ...reward,
          texts: {
            discount: reward.value + this.currency,
            state: reward.status,
            type: UICouponCodeTypeName.ShopifyUniqueGiftCard,
          },
          disabled: reward.status === GiftCardStatus.disabled
        })
      })
    }
    return items
  }

  writeValue(value) {
    this._value = value
    this.setInvalid(value)
    this.logger.log(<LogLabel>'reward-select', 'writeValue:', value)
  }

  registerOnChange(fn) {
    this._onChange = fn
  }

  registerOnTouched(fn) {
  }

  set value(value) {
    this.logger.log(<LogLabel>'reward-select', 'setValue:', value)
    if (!this.isDisabled) {
      if (this.readonly === undefined || this.readonly === false) {
        this._value = value
        this._onChange && this._onChange(value)
      }
      this.change.emit({ value })
    } else {
      this._value = 'SELECTED REWARD IS DISABLED'
    }
  }

  get value() {
    return this._value
  }

  selected(reward) {
    this.value = (reward as CouponCode | GiftCard)?.id
    this.rewardChanged.emit(reward as CouponCode | GiftCard)
    this.setInvalid(this.value)
    this.setEmpty(this.value)
    this.ngSelect && this.ngSelect.blur()
  }

  onOpen() {
    this.isTyping && this.fetchRewards()
    this.openOverlay = true
  }

  onClose() {
    this.openOverlay = false
  }

  onClear() {
    this.isTyping && this.fetchRewards()
  }

  fetchRewards(page = this.currentPage) {
    switch (this.rewardType) {
      case RewardsType.coupon_code:
        if (this.linked?.id) {
          const infoPopupCodeParams = this.infoPopupCouponCodeParams
          this.rewardsService.getCouponCodes({
            page,
            limit: this.itemsPerPage,
            status: CouponStatus.active,
            orderBy: 'linked',
            [this.linked.type]: this.linked.id,
            type: infoPopupCodeParams?.type || null,
            target_type: infoPopupCodeParams?.target_type || null
          })
        } else {
          this.rewardsService.getCouponCodes({ page, limit: this.itemsPerPage, status: CouponStatus.active })
        }
        break
      case RewardsType.gift_card:
        if (this.linked?.id) {
          this.rewardsService.getGiftCardCodes({
            page,
            limit: this.itemsPerPage,
            status: GiftCardStatus.active,
            orderBy: 'linked',
            [this.linked.type]: this.linked.id
          })
        } else {
          this.rewardsService.getGiftCardCodes({ page, limit: this.itemsPerPage, status: GiftCardStatus.active })
        }
        break
    }
  }

  get infoPopupCouponCodeParams() {
    const pluginType = this.route?.snapshot.parent?.data?.pluginType

    if (![CampaignPluginName.FreeShipping, CampaignPluginName.SalesPopup].includes(pluginType)) {
      return null
    }
    const storeType = this.userService.userInfo?.shop?.type
    let couponType
    let targetType = null
    switch (storeType) {
      case UserShopType.ShopifyShop:
        couponType = CouponCodeType.shopify_master
        targetType = pluginType === CampaignPluginName.FreeShipping ? 'shipping_line' : null
        break
      case UserShopType.BigCommerceShop:
        couponType = CouponCodeType.big_commerce_master
        targetType = pluginType === CampaignPluginName.FreeShipping ? 'shipping_line' : null
        break
      case UserShopType.WooCommerceShop:
        couponType = CouponCodeType.woo_commerce_master
        break
      default:
        couponType = CouponCodeType.manual_master
    }
    return { type: couponType, target_type: targetType }
  }

  search(event) {
    const query = event.term
    if (query) {
      this.isTyping = true
      this.currentPage = 1
      switch (this.rewardType) {
        case RewardsType.coupon_code:
          const infoPopupCodeParams = this.infoPopupCouponCodeParams
          this.rewardsService.getCouponCodes({
            limit: 999,
            query,
            target_type: infoPopupCodeParams?.target_type || null,
            type: infoPopupCodeParams?.type || null,
          })
          break
        case RewardsType.gift_card:
          this.rewardsService.getGiftCardCodes({ limit: 999, query })
          break
      }
    } else {
      this.isTyping = false
    }
  }

  createCouponCodeClicked() {
    this.createClicked.emit(null)
  }

  openCouponOverlay() {
    this.overlayRef = this.overlay.create({
      panelClass: ['L-App-Overlay', !this.noHeader ? '_with-header' : ''],
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    })
    const portal = new ComponentPortal(OverlayCouponCodeComponent, this.viewContainerRef,
      this.createInjector({ data: '' }, this.overlayRef),
    )
    const compRef = this.overlayRef.attach(portal)

    // If header should be visible add class to overlay container
    // that will lower its Z index below that of the header
    if (!this.noHeader) {
      document.querySelector('.cdk-overlay-container').classList.add('L-App-UnderBar')
    }
    this.subscription.add(compRef.instance.closeOverlay
      .subscribe((res: CouponCode) => {
        if (res) {
          this.fetchRewards(1)
          this.createdReward = res
          this.store.dispatch(new GetUserCouponsRequest())
          this.rewardCreated.emit(res);
        } else {
          this.createdReward = null
        }
        // remove the low z class so it doesn't mess with full height overlays
        document.querySelector('.cdk-overlay-container').classList.remove('L-App-UnderBar')
        this.overlayRef.detach()
      }))
    this.ngSelect && this.ngSelect.blur()
    this.ngSelect && this.ngSelect.close()
  }

  openGiftCardOverlay() {
    this.overlayRef = this.overlay.create({
      panelClass: ['L-App-Overlay', ''],
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    })
    const portal = new ComponentPortal(OverlayGiftCardComponent, this.viewContainerRef,
      this.createInjector({ data: '' }, this.overlayRef),
    )
    const compRef = this.overlayRef.attach(portal)

    // If header should be visible add class to overlay container
    // that will lower its Z index below that of the header
    if (!this.noHeader) {
      document.querySelector('.cdk-overlay-container').classList.add('L-App-UnderBar')
    }
    this.subscription.add(compRef.instance.closeOverlay
      .subscribe((res: GiftCard) => {
        if (res) {
          this.createdReward = res
          this.rewardCreated.emit(res);
          this.value = res.id
        } else {
          this.createdReward = null
        }
        // remove the low z class so it doesn't mess with full height overlays
        document.querySelector('.cdk-overlay-container').classList.remove('L-App-UnderBar')
        this.overlayRef.detach()
      }))
    this.ngSelect && this.ngSelect.blur()
    this.ngSelect && this.ngSelect.close()
  }

  openCouponImport() {
    const dialogRef = this.dialog.open(CouponsImportComponent, {
      width: '600px',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      disableClose: true,
      data: { dest: 'Dropdown' },
    })
    this.ngSelect && this.ngSelect.close()
    this.couponCount = this.itemsQuantity
    this.subscription.add(dialogRef.afterClosed()
      .subscribe((result) => {
        if (_.get(result, 'imported', false)) {
          this.store.dispatch(new GetUserCouponsRequest())

          const importedCount = _.get(result, 'count', false)

          // this.rewardsService.getCouponCodes({
          //   page: 1,
          //   limit: this.itemsPerPage,
          //   status: CouponStatus.active,
          // })
          this.fetchRewards(1)

          this.ngSelect && this.ngSelect.open()
          if (this.couponCount === this.itemsQuantity) {
            this.numPollAttempts = 0
            this.pollCoupons(importedCount)
          }
        }
      }))
  }

  private pollCoupons(importedCount = 0, timeout = 500) {
    this.polingTimeout && clearTimeout(this.polingTimeout)

    // this.rewardsService.getCouponCodes({
    //   page: 1,
    //   limit: this.itemsPerPage,
    //   status: CouponStatus.active,
    // })
    this.fetchRewards(1)

    this.polingTimeout = setTimeout(() => {
      const diff = this.itemsQuantity - this.couponCount
      this.couponCount = this.itemsQuantity
      if (diff < importedCount && this.numPollAttempts < 20) {
        this.pollCoupons(importedCount - diff, timeout + 500)
      }
    }, timeout)

    this.numPollAttempts += 1
  }

  onPageChanged(n: PageEvent) {
    this.currentPage = n.pageIndex + 1;
    this.fetchRewards(this.currentPage)
  }

  createInjector(data: any, overlayRef: OverlayRef): PortalInjector {
    const injectorTokens = new WeakMap()
    injectorTokens.set(OverlayRef, overlayRef)
    injectorTokens.set(CONTAINER_DATA, data)
    return new PortalInjector(this.injector, injectorTokens)
  }

  ngOnDestroy() {
    // fetch reward again in case it was deleted by other pages
    this.fetchRewards(1)
    this.polingTimeout && clearTimeout(this.polingTimeout)
    this.subscription.unsubscribe()
    this.overlayRef && this.overlayRef.detach()
    document.body.classList.remove('_ng-select-custom-short')
    document.querySelector('.cdk-overlay-container') && document.querySelector('.cdk-overlay-container').classList.remove('L-App-UnderBar')
  }
}
