import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ErrorHandler, Injectable, NgModule, inject } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
// import { Angulartics2Module } from 'angulartics2'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { LoginComponent } from './auth/login/login.component'
import { environment } from '../environments/environment'
import { RegisterComponent } from './auth/register/register.component'
import { ResetComponent } from './auth/reset/reset.component'
import { NgxStripeModule } from 'ngx-stripe'
import { LayoutModule } from './layout/layout.module'
import { ShopifyComponent } from './auth/shopify/shopify.component'
import { LayoutComponent } from './auth/layout/layout.component'
import { WoocommerceComponent } from './auth/woocommerce/woocommerce.component'
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { storeReducers } from './store/store.reducers'
import { storeEffects } from './store/store.effects'
import { ShopifyOauthGuard } from './core/services/shopify-oauth.service'
import { campaignReducer } from './store/campaign/campaign.reducer'
import { UserActionTypes } from './store/user/user.action-types'
import { initialStoreState } from './store/store.state'
import { CookieService } from 'ngx-cookie-service'
import { ConstantContactComponent } from './auth/shopify/mail_system_integrations/constantcontact/constantcontact.component'
import { FullScreenService } from './core/services/full-screen.service'

import { EnvironmentName } from '../environments/models/environment-name.model'
import { OverlayModule } from '@angular/cdk/overlay'
import * as Sentry from '@sentry/angular-ivy'
import { CampaignCardsModule } from './shared/modules/campaign-cards/campaign-cards.module'
import { CannyLoginComponent } from './auth/canny/canny.component'
import { CustomSnackbarModule } from './shared/modules/custom-snackbar/custom-snackbar.module'
import { CampaignDataService } from './core/services/data-services/campaign-data.service'
import { NgHcaptchaModule } from 'ng-hcaptcha'
import { LoginWithPasswordComponent } from './auth/with-password/with-password.component'
import { CoreModule } from './core/core.module'
import { GettingStartedHeaderService } from './core/services/getting-started-header.service'
import { RedirectService } from './core/services/redirect.service'
import { JotFormModule } from './shared/modules/jotform/jotform.module'
import { MonacoEditorModule } from '@dmlukichev/ngx-monaco-editor'
import * as Hammer from 'hammerjs'
import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser'
import { VipOnboardingModule } from './pages/vip-onboarding/vip-onboarding.module'
import { SegmentAnalyticsService } from './shared/services/segment-analytics.service'
import { SMSService } from './shared/services/sms.service'
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs'
import { OneIconComponent } from './shared/components/one-icon/one-icon.component'
import { LoadingOverlayComponent } from './shared/components/loading-overlay/loading-overlay.component'
import { BackupService } from './shared/services/backup-service/backup.service'
import { UpdateNotifierService } from './core/services/update-notifier.service'
import { OneHeaderComponent } from './shared/components/one-header/one-header.component'
import { OneHeaderService } from './shared/components/one-header/services/one-header.service'
import { MultiSaveGuard } from './shared/components/one-header/services/multi-save.guard'
import { OneSideNavComponent } from './shared/components/one-side-nav/one-side-nav.component'
import { IframeHelperService } from './shared/services/iframe.service'
import { SideNavService } from './shared/components/one-side-nav/services/one-side-nav.service'
import { BreakpointService } from './core/services/breakpoint.service'
import { ShortNumberPipe } from './shared/pipes/short-number.pipe'
import { StripePlanGuardService } from './pages/cart/services/stripe-plan-guard.service'
import { DecimalPipe } from '@angular/common'
// import { OneBannerComponent } from './shared/components/one-banner/one-banner.component'
import { MatTooltipModule } from '@angular/material/tooltip'
import { OnePageTitleComponent } from './shared/components/page-title/page-title.component'
import { PageTitleService } from './shared/components/page-title/services/page-title.service'
import { PromoService } from './shared/modules/promo/services/promo.service'
import { StripeRedirectGuardService } from './pages/cart/services/stripe-redirect-guard.service'
import { ShopifyLimitEnforcementService } from './core/services/enforcement/shopify-limit-enforcement.service'
import { JsonBinApiService } from './core/services/jsonbin-api.service'
import { StripeLimitEnforcementService } from './core/services/enforcement/stripe-limit-enforcement.service'
import { LimitBlockerService } from './core/services/enforcement/limit-blocker.service'
import { ApiDenoService } from './core/services/api/api-deno.service'
import { AdminToolsComponent } from './shared/components/admin-tools/admin-tools.component'

declare global {
  interface Window {
    analytics: any;
  }
}

if (environment.name !== EnvironmentName.DEVELOPMENT && !environment.server_running) {
  Sentry.init({
    dsn: 'https://471d5149457f4546aaf1112628d54489@sentry.io/1757208',
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'Non-Error exception captured with keys: error, headers, message, name, ok'],
    environment: environment.name,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // replaysOnErrorSampleRate: 1.0,
    integrations: [
      // new Sentry.Replay({
      //   maskAllText: false
      // }),
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        tracingOrigins: ['localhost', 'https://app.prooffactor.local', 'https://app.prooffactor.com', 'https://app-develop.prooffactor.com', 'https://app-staging.prooffactor.com'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: environment.name !== EnvironmentName.PRODUCTION ? 1.0 : 0.1,
  })
}

const keys = Object.keys(initialStoreState)

// export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
//   return localStorageSync({ keys, rehydrate: false, removeOnUndefined: true })(reducer)
// }

export function clearState(reducer) {
  return function(state, action) {
    if (action.type === UserActionTypes.LOGOUT) {
      state = initialStoreState
    }
    return reducer(state, action)
  }
}

const metaReducers: Array<MetaReducer<any, any>> = [clearState]

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  }
}
export class SentryErrorHandler implements ErrorHandler {
  private updateNotifierService = inject(UpdateNotifierService)
  handleError(error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/

    if (chunkFailedMessage.test(error.message)) {
      this.updateNotifierService.displayRefreshPrompt()
    }

    console.error(`handleError: `, error)
  }
}

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        LoginWithPasswordComponent,
        CannyLoginComponent,
        RegisterComponent,
        ResetComponent,
        ShopifyComponent,
        ConstantContactComponent,
        WoocommerceComponent,
        LayoutComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        // FirebaseUIModule.forRoot(firebaseUiAuthConfig),
        CoreModule.forRoot(),
        NgxStripeModule.forRoot(environment.stripe.publishableKey),
        LayoutModule,
        OverlayModule,
        MonacoEditorModule.forRoot(),
        OneIconComponent,
        MatTooltipModule,
        LoadingOverlayComponent,
        OneHeaderComponent,
        // OneBannerComponent,
        OneSideNavComponent,
        OnePageTitleComponent,
        // MonacoEditorModule.forRoot({
        //   onMonacoLoad() {
        //     monaco.languages.css.cssDefaults.setDiagnosticsOptions({
        //       validate: false,
        //     })
        //   },
        // } as NgxMonacoEditorConfig),
        StoreModule.forRoot(storeReducers, { metaReducers }),
        EffectsModule.forRoot(storeEffects),
        !environment.production ? StoreDevtoolsModule.instrument({ monitor: campaignReducer }) : [],
        // Angulartics2Module.forRoot({
        //     gst: {
        //         trackingIds: [environment.googleAnaltyicsToken],
        //     },
        // }),
        CampaignCardsModule,
        CustomSnackbarModule,
        NgHcaptchaModule.forRoot(),
        JotFormModule,
        HammerModule,
        VipOnboardingModule,
        AdminToolsComponent], providers: [
        ShopifyOauthGuard,
        CookieService,
        GettingStartedHeaderService,
        RedirectService,
        FullScreenService,
        CampaignDataService,
        SegmentAnalyticsService,
        BackupService,
        OneHeaderService,
        MultiSaveGuard,
        SMSService,
        SideNavService,
        UpdateNotifierService,
        IframeHelperService,
        JsonBinApiService,
        PageTitleService,
        BreakpointService,
        PromoService,
        ShortNumberPipe,
        DecimalPipe,
        StripeRedirectGuardService,
        StripeLimitEnforcementService,
        ShopifyLimitEnforcementService,
        StripePlanGuardService,
        LimitBlockerService,
        ApiDenoService,
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                // fullLibraryLoader: 'highlight.js',
                coreLibraryLoader: () => import('highlight.js/lib/core'),
                languages: {
                    javascript: () => import('highlight.js/lib/languages/javascript'),
                    xml: () => import('highlight.js/lib/languages/xml')
                }
            }
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
