import { CurrencyPipe } from '@angular/common'
import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import moment from 'moment'
import * as _ from 'lodash'
import { forkJoin, Subscription } from 'rxjs'
import { UserService } from '../../core/services/user.service'
import { NewUpsellModalComponent } from '../../shared/components/modals/new-upsell.modal/new-upsell.modal.component'
import { CapitalizePipe } from '../../shared/pipes/capitalize.pipe'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { StoreState } from '../../store/store.state'
import { UpsellListItem, UpsellShippingType, UpsellStats, UpsellStatus } from './models/upsell-page.models'
import { UpsellPageDatasource } from './services/upsell.datasource'
import { UpsellService } from './services/upsell.service'
import { ConfirmModalComponent, ConfirmModalConfig } from '../../shared/components/modals/confirm.modal/confirm.modal.component'
import { CustomSnackbarService } from '../../shared/modules/custom-snackbar/custom-snackbar.service'
import { RenameModalComponent, RenameModalConfig } from '../../shared/components/modals/rename.modal/rename.modal.component'
import { SegmentAnalyticsService } from '../../shared/services/segment-analytics.service'
import { AccessFormModalComponent } from '../../shared/modules/jotform/components/access-form-modal/access-form-modal.component'
import { upsellAccessFormPreset } from '../../shared/modules/jotform/models/upsell-access-form-preset.model'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'
import { ApiService } from '../../core/services/api/api.service'
import { DateRangeLabel, MatDatepickerOutput } from '../../shared/modules/mat-daterange/models/mat-daterange.model'
import { MatDaterangeService } from '../../shared/modules/mat-daterange/services/mat-daterange.service'
import { ActiveAppCampaignService } from '../../core/services/active-app-campaign.service'
import { UpgradePlanHelperService } from '../../core/services/upgrade-plan-helper.service'
import { TutorialId } from '../tutorials/services/tutorials.service'
import { MatDialog } from '@angular/material/dialog'

@Component({
    selector: 'pf-upsell-page',
    templateUrl: './upsell-page.component.html',
    styleUrls: ['./upsell-page.component.scss'],
    standalone: false
})
export class UpsellPageComponent implements OnInit, OnDestroy {

  subscription$: Subscription = new Subscription()
  dataSource: UpsellPageDatasource
  upsellItems: UpsellListItem[] = []
  isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)
  RouteHeaderUrl = RouteHeaderUrl
  stats: UpsellStats = {
    views: 0,
    conversions: 0,
    conversion_rate: 0,
    average_order: 0,
    average_historic_value: 0,
    average_increase_rate: 0,
    total_revenue: 0,
  }

  defaultDateRange: DateRangeLabel = DateRangeLabel.AllTime
  startDate = this.matDaterangeService.getDateRangeByLabel(this.defaultDateRange).start
  endDate = this.matDaterangeService.getDateRangeByLabel(this.defaultDateRange).end

  filterModel: string = UpsellStatus.AllExceptArchived
  filterOptions = [
    { value: UpsellStatus.AllExceptArchived, title: 'All statuses' },
    { value: UpsellStatus.Live, title: 'Live' },
    { value: UpsellStatus.Off, title: 'Off' },
    // { value: UpsellStatus.Invalid, title: 'Broken' },
    { value: UpsellStatus.Archived, title: 'Archived' },
  ]

  tutorialVideoUrl = `/${RouteHeaderUrl.tutorials}/${TutorialId.Upsell}/WZYrIeaZpcY`

  weightOptions = [0, 1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 500, 1000, 2000, 2500, 5000, 7500, 10000, 12500, 15000, 17500, 20000, 25000, 50000, 75000, 100000]

  displayedColumns: string[] = [
    'priority',
    'name',
    'discount',
    'shipping',
    'weight',
    'display',
    'views',
    'conversions',
    'revenue',
    'status',
    'actions',
  ]

  badgeColorClass = {
    [UpsellStatus.Live]: 'green',
    [UpsellStatus.Off]: 'grey',
    [UpsellStatus.Archived]: 'grey',
    [UpsellStatus.Paused]: 'yellow',
    [UpsellStatus.Invalid]: 'red',
  }

  upsellStatus = UpsellStatus
  upsellShippingType = UpsellShippingType

  checkForSampleUpsell = true

  postPurchaseAppInUse = true

  userCurrency = this.userService.userInfo?.shop?.profile?.currency || 'USD'

  get checkoutPostPurchaseUrl() {
    let storeUrl = this.userService.userInfo?.shop?.store_url
    if (!storeUrl.includes('http')) {
      storeUrl = `https://${storeUrl}`
    }
    return `${storeUrl}/admin/settings/checkout#post-purchase`
  }

  updateSettings() {
    window.open(this.checkoutPostPurchaseUrl, '_blank')
  }

  get messageHtml() {
    return `<b>Required:</b>
    In Shopify Admin (<a href="${this.checkoutPostPurchaseUrl}" target="_blank">Settings</a> > <a href="${this.checkoutPostPurchaseUrl}" target="_blank">Checkout</a> > <a href="${this.checkoutPostPurchaseUrl}" target="_blank">Post-Purchase Page</a>),
    please <strong>manually select ONE app</strong> as the primary post-purchase app in order to show upsell offers page after checkout. Once settings are updated refresh this page.`
  }

  get tableTitle() {
    return !this.filterModel || this.filterModel === UpsellStatus.AllExceptArchived ? 'Upsell Pages' : `${this.capitalizePipe.transform(this.filterModel)} Upsell Pages`
  }

  get emptyTableText() {
    return !this.filterModel || this.filterModel === UpsellStatus.AllExceptArchived ? 'No upsells yet' : 'No upsells found'
  }

  constructor(
    private router: Router,
    private upsellService: UpsellService,
    private store: Store<StoreState>,
    private capitalizePipe: CapitalizePipe,
    private currencyPipe: CurrencyPipe,
    private snackbarService: CustomSnackbarService,
    private userService: UserService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private matDaterangeService: MatDaterangeService,
    private apiService: ApiService,
    private activeAppCampaignService: ActiveAppCampaignService,
    private upgradePlanHelperService: UpgradePlanHelperService,
    public dialog: MatDialog,
  ) {
    this.onStatusToggle = _.debounce(this.onStatusToggle, 200)
    this.upsellService.dateRange$.next({
      start_date: this.startDate.format('YYYY-MM-DD'),
      end_date: this.endDate.format('YYYY-MM-DD'),
    })
  }

  ngOnInit(): void {
    this.getStats()
    this.dataSource = new UpsellPageDatasource(this.upsellService, this.currencyPipe, this.userService, this.store)
    this.subscription$.add(this.dataSource.data$.subscribe(data =>  {
      this.upsellItems = data
    }))
    this.subscription$.add(this.userService.postPurchaseAppInUse().subscribe(res => {
      this.postPurchaseAppInUse = res
    }))
    // if (!this.userService.userInfo.upsell_eligible) {
    //   this.openUpsellForm()
    // } else {
    //   this.createSampleUpsellIfNeeded()
    // }
    this.createSampleUpsellIfNeeded()
    this.subscription$.add(
      this.upsellService.dateRange$.subscribe(dateRange => {
        this.startDate = moment(dateRange.start_date)
        this.endDate = moment(dateRange.end_date)
        this.loadTable()
      })
    )
  }

  public openUpsellForm() {
    const upsellDialog = this.dialog.open(AccessFormModalComponent, {
      width: '700px',
      data: {
        preset: upsellAccessFormPreset
      },
    })

    this.subscription$.add(
      upsellDialog.afterClosed().subscribe((res) => {
        if (res) {
          this.subscription$.add(forkJoin([
            this.userService.updateUserUpsell(true),
            this.apiService.post(`/v1/me/canny/feature_requests/upsell_one_click_checkout/vote_and_comment`, {value: ''})
          ]).subscribe(() => {
            this.createSampleUpsellIfNeeded()
            this.router.navigateByUrl(RouteHeaderUrl.upsell)
          }))
        } else {
          // if user doesn't fill survey redirect them to dashboard
          this.router.navigateByUrl('/')
        }
      }))
  }

  selectedDate(e: MatDatepickerOutput) {
    if (this.startDate.format('YYYY-MM-DD') === e.start_date && this.endDate.format('YYYY-MM-DD') === e.end_date) {
      return
    }
    this.startDate = e.start
    this.endDate = e.end

    this.upsellService.dateRange$.next({
      start_date: e.start_date,
      end_date: e.end_date,
    })

    this.getStats()
  }

  getStats() {
    this.store.dispatch(new ShowLoading('UpsellStats'))
    this.stats.loading = true
    this.subscription$.add(
      this.upsellService.getUpsellStats(
        this.startDate.format('YYYY-MM-DD'),
        this.endDate.format('YYYY-MM-DD')
      ).subscribe(res => {
        if (res) {
          const avgIncreaseRate = 0
          this.stats = {
            ...res,
            average_increase_rate: avgIncreaseRate,
            loading: false
          }
        }
      }),
    )
  }

  showCreateUpsellPopup() {
    this.dialog.open(NewUpsellModalComponent, {
      width: '600px',
      data: {}
    })
  }

  loadTable() {
    this.dataSource.loadPage({
      status: this.filterModel,
      start_date: this.startDate.format('YYYY-MM-DD'),
      end_date: this.endDate.format('YYYY-MM-DD'),
    })
  }

  filterChanged() {
    this.loadTable()
  }

  createUpsell() {
    this.subscription$.add(
      this.upsellService.createUpsellOffer({name: 'Untitled'} as UpsellListItem).subscribe(res => {
        if (res && res.id) {
          this.router.navigate([`upsell/${res.id}/offer`])
          this.segmentAnalyticsService.track('Create Upsell Offer', {
            upsell: {
              id: res.id,
              name: res.name,
            }
          })
        }
      }, err => {
        this.router.navigate([`upsell`])
      })
    )
  }

  onPublishUpsell(upsell: UpsellListItem) {
    this.subscription$.add(this.userService.postPurchaseAppInUse().subscribe(res => {
      if (res) {
        this.onStatusToggle(upsell, UpsellStatus.Live)
      } else {
        this.showInfoModal()
      }
    }))
  }

  onStatusToggle(upsell: UpsellListItem, status: UpsellStatus) {
    this.subscription$.add(this.upsellService.updateUpsellStatus(upsell, status).subscribe(res => {
      upsell.status = status
      this.snackbarService.showSuccess({title: status === UpsellStatus.Live ? 'Published Successfully' : 'Paused Successfully'})
      this.segmentAnalyticsService.track(status === UpsellStatus.Live ? 'Published Upsell Offer' : 'Paused Upsell Offer', {
        upsell: {
          id: upsell.id,
          name: upsell.name,
        }
      })
      this.activeAppCampaignService.refreshTotals()
    },
    err => {
      this.processStatusError(err, upsell)
    }))
  }

  processStatusError(res, upsell) {
    if (res?.error?.error_code === 'exceeds_max_active_campaigns') {
      return
    }

    const error = _.get(res, 'error.errors[0]', {})
    let text = 'Something went wrong, '

    if (error?.field === 'variants' && error.code === `can't be blank`) {
      text = 'Upsell offer not set up, '
    } else if (error?.field === 'products' && error.code === `can't be blank`) {
      text = 'No trigger product(s) selected, '
    }
    this.snackbarService.showError({text}, {},
      {button: true, button_type: 'link', button_text: 'resolve issue', cb: () => {
         this.onEditUpsellClick(upsell)
         this.snackbarService.dismiss()
      }})
  }

  onRenameClick(item) {
    const dialogRef = this.dialog.open(RenameModalComponent, {
      width: '450px',
      data: {
        name: item.name,
        title: item.offer_id ? `Change Variant Name?` : `Rename Upsell Offer?`,
        text: 'Enter new name:'
      } as RenameModalConfig,
    })
    this.subscription$.add(dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        let update = null
        if (item.offer_id) {
          update = this.upsellService.updateVariant(item.offer_id, item.id, {...item, name: result})
        } else {
          update = this.upsellService.updateUpsell(item.id, {...item, name: result})
        }
        this.subscription$.add(update
          .subscribe(() => {
            item.name = result
            this.snackbarService.showSuccess({title: 'Renamed Successfully'})
            if (item.offer_id) {
              this.segmentAnalyticsService.track('Renamed Upsell Variant', {
                variant: {
                  id: item.id,
                  offer_id: item.offer_id,
                  name: item.name,
                }
              })
            } else {
              this.segmentAnalyticsService.track('Renamed Upsell Offer', {
                upsell: {
                  id: item.id,
                  name: item.name,
                }
              })
            }
          }))
      }
    }))
  }

  onPreviewClick(upsell: UpsellListItem) {
    // TODO add redirect to site
  }

  onDuplicateClick(upsell: UpsellListItem) {
    const dialogRef = this.dialog.open(RenameModalComponent, {
      width: '450px',
      data: {
        name: `Copy of ${upsell.name}`,
        title: `Duplicate «${upsell.name}» Upsell Offer?`,
        text: 'Enter new name:',
        acceptButton: {
          text: 'Duplicate',
        },
      } as RenameModalConfig,
    })

    this.subscription$.add(dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.subscription$.add(this.upsellService.duplicateUpsell(upsell.id, result)
          .subscribe((res) => {
            this.snackbarService.showSuccess({title: 'Upsell Duplicated Successfully'})
            this.loadTable()
            this.segmentAnalyticsService.track('Duplicate Upsell Offer', {
              upsell: {
                id: res.id,
                name: res.name,
              }
            })
          }))
      }
    }))
  }

  onDuplicatVarianteClick(upsell, variant) {
    const dialogRef = this.dialog.open(RenameModalComponent, {
      width: '450px',
      data: {
        name: `Copy of ${variant.name}`,
        title: `Duplicate «${variant.name}»`,
        text: 'Enter new name:',
        acceptButton: {
          text: 'Duplicate',
        },
      } as RenameModalConfig,
    })

    this.subscription$.add(dialogRef.afterClosed().subscribe((result: string) => {
      if (result && variant.offer_id) {
        this.subscription$.add(this.upsellService.createUpsellVariant(upsell.id, {...variant, name: result})
          .subscribe((res) => {
            this.snackbarService.showSuccess({title: 'Variant Duplicated Successfully'})
            this.loadTable()
            this.segmentAnalyticsService.track('Duplicate Upsell Variant', {
              variant: {
                id: res.id,
                name: res.name,
              }
            })
          }))
      }
    }))
  }

  onCreateVariatonClick(upsell: UpsellListItem) {
    if (upsell?.variants?.length > 0) {
      this.router.navigateByUrl(`/upsell/${upsell.id}/variant/offer`)
    } else {
      this.router.navigateByUrl(`/upsell/${upsell.id}/offer`)
    }
  }

  onArchiveClick(upsell: UpsellListItem, status: UpsellStatus) {
    const liveUpsell = upsell.status === UpsellStatus.Live
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      data: {
        title: `${status === UpsellStatus.Archived ? 'Archive' : 'Restore'} Upsell Offer?`,
        text: (upsell.name) ?
          `Are you sure you want to ${status === UpsellStatus.Archived ? 'archive' : 'restore'} «${upsell.name}»?` :
          `Are you sure you want to ${status === UpsellStatus.Archived ? 'archive' : 'restore'} this upsell?`,
        acceptButton: {
          text: status === UpsellStatus.Archived ? 'Archive' : 'Restore',
          classes: 'pf-button filled blue',
        },
        cancelButton: {
          text: 'Cancel',
          classes: 'pf-button outline gray',
        },
      } as ConfirmModalConfig,
    })
    this.subscription$.add(dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscription$.add(this.upsellService.updateUpsellStatus(upsell, status)
          .subscribe(() => {
            this.snackbarService.showSuccess({title: `${status === UpsellStatus.Archived ? 'Archived' : 'Restored'} Successfully`})
            this.loadTable()

            this.segmentAnalyticsService.track(`${status === UpsellStatus.Archived ? 'Archived' : 'Restored'} Upsell Offer`, {
              upsell: {
                id: upsell.id,
                name: upsell.name,
              }
            })
            this.activeAppCampaignService.refreshTotals()
          }))
      }
    }))
  }

  mobileMoveButtonClick(row, index, direction) {
    const newIndex = direction === 'up' ? index - 1 : index + 1
    this.store.dispatch(new ShowLoading('MobileMoveButtonClick'))
    this.drop({previousIndex: index, currentIndex: newIndex, item: {data: row}})
  }

  drop(event) {
    // if dropped in same spot do nothing
    if (event.previousIndex === event.currentIndex) {
      return
    }
    // if dropped in another spot update priority
    const droppedItem = _.get(event, 'item.data')
    if (event.currentIndex === 0) {
      const firstPriority = _.get(this.upsellItems, `[${event.currentIndex}].priority`, 0)
      const newPriority = firstPriority - 10
      droppedItem.priority = newPriority
    } else if (event.currentIndex + 1 === this.upsellItems.length) {
      const lastPriority = _.get(this.upsellItems, `[${event.currentIndex}].priority`, 0)
      const newPriority = lastPriority + 10
      droppedItem.priority = newPriority
    } else if (event.currentIndex < event.previousIndex) {
      const prevPriority = _.get(this.upsellItems, `[${event.currentIndex - 1}].priority`, 0)
      const nextPriority = _.get(this.upsellItems, `[${event.currentIndex}].priority`, 0)
      const newPriority = (prevPriority + nextPriority) / 2
      droppedItem.priority = newPriority
    } else if (event.currentIndex > event.previousIndex) {
      const prevPriority = _.get(this.upsellItems, `[${event.currentIndex}].priority`, 0)
      const nextPriority = _.get(this.upsellItems, `[${event.currentIndex + 1}].priority`, 0)
      const newPriority = (prevPriority + nextPriority) / 2
      droppedItem.priority = newPriority
    }
    this.subscription$.add(
      this.upsellService.updateUpsell(droppedItem.id, droppedItem).subscribe(res => {
        this.loadTable()
        if (this.isMobile) {
          this.store.dispatch(new HideLoading('MobileMoveButtonClick'))
        }
      })
    )
  }

  onEditUpsellClick(upsell) {
    if (upsell.status === UpsellStatus.Live && this.upgradePlanHelperService.editingNotAllowed()) {
      return
    }
    this.router.navigateByUrl(`/upsell/${upsell.id}/offer`)
  }

  onEditVariantClick(variant) {
    if (variant.status === UpsellStatus.Live && this.upgradePlanHelperService.editingNotAllowed()) {
      return
    }
    this.router.navigateByUrl(`/upsell/${variant.offer_id}/variant/${variant.id}/offer`)
  }

  onWeightChange(upsell, variant) {
    upsell.total_weight = upsell.root_variant.weight + upsell.other_variants.reduce((res, item) => (res + item.weight), 0)
    this.subscription$.add(this.upsellService.updateVariant(upsell.id, variant.id, variant).subscribe(res => {
      this.snackbarService.showSuccess({title: 'Success', text: 'Weight value changed successfully.'})
      this.segmentAnalyticsService.track(`Changed Upsell Variant Weight`, {
        variant: {
          id: variant.id,
          offer_id: variant.offer_id,
          name: variant.name,
          weight: variant.weight,
        }
      })
    }))
  }

  onToggleVariantWeight(upsell, variant, weight = 0) {
    variant.weight = weight
    upsell.total_weight = upsell.root_variant.weight + upsell.other_variants.reduce((res, item) => (res + item.weight), 0)
    this.subscription$.add(this.upsellService.updateVariant(upsell.id, variant.id, variant).subscribe(res => {
      if (weight === 0) {
        this.snackbarService.showSuccess({title: 'Variant Paused', text: `Variant's weight has been set to 0 to pause it from being shown to customers.`})
      } else {
        this.snackbarService.showSuccess({title: 'Variant Published', text: `Variant's weight has been set to ${weight} to start showing it to customers.`})
      }
      this.segmentAnalyticsService.track(`Changed Upsell Variant Weight`, {
        variant: {
          id: variant.id,
          offer_id: variant.offer_id,
          name: variant.name,
          weight: variant.weight,
        }
      })
    }))
  }

  createSampleUpsellIfNeeded() {
    this.subscription$.add(this.dataSource.sample$.subscribe(sample => {
      if (sample) {
        // if there are no archived upsells either create sample one
        this.subscription$.add(this.upsellService.getUpsells({status: UpsellStatus.Archived}).subscribe((data) => {
          if (data?.length === 0) {
            this.createSampleUpsell()
          }
        }))
      }
    }))
  }

  createSampleUpsell() {
    this.subscription$.add(this.upsellService.createUpsellOffer({name: 'Sample Upsell'}).subscribe(res => {
      this.segmentAnalyticsService.track('Create Sample Upsell Offer', {
        upsell: {
          id: res.id,
          name: res.name,
        }
      })
      this.loadTable()
    }))
  }

  showInfoModal() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '560px',
      data: {
        title: 'Enable ONE as your primary post-purchase app',
        html: `<strong>Important: </strong> You must manually select ONE to be your
              primary post-purchase app in order to offer upsells after checkout. Go to the
              <strong>Shopify Checkout Settings</strong> →
              <strong>"Post-purchase Page" section</strong> →
              then select the <strong>ONE</strong> app.`,
        acceptButton: {
          text: 'Enable',
          classes: 'pf-button filled blue',
        },
        footerClass: 'd-flex justify-content-end'
      } as ConfirmModalConfig,
    })

    this.subscription$.add(dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        window.open(this.checkoutPostPurchaseUrl, '_blank')
      }
    }))
  }

  navigateToTutorials() {
    this.segmentAnalyticsService.track('Clicked Watch Tutorials btn - Upsell Page')
    this.router.navigate([this.tutorialVideoUrl])
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe()
  }
}
