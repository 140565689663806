import { Logger } from '../../../core/services/logger.service'
import { HostListener, Component, inject } from '@angular/core'
import { isEqual } from 'lodash'
import { SavableModel } from '../../models/savable.model'
import diff from 'variable-diff'
import { Observable, BehaviorSubject } from 'rxjs'
import * as Sentry from '@sentry/browser'
import { LogLabel } from '../../models/logger/log-label.model'
import { CommonModule } from '@angular/common'

@Component({
    template: '',
    imports: [
        CommonModule,
    ]
})
export class SavableComponent implements SavableModel {
  public _hasUnsavedChanges = new BehaviorSubject<boolean>(false)
  public currentData: any
  private _isChanged: boolean = false
  private _detectedChanges: [] = []
  private _changeableData: any

  logger = inject(Logger)

  constructor(
  ) {
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload($event: BeforeUnloadEvent) {
    if (this._isChanged) $event.returnValue = 'Your data will be lost!'
  }

  get isChanged(): boolean {
    return this._isChanged
  }

  set isChanged(value: boolean) {
    this._isChanged = false;
  }

  get detectedChanges(): [] {
    return this._detectedChanges
  }

  get savableData(): any {
    return this._changeableData
  }

  get hasChangesObservable(): Observable<boolean> {
    return this._hasUnsavedChanges.asObservable()
  }

  storeSavableData(value: any) {
    this._changeableData = this.jsonify(value)
  }

  compareSavableData(value: any) {
    if (!this._changeableData || !value) {
      const isChanged = false
      this.markAsChanged(isChanged)
      return isChanged
    } else {
      this.currentData = this.jsonify(value)
      const isChanged = !isEqual(this._changeableData, this.currentData)
      if (isChanged) {
        Sentry.addBreadcrumb({
          category: 'savable-component',
          message: `compareSavableData: ${JSON.stringify(diff(this._changeableData, this.currentData))}`,
          level: 'info',
        })
        this.logger.log(<LogLabel>'savable-component', 'compare_data: ', diff(this._changeableData, this.currentData))
      }
      this.markAsChanged(isChanged)
      return isChanged
    }
  }

  // it's a hack because Unlayer keeps adding random (non-existing) properties with undefined values
  jsonify(value: any) {
    return JSON.parse(JSON.stringify(value))
  }

  markAsChanged(value: boolean = true, label?: string) {
    this._isChanged = value
    this._hasUnsavedChanges.next(value)
    this.logger.log(LogLabel.SavableComponent, `Mark as Changed: ${this._isChanged}`, label ? `(${label})` : '')
  }
}
