import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDaterangeComponent } from './components/mat-daterange/mat-daterange.component'
import { OverlayModule } from '@angular/cdk/overlay'
import { MatDaterangePickerComponent } from './components/mat-daterange-picker/mat-daterange-picker.component'
import { MatDaterangeHeaderRightComponent } from './components/mat-daterange-header-right/mat-daterange-header-right.component'
import { MatDaterangeHeaderLeftComponent } from './components/mat-daterange-header-left/mat-daterange-header-left.component'
import { FormsModule } from '@angular/forms'
import { OneIconComponent } from '../../components/one-icon/one-icon.component'
import { MatButtonModule } from '@angular/material/button'

@NgModule({
  declarations: [
    MatDaterangeComponent,
    MatDaterangePickerComponent,
    MatDaterangeHeaderRightComponent,
    MatDaterangeHeaderLeftComponent,
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    OverlayModule,
    MatButtonModule,
    FormsModule,
    OneIconComponent,
  ],
  exports: [
    MatDaterangeComponent,
  ],

  providers: [
  ],
})
export class MatDaterangeModule { }
