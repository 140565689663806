import { CommonModule } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { CloudflareStreamModule } from '@cloudflare/stream-angular'
import { UserService } from '../../../../core/services/user.service'
import { TutorialsAnalyticsService } from '../../services/tutorials-analytics.service'
import { TutorialsService } from '../../services/tutorials.service'
import { TutorialVideoItem } from '../../models/tutorials.model'
import { SegmentAnalyticsService } from '../../../../shared/services/segment-analytics.service'

@Component({
    selector: 'one-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
    imports: [
        CommonModule,
        CloudflareStreamModule,
    ]
})

export class VideoPlayerComponent implements OnInit, OnDestroy {
  private isCn = this.userService?.userInfo?.country_code === 'CN'
  public showCloudflarePlayer = this.isCn
  public videoItem: TutorialVideoItem
  private _lastPlayedCallback: number = 0
  private _youtubeId
  private _cloudflareId
  @Input() set youtubeId(val: string) {
    this._youtubeId = val
    this.videoItem = this.tutorialsService.getVideoById(val)
    if (!val) {
      // If youtubeId is not provided, show cloudflare player
      this.showCloudflarePlayer = true
    }
    if (val && !this.showCloudflarePlayer) {
      // If youtubeId is provided and cloudflare player is not shown, set id and play
      this.setIdAndPlay(val)
    }
    if (val && this._cloudflareId) {
      if (!this.isCn) {
        // Prefer youtube when not in China and both video id's are provided
        this.showCloudflarePlayer = false
        this.initYouTubePlayerScript()
        setTimeout(() => {
          this.setIdAndPlay(val)
        }, 400)
      }
    }
  }
  @Input() set cloudflareId(val: string) {
    this._cloudflareId = val
    if (val && !this._youtubeId) {
      // If cloudflareId is provided and youtubeId is not, show cloudflare player
      this.showCloudflarePlayer = true
    }
    if (val && this.showCloudflarePlayer) {
      // If cloudflareId is provided and cloudflare player is shown, set id and play
      this.setIdAndPlay(val)
    }
  }
  videoId: string
  youtubePlayer: any
  videoLoaded: boolean = false

  constructor(
    private userService: UserService,
    private tutorialsAnalyticsService: TutorialsAnalyticsService,
    private tutorialsService: TutorialsService,
    private segmentAnalyticsService: SegmentAnalyticsService
  ) { }

  ngOnInit() {
    this.initYouTubePlayerScript()
  }

  public onVideoUpdated(event: Event, videoId: string) {
    const time = new Date().getTime()
    // Workaround for Play callback being fired twice
    if (event.type === 'play') {
      if (time - this._lastPlayedCallback < 10) {
        return false
      }
      this._lastPlayedCallback = time
    }

    switch (event.type) {
      case 'play':
        this.tutorialsAnalyticsService.onVideoStart(time, videoId)
        break
      case 'pause':
        this.tutorialsAnalyticsService.onVideoEnd(time, videoId)
        break
      case 'seeked':
        // seek is triggering pause and play events
        // can't be used
        break
    }
  }

  public setIdAndPlay(videoId: string) {
    this.videoId = videoId
    if (this.youtubePlayer) {
      if (!this.youtubePlayer.loadVideoById) {
        setTimeout(() => {
          this.setIdAndPlay(videoId)
        }, 100)
      } else {
        this.youtubePlayer.loadVideoById(videoId)
      }
    } else {
      this.initYouTubePlayerScript()
    }
  }

  private onYouTubePlayerReady() {
    this.videoLoaded = true
  }

  private createYouTubePlayer() {
    this.youtubePlayer = window['YT'] && new window['YT'].Player('youtube-video', {
      videoId: this.videoId,
      height: '100%',
      width: '100%',
      events: {
        'onReady': this.onYouTubePlayerReady.bind(this),
        'onStateChange': this.onYouTubePlayerStateChange.bind(this),
      },
      playerVars: {
        modestbranding: 1,
        rel: 0,
      }
    })
    const iframe = document.getElementById('youtube-video')
    if (iframe) {
      iframe.style.position = 'absolute'
      iframe.style.top = '0'
      iframe.style.left = '0'
    }
  }

  private onYouTubePlayerStateChange(event) {
    if (!this.videoItem?.eventName) return
    switch (event.data) {
      case window['YT'].PlayerState.PLAYING:
        this.segmentAnalyticsService.track(`Start ${this.videoItem?.eventName} Video`)
        break
      case window['YT'].PlayerState.ENDED:
        this.segmentAnalyticsService.track(`Complete ${this.videoItem?.eventName} Video`)
        break;
    }
  }

  initYouTubePlayerScript() {
    const youtubeScriptAdded = document.getElementById('youtube-script')
    if (!youtubeScriptAdded) {
      const script = document.createElement('script')
      script.src = 'https://www.youtube.com/iframe_api'
      script.id = 'youtube-script'
      document.body.appendChild(script)
      window['onYouTubeIframeAPIReady'] = () => this.createYouTubePlayer()
    } else {
      setTimeout(() => {
        this.createYouTubePlayer()
      })
    }
  }

  public onVideoLoadStarted() {
    this.videoLoaded = true
  }

  ngOnDestroy(): void {
    this.onVideoUpdated({type: 'pause'} as Event, this.videoId)
    const youtubeScriptAdded = document.getElementById('youtube-script')
    if (youtubeScriptAdded) {
      youtubeScriptAdded.remove()
    }
  }
}
