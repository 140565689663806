import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ShopSwitchService } from './services/one-shop-switch.service'
import { Shop } from './shop-switch.model'
import { Subscription } from 'rxjs'
import { OneIconComponent } from '../../one-icon/one-icon.component'
import {CdkMenuTrigger, CdkMenu} from '@angular/cdk/menu'
import { OneAvatarComponent } from './one-avatar/one-avatar.component'
import { ConnectStoresModalComponent } from './connect-stores-modal/connect-stores-modal.component'
import { ShopifyService } from '../../../../core/services/shopify.service'
import { ReinstallModalComponent } from './reinstall-modal/reinstall-modal.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
    selector: 'one-shop-switch',
    templateUrl: 'one-shop-switch.component.html',
    styleUrls: ['one-shop-switch.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        OneIconComponent,
        CdkMenuTrigger,
        CdkMenu,
        OneAvatarComponent,
    ]
})

export class ShopSwitchComponent implements OnInit, OnDestroy {
  @ViewChild(CdkMenuTrigger, {static: false}) trigger: CdkMenuTrigger
  private subscription = new Subscription()
  public selectedShop$ = this.shopSwitchService.selectedShop$
  public shopList$ = this.shopSwitchService.userShops$
  public isOpened = false
  public allShopsHidden = false

  constructor(
    private shopSwitchService: ShopSwitchService,
    private shopifyService: ShopifyService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.shopList$.asObservable().subscribe(shops => {
        if (shops) {
          this.allShopsHidden = shops?.every(
            shopGroup => shopGroup?.shops?.every(shop => shop.hidden)
          )
        } else {
          this.allShopsHidden = true
        }
      })
    )
  }

  onShopChange(shop: Shop) {
    if (shop.one_store_installed) {
      this.trigger.close()
      this.shopSwitchService.setActiveShop(shop)
      window.location.reload()
    } else {
      this.showReinstallModal(shop)
    }
  }

  checkEmbedded(event) {
    if (this.shopifyService.isEmbedded) {
      event.preventDefault()
      window.open(`${window.location.origin}/app?redirectForStandalone` , '_blank')
    }
  }

  onSelectShopBtnClick(forceOpen = false) {
    if (this.allShopsHidden || forceOpen) {
      this.showConnectStoresModal()
    }
  }

  showConnectStoresModal() {
    const dialogRef = this.dialog.open(ConnectStoresModalComponent, {
      width: '360px',
      data: {
      },
    })

    // dialogRef.afterClosed().subscribe(reload => {
      // this.modalOpened = false
      // if (reload === true) {
      //   this.segmentAnalyticsService.track('Clicked Refresh Btn in Modal')
      //     .then(() => {
      //       window.location.reload()
      //     })
      // }
    // })
  }

  showReinstallModal(shop: Shop) {
    const dialogRef = this.dialog.open(ReinstallModalComponent, {
      width: '360px',
      data: {
        shop: shop
      },
    })

    // dialogRef.afterClosed().subscribe(reload => {
      // this.modalOpened = false
      // if (reload === true) {
      //   this.segmentAnalyticsService.track('Clicked Refresh Btn in Modal')
      //     .then(() => {
      //       window.location.reload()
      //     })
      // }
    // })
  }

  ngOnDestroy() {
    this.dialog.closeAll()
    this.subscription.unsubscribe()
  }
}
