import { OnInit, Input, Output, EventEmitter, Component } from '@angular/core'
import { CouponBoxNotificationThemes, CouponBoxThemeConfig } from '../../models/campaign/coupon-box-notification.model'
import { EmailEditorTheme } from '../../../pages/email-builder/models/email-editor.models'
import { WidgetFortuneWheelThemeIdentifier, WidgetFortuneWheelThemeVariables, WidgetFortuneWheelThemeImages } from '../../models/widget/fortune-wheel.model'
import { CommonModule } from '@angular/common'
import { OneIconComponent } from '../one-icon/one-icon.component'

export interface CouponCarouselThemePreview {
  premium: boolean,
  selected: boolean,
  name: string,
  loaded: boolean,
  identifier: CouponBoxNotificationThemes,
  config: CouponBoxThemeConfig,
  resetable: boolean
  preview_image_url: string
}

interface WidgetFortuneWheelThemePreviewConfig {
  name?: string,
  identifier?: WidgetFortuneWheelThemeIdentifier,
  premium: boolean,
  variables: WidgetFortuneWheelThemeVariables,
  images: WidgetFortuneWheelThemeImages,
  css: string
  selected: boolean,
  loaded: boolean,
  resetable: boolean,
  list_order: number,
  preview_image_url: string
}

export type Theme = CouponCarouselThemePreview | WidgetFortuneWheelThemePreviewConfig | EmailEditorTheme

@Component({
    selector: 'pf-theme-carousel',
    templateUrl: './theme-carousel.component.html',
    styleUrls: ['./theme-carousel.component.scss'],
    imports: [
        CommonModule,
        OneIconComponent,
    ]
})

export class ThemeCarouselComponent implements OnInit {

  @Input() themes: Array<Theme | any> = []
  @Input() imgPath: string
  @Input() published = false
  @Input() savedTheme: CouponBoxNotificationThemes | WidgetFortuneWheelThemeIdentifier | string
  @Input() customClass: string

  @Output() themeSelected: EventEmitter<Theme | any> = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  public selectTheme(theme: Theme) {
    this.themeSelected.emit(theme)
  }

}
