import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { UICouponTargetSelection, CouponCodeValueType, UIProductSelectorType, TargetSelectionChangeEvent, UICouponMinimumRequirements } from '../../../../models/coupon-code'
import { ProductsListService } from '../../../../../../shared/modules/products-selector/services/products-list.service'
import { UntypedFormGroup } from '@angular/forms'
import { NewCouponCodesService } from '../../../../services/new-coupon-code.service'


@Component({
    selector: 'pf-coupon-code-products',
    templateUrl: './coupon-code-products.component.html',
    styleUrls: ['./coupon-code-products.component.scss'],
    standalone: false
})

export class CouponCodeProductsComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup
  @Input() isEditMode: boolean
  @Input() label: string
  @Input() controlName: string
  @Input() minimumOption: UICouponMinimumRequirements
  @Input() productSelectorType: UIProductSelectorType
  @Input() productsListService: ProductsListService
  @Input() noValidate: boolean = false;
  @Input() customError = '';

  @Output() showProductSelectorClick: EventEmitter<UICouponTargetSelection> = new EventEmitter()
  @Output() updateTargetSelectionChange: EventEmitter<TargetSelectionChangeEvent> = new EventEmitter()

  uiCouponTargetSelection = UICouponTargetSelection
  uiCouponMinimumRequirements = UICouponMinimumRequirements
  uiProductSelectorType = UIProductSelectorType
  couponCodeValueType = CouponCodeValueType

  targetSelectionItems = []

  constructor(
    public newCouponCodesService: NewCouponCodesService,
  ) { }

  ngOnInit(): void {
    if (this.productSelectorType === this.uiProductSelectorType.default) {
      this.targetSelectionItems = [
        {
          title: 'All Products',
          value: this.uiCouponTargetSelection.all
        },
        {
          title: 'Specific Collections...',
          value: this.uiCouponTargetSelection.entitled_collections
        },
        {
          title: 'Specific Products...',
          value: this.uiCouponTargetSelection.entitled_products
        },
      ]
    } else if (this.productSelectorType === this.uiProductSelectorType.wooCommerceInclude) {
      this.targetSelectionItems = [
        {
          title: 'All Products',
          value: this.uiCouponTargetSelection.all
        },
        {
          title: 'Specific Products...',
          value: this.uiCouponTargetSelection.entitled_products
        },
      ]
    } else if (this.productSelectorType === this.uiProductSelectorType.wooCommerceExclude) {
      this.targetSelectionItems = [
        {
          title: 'Specific Products...',
          value: this.uiCouponTargetSelection.entitled_products
        },
      ]
    } else if (this.productSelectorType === this.uiProductSelectorType.bigCommerce) {
      this.targetSelectionItems = [
        {
          title: 'All Products',
          value: this.uiCouponTargetSelection.all
        },
        {
          title: 'Specific Products...',
          value: this.uiCouponTargetSelection.entitled_products
        },
      ]
    } else {
      this.targetSelectionItems = [
        {
          title: 'Specific Collections...',
          value: this.uiCouponTargetSelection.entitled_collections
        },
        {
          title: 'Specific Products...',
          value: this.uiCouponTargetSelection.entitled_products
        },
      ]
    }
  }

  onShowProductSelectorClick(value: UICouponTargetSelection) {
    this.showProductSelectorClick.emit(value)
  }

  onUpdateTargetSelection(event: Event) {
    const value = (event.target as HTMLSelectElement).value as UICouponTargetSelection
    this.updateTargetSelectionChange.emit({targetSelection: value, selectorType: this.productSelectorType})
  }
}
