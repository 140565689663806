import { CurrencyPipe } from '@angular/common';
import {
  Component, Input, OnDestroy, OnInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../../core/services/user.service';
import { UpsellDiscountType, UpsellFormatType, UpsellShippingType } from '../../models/upsell-page.models';
import { TranslateUpsellPipe } from '../../pipes/translate-upsell.pipe';

@Component({
    selector: 'pf-upsell-preview',
    templateUrl: './upsell-preview.component.html',
    styleUrls: ['./upsell-preview.component.scss'],
    standalone: false
})

export class UpsellPreviewComponent implements OnInit, OnDestroy {
  @Input() data: any = {}
  @Input() set minutes(value) {
    this._minutes = value
    this.setupTimer()
  }
  _minutes = 0
  timer = 0
  userInfo = this.userService.userInfo
  userShop = this.userService?.userInfo?.shop?.profile?.name || 'test-shop'
  timerInterval = null
  localQuantity = 1
  quantityOptions = []
  imageIndex = 0

  get minutes() {
    return this._minutes
  }

  get showTimer() {
    return !!this._minutes
  }

  get formattedTime() {
    const minutes = `0${Math.floor(this.timer / 60)}`.slice(-2);
    const seconds = `0${this.timer % 60}`.slice(-2);
    return `${minutes}:${seconds}`;
  }

  get variantPrice() {
    return parseFloat(this.data.selectedVariant?.discounted_price || this.data.selectedVariant?.original_price || '0')
  }

  get discountLabel() {
    switch (this.data.discount_type) {
      case UpsellDiscountType.Percentage:
        return `${this.data.discount_value}% ${this.translateUpsellPipe.transform('Savings', this.data.locale || 'en')}`
      case UpsellDiscountType.Fixed:
        let savings = this.data.discount_value < this.variantPrice ? this.data.discount_value * this.data.quantity : this.originalPrice
        return `${this.currencyPipe.transform(savings, this.data.currency)} ${this.translateUpsellPipe.transform('Savings', this.data.locale || 'en')}`
    }
  }

  get originalPrice() {
    return this.data.quantity * this.variantPrice
  }

  get discountedPrice() {
    let discountedPrice = 0
    if (this.data.discounted) {
      switch (this.data.discount_type) {
        case UpsellDiscountType.Percentage:
          discountedPrice = this.originalPrice * ((100 - this.data.discount_value) / 100)
          break
        case UpsellDiscountType.Fixed:
          discountedPrice = (this.variantPrice - this.data.discount_value) * this.data.quantity
          break
      }
    } else {
      discountedPrice = this.originalPrice
    }

    return discountedPrice < 0 ? 0 : discountedPrice
  }

  get showReadMore() {
    return this.data?.description_format === UpsellFormatType.Truncated && this.data.product_description?.length > this.data.description_length
  }

  readMore = false

  get isCustomDescription() {
    return this.data?.description_format === UpsellFormatType.Custom
  }

  get showCustomReadmore() {
    return this.data?.description_format === UpsellFormatType.Custom && this.data.description_length && this.data.description_custom?.split('p><p')?.length > this.data.description_length
  }
  get customDescriptionHtml() {
    let description = this.data?.description_custom
    if (this.showCustomReadmore && !this.readMore) {
      let paragraphs = description.split('p><p').slice(0, this.data.description_length)
      description = paragraphs.join('p><p') + 'p>'
    }
    description = `<style>
      .ql-align-center {
        text-align: center;
      }
      .ql-align-right {
        text-align: right;
      }
      a {
        color: rgb(32, 145, 222) !important;
      }
      a:hover {
        opacity: 0.9;
        text-decoration: none !important;
      }
      p, ol, ul {
        margin-bottom: 9px;
      }
      p:has(> br) {
        height: 0 !important;
      }
      p:empty {
        display: none;
      }
      ul {
        list-style-type: none;
      }
      ul > li:before {
        content: '\\2022';
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: center;
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
      }
    </style>
    ${description}`
    // if (description) {
    //   description = description.replaceAll('class=\"ql-align-center\"', 'style=\"text-align: center\"')
    //   description = description.replaceAll('class=\"ql-align-right\"', 'style=\"text-align: right\"')
    // }
    return this.sanitizer.bypassSecurityTrustHtml(description || '')
  }

  get productDescription() {
    switch (this.data?.description_format) {
      case UpsellFormatType.Custom:
        return this.data.description_custom
      case UpsellFormatType.Truncated:
        return this.showReadMore && !this.readMore
          ? this.data.product_description?.substring(0, this.data.description_length || 16) +  '...'
          : this.data.product_description
      default:
        return this.data.product_description
    }
  }

  get shippingPrice() {
    if (this.data.shipping_type === UpsellShippingType.Fixed) {
      if (this.data.shipping_per_unit) {
        return this.data.shipping_amount * this.data.quantity
      } else {
        return this.data.shipping_amount
      }
    }
    return 0
  }

  get finalPrice() {
    return this.discountedPrice + this.shippingPrice
  }

  get acceptButtonText() {
    if (this.data?.soldOut) {
      return 'Sold Out'
    }
    let text = this.data?.accept_button_text || 'Pay Now'
    text += text.indexOf('·') === -1 ? ` · ${this.formatPrice(this.finalPrice)}` : ''
    if (this.finalPrice <= 0 && text.indexOf('Add Now') === -1) {
      text = 'Add Now · Free'
    } else if (this.finalPrice > 0 && text.indexOf('Add Now') !== -1) {
      text = `Pay Now · ${this.formatPrice(this.finalPrice)}`
    }
    return text
  }

  get necessaryQuantity() {
    return this.data.predefined_quantity ? this.data.quantity : 1
  }

  get quantityLimit() {
    return this.data.selectedVariant?.stock_enforced ? this.data.selectedVariant?.stock_quantity : 999
  }

  get images() {
    return this.data.selectedVariant?.variant_images || []
  }

  get imageView() {
    return this.images?.length > 1 ? this.data.image_view : 'default'
  }

  constructor (
    private userService: UserService,
    public currencyPipe: CurrencyPipe,
    public translateUpsellPipe: TranslateUpsellPipe,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.localQuantity = this.data?.quantity || 1
    this.setupTimer()
  }

  qunatityChange() {
    if (this.localQuantity < 1) {
      this.localQuantity = 1
    } else if (this.localQuantity > this.quantityLimit) {
      this.localQuantity = this.quantityLimit
    }
    this.data.quantity = this.localQuantity
  }

  variantChange() {
    const name = this.data.options.map((option) => option.selected).join(' / ');
    const newVariant = this.data.product_variants.find(
      (variant: any) => variant.product_name === name
    )
    if (newVariant) {
      this.data.selectedVariant = newVariant
      this.imageIndex = 0
      if (newVariant.stock_enforced && newVariant.stock_quantity < this.necessaryQuantity) {
        this.data.soldOut = true
      } else {
        this.data.soldOut = false
        this.qunatityChange()
      }
    } else {
      this.data.soldOut = true
    }
  }

  shiftImageindex(i) {
    let index = this.imageIndex + i;
    if (index < 0) {
      index = this.images.length - 1;
    } else if (index > this.images.length - 1) {
      index = 0;
    }
    this.imageIndex = index
  }

  onNumberInputClick(event) {
    // when clicking on the number spinners in FF browser focus is not triggered, so need to do it here
    const isFirefoxBrowser = window.navigator.userAgent.toLowerCase().includes('firefox')
    if (isFirefoxBrowser) {
      event.target.focus()
    }
  }

  setupTimer() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval)
    }
    if (this.minutes) {
      this.timer = this.minutes * 60
      this.timerInterval = setInterval(() => {
        if (this.timer === 0) {
          this.timer = this.minutes * 60
        } else {
          this.timer--
        }
      }, 1000)
    }
  }

  formatPrice(amount = 0) {
    return amount > 0 ? this.currencyPipe.transform(amount, this.data.currency) : this.translateUpsellPipe.transform('Free', this.data.locale || 'en')
  }

  ngOnDestroy(): void {
    clearInterval(this.timerInterval)
  }

}

