import { RewardsService } from './../../../../../../core/services/rewards-service.service'
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { NewCouponCodesService } from '../../../../services/new-coupon-code.service'
import {
  CouponCode,
  CouponCodeAllocationMethod,
  CouponCodeTargetSelection,
  CouponCodeType,
  CouponStatus,
  TargetSelectionChangeEvent,
  UICouponMinimumRequirements,
  UICouponTargetSelection,
  UIProductSelectorType,
} from '../../../../models/coupon-code'
import moment from 'moment'
import { DiscountType } from './enums/discount-type.enum'
import { WooCommerceCoupon } from './interfaces/woo-commerce-coupon.interface'
import * as _ from 'lodash'
import { ActivatedRoute, Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { StoreState } from '../../../../../../store/store.state'
import { UserShopType } from '../../../../../../shared/models/user/user-shop-type.model'
import { HideLoading, ShowLoading } from '../../../../../../store/loading/loading.actions'
import { HttpErrorResponse } from '@angular/common/http'
import { Logger } from '../../../../../../core/services/logger.service'
import { CouponsImportService } from '../../../../../../shared/modules/coupons-import/services/coupons-import.service'
import { getUserInfo } from '../../../../../../store/user/user.selectors'
import { filter } from 'rxjs/operators'
import { Subscription } from 'rxjs'
import {
  ImportExistingDiscountModalComponent,
  ImportResult,
} from '../../../import-existing-discount-modal/import-existing-discount-modal.component'
import { WooCommerceCouponService } from './services/woo-commerce-coupon.service'
import { ProductsListService } from '../../../../../../shared/modules/products-selector/services/products-list.service'
import { ProductsSelectorInterface } from '../../../../../../shared/modules/products-selector/models/Product'
import { ProductsSelectorComponent } from '../../../../../../shared/modules/products-selector/products-selector.component'
import { UIWooCommerceCouponCodeSummary } from './interfaces/woo-commerce-coupon-summery.interface'
import { ApiCampaignService } from '../../../../../../core/services/api/api-campaign.service'
import { ApiService } from '../../../../../../core/services/api/api.service'
import { RedirectService } from '../../../../../../core/services/redirect.service'
import { LogLabel } from '../../../../../../shared/models/logger/log-label.model'
import { RouteHeaderUrl } from '../../../../../../shared/components/one-header/header-navigation.model'
import { ApiRewardsService } from '../../../../../../core/services/api/api-rewards.service'
import { RewardsType } from '../../../../models/rewards-model'
import { CustomSnackbarService } from '../../../../../../shared/modules/custom-snackbar/custom-snackbar.service'
import { CampaignPluginName } from '../../../../../../shared/models/campaign/campaign'
import { MatDialog } from '@angular/material/dialog'

@Component({
    selector: 'pf-woo-commerce-coupon',
    templateUrl: './woo-commerce-coupon.component.html',
    styleUrls: ['./woo-commerce-coupon.component.scss'],
    standalone: false
})
export class WooCommerceCouponComponent implements OnInit, OnDestroy {
  @Input() isOverlay: boolean = false;
  @Output() closeOverlay = new EventEmitter<CouponCode>();
  @ViewChild('masterCodeInput', {static: false}) masterCodeInput: ElementRef;
  couponCodeType = CouponCodeType;
  isEditMode = false;
  summary: UIWooCommerceCouponCodeSummary;
  randomString: string;
  currency: string;
  errors: any[] = [];
  currentDate = moment().format();
  discountType = DiscountType;
  valueTypeOptions: {value: DiscountType, label: string}[] = [
    { value: DiscountType.Percentage, label: 'Percentage' },
    { value: DiscountType.FixedCart, label: 'Fixed Amount' },
    { value: DiscountType.FixedProduct, label: 'Fixed Amount on Each Item' },
  ]
  createdCoupon: CouponCode;
  shopType: UserShopType = null;
  form: UntypedFormGroup;
  isCartSelected = false;
  subscription$ = new Subscription();
  uiCouponMinimumRequirements = UICouponMinimumRequirements

  // product selector variables
  uiProductSelectorType = UIProductSelectorType;
  uiCouponTargetSelection = UICouponTargetSelection;
  couponCodeTargetSelection = CouponCodeTargetSelection;
  couponCodeAllocationMethod = CouponCodeAllocationMethod;
  includeProductsListService: ProductsListService = new ProductsListService(this.apiCampaignService);
  excludeProductsListService: ProductsListService = new ProductsListService(this.apiCampaignService);
  entitledProductsList: string[] = []
  excludedProductsList: string[] = []
  entitledCollectionsList: string[] = []
  excludedCollectionsList: string[] = []
  productsRequiredError: any = null;
  defaultSelectorData = (type: UICouponTargetSelection): ProductsSelectorInterface => {
    return {
      type: type,
      ids: {},
      selectMultiple: true,
    }
  }

  routeHeaderUrl = RouteHeaderUrl

  // dynamic validators
  valueValidator;
  popupType: CampaignPluginName = null
  onlyStaticCodeAllowed: boolean = false

  constructor(
    private formBuilder: UntypedFormBuilder,
    private newCouponCodesService: NewCouponCodesService,
    private wooCommerceCouponService: WooCommerceCouponService,
    private redirectService: RedirectService,
    private rewardsService: RewardsService,
    private apiRewardsService: ApiRewardsService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<StoreState>,
    private logger: Logger,
    private apiService: ApiService,
    private couponsImportService: CouponsImportService,
    private apiCampaignService: ApiCampaignService,
    private snackbarService: CustomSnackbarService,
    public dialog: MatDialog,
  ) {
    this.popupType = this.route?.snapshot.parent?.data?.pluginType
    this.onlyStaticCodeAllowed = [CampaignPluginName.FreeShipping, CampaignPluginName.SalesPopup].includes(this.popupType)
  }

  ngOnInit(): void {
    this.defineInitialData();
    this.updateSummary(this.randomString);
    this.initFormWatchers();
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  public generateMasterCode(e: Event) {
    e.preventDefault()
    this.form.patchValue({
      master_code: '',
      prefix_code: '',
    })
    const code = this.newCouponCodesService.generateRandomCode()
    this.form.get('master_code').patchValue(code)
    this.form.markAsDirty()
  }

  public onCodeLengthChange(event: Event) {
    const val = (event.target as HTMLInputElement).value
    const _val = parseInt(val, 10)
    if (_val !== _val || _val < 6) {
      this.updateRandomString(6)
      this.form.get('data.coupon_code_length').patchValue(6)
    } else if (_val > 16) {
      this.updateRandomString(16)
      this.form.get('data.coupon_code_length').patchValue(16)
    } else {
      this.updateRandomString(val)
    }
  }

  onNumberInputClick(event) {
    // when clicking on the number spinners in FF browser focus is not triggered, so need to do it here
    const isFirefoxBrowser = window.navigator.userAgent.toLowerCase().includes('firefox')
    if (isFirefoxBrowser) {
      event.target.focus()
    }
  }

  public touchControl(controlName: string) {
    this.form.get(`data.${controlName}`).markAsTouched()
  }

  updateCouponCodesList() {
    this.rewardsService.getCouponCodes({
      page: 1,
      limit: 25,
      status: CouponStatus.active,
    });
  }

  convertErrorToText(error) {
    // God bless whoever wants to edit this
    // For some errors validation messages are stored in `code` key, for other `code` is an error type
    if (error.code === 'Value must be between 0.0 and 1.0') {
      return 'Please provide a percentage between 0 and 100'
    }
    if (error.code === 'too_long') {
      return 'Minimum quantity of items can\'t be greater than 99999999'
    }
    // not every Buy X Get Y error has this 'bxgy' string in `field` key
    if (error.field.includes('bxgy')) {
      return error.code
    }
    // some errors doesn't say which field should has a value greater than 0, so we need to alter them
    if (error.field === 'customer_get_percentage') {
      return 'Discounted value should be greater than 0'
    }
    // Backend doesn't know which radio was selected, so it returns both errors for both cases
    if (error.field === 'prerequisite_subtotal_min' || error.field === 'prerequisite_quantity_range_min') {
      const selectedRadio = this.form.get('data._local.minimumRequirements').value
      if (selectedRadio === this.uiCouponMinimumRequirements.price && error.field === 'prerequisite_subtotal_min') {
        return 'Minimum purchase amount in "Customer buys" should be greater than 0'
      } else if (selectedRadio === this.uiCouponMinimumRequirements.quantity && error.field === 'prerequisite_quantity_range_min') {
        return 'Minimum quantity of items in "Customer buys" should be greater than 0'
      }
    }
    if (error.field === 'customer_get_quantity') {
      return 'Minimum quantity of items in "Customer gets" should be greater than 0'
    }
    switch (error.code) {
      case 'no_entitled_collection_ids': {
        switch (this.form.value.data._local.defaultTargetSelection) {
          case UICouponTargetSelection.entitled_products: {
            return 'No products selected'
          }
          case UICouponTargetSelection.entitled_collections: {
            return 'No collections selected'
          }
        }
        break
      }
      case 'end_date_without_start_date': {
        return 'End date without a start date'
      }
      case 'end_date_before_start_date': {
        return 'Please choose an end date that is after the start date'
      }
      default: {
        return _.get(error, 'message')
      }
    }
  }

  public onValueChange(controlName: string, max = Number.MAX_SAFE_INTEGER, min = 0, isInt = true) {
    const _val = parseInt(this.form.get(`data.${controlName}`).value, 10)
    console.log(_val)
    const control = this.form.get(`data.${controlName}`);
    if (_val !== _val) {
      control.patchValue(min)
    } else if (_val > max) {
      control.patchValue(max)
    } else if (_val < min) {
      control.patchValue(min)
    } else if (isInt) {
      control.patchValue(_val)
    }
  }

  resetProductsRequiredError () {
    this.productsRequiredError = '';
  }

  public cancel() {
    if (!this.isOverlay) {
      this.navigateAfterSave()
    } else {
      this.closeOverlay.emit(null)
    }
  }

  public updateValueType(): void {
    this.resetValue();
    this.setValueValidators();
    this.updateProductSelectors();
    this.resetProductsRequiredError();
  }

  resetValue() {
    this.form.get('data.value').patchValue(0);
  }

  resetFormDataInCaseCouponTypeChanged() {
    this.form.get('data').patchValue({
      limit_usage_to_x_items: 0,
      expires_after_days: 1,
      expires_after_minutes: 0,
      expires_after_hours: 0,
      expires_after_enabled: false,

      free_shipping: false,
      exclude_sale_items: false,
      individual_use: false,
      prerequisite_subtotal_min: 0,
      prerequisite_subtotal_max: 0,
      allowed_emails: [],
      value: 0,
    })
    this.form.get('data._local').patchValue({
      isUserUsageLimit: false,
      isUsageLimit: false,
      isProductUsageLimit: false,
    })
  }

  public submit() {
    this.errors = []
    const couponType = this.form.get('type').value
    if (couponType === this.couponCodeType.manual_master) {
      this.form.get('data').clearValidators()
      this.form.get('data').updateValueAndValidity()
    }

    // Stop if form is invalid
    if (!this.form.valid) {
      this.logger.log(<LogLabel>'new-coupon-code', 'form validation failed', this.form.errors, 'form:', this.form)
      this.form.markAllAsTouched()
      return
    }

    if (this.form.get('data.value_type').value === DiscountType.FixedProduct) {
      if (
        this.form.get('data._local.defaultEntitledTargetSelection').value === UICouponTargetSelection.entitled_products &&
        this.entitledProductsList.length === 0
        ) {
        this.productsRequiredError = 'Selected products can`t be empty';
        return;
      }
    } else {
      this.resetProductsRequiredError();
    }

    // Adapt form for payload
    const clonedValue = _.cloneDeep(this.form.getRawValue())
    const value = this._removeUnrelatedProducts(clonedValue)

    this.store.dispatch(new ShowLoading('new-coupon-code'))
    if (value.type === CouponCodeType.woo_commerce_master && !this.isEditMode) {
      this.subscription$.add(
        this.apiService.get(`/v1/me/shop/coupon_lookup?code=${encodeURIComponent(value.master_code)}`)
          .subscribe((response) => {
            this.logger.log(<LogLabel>'new-coupon-code', 'response: ', response)
            if (response.error === 'not_found') {
              this.submitCouponCodeRequest(value)
            } else {
              this.store.dispatch(new HideLoading('new-coupon-code'))
              const dialogRef = this.dialog.open(ImportExistingDiscountModalComponent, {data: {shop: this.shopType}})
              this.subscription$.add(
                dialogRef.afterClosed().subscribe((result: ImportResult) => {
                  this.logger.log(<LogLabel>'new-coupon-code', 'dialog-result: ', result)
                  if (_.get(result, 'import', false)) {
                    this.store.dispatch(new ShowLoading('new-coupon-code-import'))
                    this.subscription$.add(
                      this.couponsImportService.importCoupons([response.id])
                        .subscribe((res) => {
                          this.createdCoupon = res
                          this.logger.log(<LogLabel>'new-coupon-code', 'response: ', res)
                          this.navigateAfterSave()
                        }, (res: HttpErrorResponse) => {
                          this.errors.push(res.error)
                          this.showErrors()
                          this.logger.log(<LogLabel>'new-coupon-code', 'new_errors: ', this.errors)
                          this.store.dispatch(new HideLoading('new-coupon-code-import'))
                        }, () => {
                          this.store.dispatch(new HideLoading('new-coupon-code-import'))
                        })
                    )
                  } else {
                    this.form.patchValue({ master_code: null })
                    this.masterCodeInput.nativeElement.focus()
                  }
                })
              );
            }
          }, () => this.store.dispatch(new HideLoading('new-coupon-code')))
      )
    } else {
      this.submitCouponCodeRequest(value)
    }
  }

  public submitCouponCodeRequest(value) {
    if (this.isEditMode) {
      this.subscription$.add(
        this.apiRewardsService.putRewards(value, RewardsType.coupon_code)
          .subscribe(res => {
              this.logger.log(<LogLabel>'new-coupon-code', 'response: ', res)
              this.updateCouponCodesList();
              this.navigateAfterSave()
            },
            (res: HttpErrorResponse) => {
              window.scroll(0, 0)
              this.errors = res.error.errors
              this.showErrors()
              this.logger.log(<LogLabel>'new-coupon-code', 'new_errors: ', this.errors)
              this.store.dispatch(new HideLoading('new-coupon-code'))
            }, () => {
              this.store.dispatch(new HideLoading('new-coupon-code'))
            })
      )
    } else {
      this.subscription$.add(
        this.apiRewardsService.postRewards(value, RewardsType.coupon_code)
          .subscribe(res => {
            this.createdCoupon = res
            this.logger.log(<LogLabel>'new-coupon-code', 'response: ', res)
              this.updateCouponCodesList();
              this.navigateAfterSave()
          },
          (res: HttpErrorResponse) => {
            window.scroll(0, 0)
            this.errors = res.error.errors
            this.showErrors()
            this.logger.log(<LogLabel>'new-coupon-code', 'new_errors: ', this.errors)
            this.store.dispatch(new HideLoading('new-coupon-code'))
          }, () => {
            this.store.dispatch(new HideLoading('new-coupon-code'))
          })
      );
    }
  }

  showErrors() {
    let errHtml = ''
    for (let error of this.errors) {
      errHtml = `${errHtml}${errHtml.length > 0 ? '<br>' : ''}${this.convertErrorToText(error)}`
    }
    this.snackbarService.showError({html: errHtml})
  }

  public updateTargetSelection(e: TargetSelectionChangeEvent, typeOfAction: string) {
    this.resetProductsRequiredError();
    switch (e.targetSelection) {
      case this.uiCouponTargetSelection.all:
        this.form.get(`data.${typeOfAction}`).patchValue(this.couponCodeTargetSelection.all)
        break
      case this.uiCouponTargetSelection.entitled_collections:
        this.form.get(`data.${typeOfAction}`).patchValue(this.couponCodeTargetSelection.entitled)
        break
      case this.uiCouponTargetSelection.entitled_products:
        this.form.get(`data.${typeOfAction}`).patchValue(this.couponCodeTargetSelection.entitled)
        break
    }
    if (typeOfAction === 'entitled_target_selection') {
      this.includeProductsListService.setSelection(this.defaultSelectorData(this.uiCouponTargetSelection.all))
    } else if (typeOfAction === 'excluded_target_selection') {
      this.excludeProductsListService.setSelection(this.defaultSelectorData(this.uiCouponTargetSelection.entitled_products))
    }
  }

  public showProductSelector(target: UICouponTargetSelection, isExcluded: boolean) {
    this.resetProductsRequiredError();
    const data: ProductsSelectorInterface = this.makeSelectorData(target, isExcluded)
    const dialogRef = this.dialog.open(ProductsSelectorComponent, {
      width: '530px',
      data: data,
    })
    this.subscription$.add(
      dialogRef.afterClosed().pipe(filter((result) => !!result))
        .subscribe((result: ProductsSelectorInterface) => {
          if (result) {
            this.setProductsSelection(result, target, isExcluded)
          }
        })
    )
  }

  private updateProductSelectors() {
    if (this.form.get('data.value_type').value === DiscountType.FixedProduct) {
      this.form.get('data._local.defaultEntitledTargetSelection').patchValue(this.uiCouponTargetSelection.entitled_products);
    } else {
      this.form.get('data._local.defaultEntitledTargetSelection').patchValue(this.uiCouponTargetSelection.all);
    }
  }

  private makeSelectorData(target: UICouponTargetSelection, isExcluded: boolean): ProductsSelectorInterface {
    const data = {
      type: target,
      ids: {},
      selectMultiple: true,
    }

    if (target === this.uiCouponTargetSelection.entitled_products) {
      if (isExcluded) {
        data.ids['products'] = this.excludedProductsList
      } else {
        data.ids['products'] = this.entitledProductsList
      }
    }

    return data
  }

  private setProductsSelection(data: ProductsSelectorInterface, target: UICouponTargetSelection, isExcluded: boolean) {
    isExcluded ?
      this.excludeProductsListService.setSelection(data) :
      this.includeProductsListService.setSelection(data)
    if (target === this.uiCouponTargetSelection.entitled_products) {
      if (isExcluded) {
        this.excludedProductsList = data.ids.products;
        this.form.get('data.excluded_product_ids').patchValue(this.excludedProductsList);
      } else {
        this.entitledProductsList = data.ids.products;
        this.form.get('data.entitled_product_ids').patchValue(this.entitledProductsList);
      }
    }
  }

  private _removeUnrelatedProducts(value) {
    const defaultEntitledTargetSelection = value.data._local.defaultEntitledTargetSelection
    const defaultExcludedTargetSelection = value.data._local.defaultExcludedTargetSelection

    delete value.data._local

    this.fillProductOrCollectionIdsCorrect(defaultEntitledTargetSelection, value, false);
    this.fillProductOrCollectionIdsCorrect(defaultExcludedTargetSelection, value, true);

    if (value.type === CouponCodeType.manual_master) {
      delete value.data
    }

    if (value.data && value.type !== CouponCodeType.woo_commerce_unique) {
      delete value.data.expires_after_enabled
      delete value.data.expires_after_days
      delete value.data.expires_after_minutes
      delete value.data.expires_after_hours
      delete value.data.coupon_code_length
    }

    if (value.data) {
      delete value.data.entitled_target_selection
      delete value.data.excluded_target_selection

      value.data.individual_use = !value.data.individual_use
      value.data.allowed_emails = value.data.allowed_emails.map(email => email.label)
    }
    return value
  }

  private fillProductOrCollectionIdsCorrect(targetSelection: UICouponTargetSelection, value, isExcluded: boolean): void {
    const productIdsKey = isExcluded ? 'excluded_product_ids' : 'entitled_product_ids';
    const collectionIdsKey = isExcluded ? 'excluded_collection_ids' : 'entitled_collection_ids';
    const productIds = isExcluded ? this.excludedProductsList : this.entitledProductsList;
    const collectionIds = isExcluded ? this.excludedCollectionsList : this.entitledCollectionsList;
    if (targetSelection === this.uiCouponTargetSelection.all) {
      value.data[productIdsKey] = []
      value.data[collectionIdsKey] = []
    } else if (targetSelection === this.uiCouponTargetSelection.entitled_products) {
      value.data[productIdsKey] = productIds
      value.data[collectionIdsKey] = []
    } else if (targetSelection === this.uiCouponTargetSelection.entitled_collections) {
      value.data[productIdsKey] = []
      value.data[collectionIdsKey] = collectionIds
    }
  }

  private navigateAfterSave() {
    if (!this.isOverlay) {
      this.redirectService.confirmRoute()
      const redirectUrl = this.redirectService.check()
      if (redirectUrl) {
        this.router.navigate([redirectUrl])
      } else {
        this.router.navigate([`/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.coupons}`])
      }
    } else {
      this.closeOverlay.emit(this.createdCoupon)
    }
  }

  private defineInitialData() {
    this.subscription$.add(
      this.store.pipe(
        select(getUserInfo),
        filter(next => !!next),
      ).subscribe((userInfo) => {
        // this.userInfo = userInfo
        this.shopType = _.get(userInfo, 'shop.type')
      })
    )

    this.subscription$.add(
      this.route.data
        .subscribe((resolved: { data: WooCommerceCoupon }) => {
          if (resolved && resolved.data) {
            this.isEditMode = true
            this.entitledProductsList = _.get(resolved, 'data.data.entitled_product_ids', []) as any
            this.excludedProductsList = _.get(resolved, 'data.data.excluded_product_ids', []) as any

            this.initForm(resolved.data)
          } else {
            this.initForm()
          }
        })
    )
    const randomStringValue = this.form.value.data.coupon_code_length || 8
    this.isCartSelected = this.form.get('data.value_type').value === DiscountType.FixedCart || false;
    this.updateRandomString(randomStringValue)
  }

  private getMasterCodeValidator(couponType: CouponCodeType) {
    if (couponType === CouponCodeType.woo_commerce_unique) {
      return Validators.required;
    }
    return null;
  }

  private setValueValidators() {
    const discountType = this.form.get('data.value_type').value
    if (discountType === DiscountType.Percentage) {
      this.valueValidator = [Validators.min(0), Validators.max(100), Validators.required]
    } else if ([DiscountType.FixedProduct, DiscountType.FixedCart].includes(discountType)) {
      this.valueValidator = [Validators.min(0), Validators.required]
    } else {
      this.valueValidator = null
    }
  }

  private initUsageLimit(controlName: string, coupon?: WooCommerceCoupon): boolean {
    if (!coupon) {
      return false
    }
    return coupon.data[`${controlName}`] && coupon.data[`${controlName}`] > 0
  }

  private initForm(coupon?: WooCommerceCoupon): void {
    const freeShippingPopup = this.popupType === CampaignPluginName.FreeShipping
    const defaultCouponType =  this.onlyStaticCodeAllowed ? CouponCodeType.woo_commerce_master : CouponCodeType.woo_commerce_unique
    this.form = this.formBuilder.group({
      id: _.get(coupon, 'id', null),
      title: [_.get(coupon, 'title', null), Validators.required],
      type: [{
        value: _.get(coupon, 'type', null) || defaultCouponType,
        disabled: this.isEditMode
      }],
      master_code: [{
        value: _.get(coupon, 'master_code', null),
        disabled: this.isEditMode,
      }, this.getMasterCodeValidator(_.get(coupon, 'type', null))],

      prefix_code: [_.get(coupon, 'prefix_code', '')],
      data: this.formBuilder.group({
        coupon_code_length: [_.get(coupon, 'data.coupon_code_length', 8), [Validators.min(6), Validators.max(16)]],

        expires_after_enabled: [{
          value: _.get(coupon, 'data.expires_after_enabled', false),
          disabled: this.isEditMode
        }],
        expires_after_days: [{
          value: _.get(coupon, 'data.expires_after_days', 1),
          disabled: this.isEditMode
        }, [Validators.min(0), Validators.max(365)]],
        expires_after_minutes: [{
          value: _.get(coupon, 'data.expires_after_minutes', 0),
          disabled: this.isEditMode
        }, [Validators.min(0), Validators.max(59)]],
        expires_after_hours: [{
          value: _.get(coupon, 'data.expires_after_hours', 0),
          disabled: this.isEditMode
        }, [Validators.min(0), Validators.max(24)]],

        starts_at: [_.get(coupon, 'data.starts_at', this.currentDate)],
        // ends_at: [_.get(coupon, 'data.ends_at', null)],
        usage_limit: [{
          value: _.get(coupon, 'data.usage_limit', 1),
          disabled: this.isEditMode
        }, [Validators.min(0), Validators.max(99999999)]],
        value: [{
          value: _.get(coupon, 'data.value', 0),
          disabled: this.isEditMode,
        }, this.valueValidator],
        value_type: [{
          value: _.get(coupon, 'data.value_type', DiscountType.Percentage),
          disabled: this.isEditMode
        }, Validators.required],
        free_shipping: [{
          value: _.get(coupon, 'data.free_shipping', freeShippingPopup || false),
          disabled: this.isEditMode,
        }],
        individual_use: [{
          value: this.initIndividualUse(coupon),
          disabled: this.isEditMode,
        }],
        exclude_sale_items: [{
          value: _.get(coupon, 'data.free_shipping', false),
          disabled: this.isEditMode,
        }],
        usage_limit_per_customer: [{
          value: _.get(coupon, 'data.usage_limit_per_customer', 1),
          disabled: this.isEditMode,
        }, [Validators.min(0), Validators.max(99999999)]],
        limit_usage_to_x_items: [_.get(coupon, 'data.limit_usage_to_x_items', 0), [Validators.min(0), Validators.max(99999999)]],
        prerequisite_subtotal_min: [{
          value: _.get(coupon, 'data.prerequisite_subtotal_min', 0),
          disabled: this.isEditMode,
        }],
        prerequisite_subtotal_max: [{
          value: _.get(coupon, 'data.prerequisite_subtotal_max', 0),
          disabled: this.isEditMode,
        }],

        entitled_target_selection: [_.get(coupon, 'data.entitled_target_selection', this.couponCodeTargetSelection.all)],
        excluded_target_selection: [_.get(coupon, 'data.excluded_target_selection', this.couponCodeTargetSelection.entitled)],
        entitled_product_ids: [_.get(coupon, 'data.entitled_product_ids', [])],
        excluded_product_ids: [_.get(coupon, 'data.excluded_product_ids', [])],
        entitled_collection_ids: [_.get(coupon, 'data.entitled_collection_ids', [])],
        excluded_collection_ids: [_.get(coupon, 'data.excluded_collection_ids', [])],
        allowed_emails: [{
          value: this.initAllowedEmail(coupon),
          disabled: this.isEditMode,
        }],

        _local: this.formBuilder.group({
          isUsageLimit: [{
            value: this.initUsageLimit('usage_limit', coupon),
            disabled: this.isEditMode
          }],
          isUserUsageLimit: [{
            value: this.initUsageLimit('usage_limit_per_customer', coupon),
            disabled: this.isEditMode
          }],
          isProductUsageLimit: [{
            value: this.initUsageLimit('limit_usage_to_x_items', coupon),
            disabled: this.isEditMode
          }],
          defaultEntitledTargetSelection: [{
            value: this.initTargetSelection(coupon, false),
            disabled: this.isEditMode,
          }],
          defaultExcludedTargetSelection: [{
            value: this.initTargetSelection(coupon, true),
            disabled: this.isEditMode,
          }],
        })
      }, { validators: [this.hourOrDayRequired, this.discountOrFreeShippingRequired] })
    });
    this.setValueValidators();
    this.initSelectorsData(coupon);
  }

  initIndividualUse(coupon: WooCommerceCoupon) {
    // !value because meaning on WooCommerce API is incorrect, that's why it done in this way
    const value = _.get(coupon, 'data.individual_use', true);
    return !value;
  }

  initAllowedEmail(coupon: WooCommerceCoupon) {
    if (!coupon) {
      return []
    }

    const emails = coupon.data.allowed_emails || [];
    if (emails.length === 0) {
      return []
    }
    return emails;
  }

  initSelectorsData(coupon: WooCommerceCoupon) {
    if (!coupon) {
      // If new coupon
      const data = this.makeSelectorData(this.uiCouponTargetSelection.all, false)
      this.includeProductsListService.setSelection(data)
    } else {
      const includedTargetSelection = this.initTargetSelection(coupon, false)
      const includedData: ProductsSelectorInterface = this.makeSelectorData(includedTargetSelection, false)
      this.includeProductsListService.setSelection(includedData);
      const excludedTargetSelection = this.initTargetSelection(coupon, true)
      const excludedData: ProductsSelectorInterface = this.makeSelectorData(excludedTargetSelection, true)
      this.excludeProductsListService.setSelection(excludedData);
    }
  }

  initTargetSelection(coupon: WooCommerceCoupon, isExcluded: boolean): UICouponTargetSelection {
    if (!coupon) {
      return isExcluded ? this.uiCouponTargetSelection.entitled_products : this.uiCouponTargetSelection.all
    }
    if (coupon.data.target_selection === this.couponCodeTargetSelection.all) {
      return this.uiCouponTargetSelection.all
    } else {
      if (_.get(coupon, 'data.entitled_product_ids.length')) {
        return this.uiCouponTargetSelection.entitled_products
      } else {
        return isExcluded ? this.uiCouponTargetSelection.entitled_products : this.uiCouponTargetSelection.all
      }
    }
  }

  private updateSummary(randomString) {
    const raw = this.form.getRawValue()
    this.summary = this.wooCommerceCouponService.updateSummary(raw, randomString)
  }

  // Random string is
  private updateRandomString(val) {
    this.randomString = NewCouponCodesService.randomString(parseInt(val, 10))
    this.updateSummary(this.randomString)
  }

  private initFormWatchers() {
    this.subscription$.add(
      this.form.valueChanges.subscribe((value: WooCommerceCoupon) => {
        this.form.markAsUntouched();
        this.logger.log(<LogLabel>'new-coupon-code', 'updated_form:', value)
        this.updateSummary(this.randomString)
      })
    )
    this.subscription$.add(
      this.form.get('type').valueChanges.subscribe((value: CouponCodeType) => {
        if (this.form.get('type').value === CouponCodeType.woo_commerce_master) {
          this.form.get('master_code').setValidators(Validators.required);
          this.form.get('data.usage_limit').patchValue(0);
          this.form.get('data.usage_limit_per_customer').patchValue(0);
        } else if (this.form.get('type').value === CouponCodeType.manual_master) {
          this.form.get('master_code').setValidators(Validators.required);
        } else {
          this.form.get('master_code').setValidators(null);
          this.form.get('data.usage_limit').patchValue(1);
          this.form.get('data.usage_limit_per_customer').patchValue(1);
        }
        this.resetFormDataInCaseCouponTypeChanged()
      })
    )
    this.subscription$.add(
      this.form.get('data.value_type').valueChanges.subscribe((value: DiscountType) => {
        this.form.get('data.limit_usage_to_x_items').patchValue(0);
        this.form.get('data._local.isProductUsageLimit').patchValue(false);
        this.isCartSelected = value === DiscountType.FixedCart || false
      })
    )
  }

  private hourOrDayRequired(group: UntypedFormGroup): { [s: string]: boolean } {
    const daysCtrl = group.controls['expires_after_days']
    const hoursCtrl = group.controls['expires_after_hours']
    const minutesCtrl = group.controls['expires_after_minutes']
    const enabledCtrl = group.controls['expires_after_enabled']
    if (enabledCtrl.value) {
      if (daysCtrl.value === 0 && hoursCtrl.value === 0 && minutesCtrl.value === 0) {
        return { expiresError: true }
      } else {
        return null
      }
    } else {
      return null
    }
  }

  discountOrFreeShippingRequired(group: UntypedFormGroup): { [s: string]: boolean } {
    const value = group.get('value').value;
    const freeShipping = group.get('free_shipping').value;
    if (value === 0 && freeShipping === false) {
      return {discountError: true}
    } else if (value > 0 || freeShipping === true) {
      return null
    }
  }

  isDiscountError() {
    return this.form.get('data').hasError('discountError') &&
      (this.form.get('data.value').touched || this.form.get('data.free_shipping').touched)
  }
}
