import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { Store } from '@ngrx/store'
import { combineLatest, Subscription } from 'rxjs'
import { PluginConfigureSteps } from '../../../pages/campaign-page/plugin-configure/models/plugin-configure.models'
import { Campaign, CampaignPluginName, CampaignState } from '../../models/campaign/campaign'
import { StoreState } from '../../../store/store.state'
import { CampaignSaveService } from '../../../pages/campaign-page/campaign/services/save.service'
import { campaignStepsMap, pluginMap, pluginNameMap } from './breadcrumbs.model'
import { UserShopType } from '../../models/user/user-shop-type.model'
import { AllCampaignService } from '../../../pages/campaign-page/services/all-campaign.service'
import { HideLoading, ShowLoading } from '../../../store/loading/loading.actions'
import { CampaignDataService } from '../../../core/services/data-services/campaign-data.service'
import _ from 'lodash'
import { PluginConfigureNavigationService } from '../../../pages/campaign-page/plugin-configure/services/plugin-configure-navigation.service'
import { RoutePluginName } from '../../../pages/campaign-page/plugin-configure/models/plugin-configure-navigation.model'
import { CampaignRouteStep } from '../../../core/services/campaign-url.service'
import { UserService } from '../../../core/services/user.service'
import { RouteHeaderUrl } from '../one-header/header-navigation.model'
import { CommonModule } from '@angular/common'
import { MatTooltipModule } from '@angular/material/tooltip'
import { OneIconComponent } from '../one-icon/one-icon.component'

@Component({
    selector: 'pf-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    imports: [
        CommonModule,
        RouterModule,
        MatTooltipModule,
        OneIconComponent,
    ],
    providers: [
        CampaignSaveService,
        PluginConfigureNavigationService,
    ]
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  @Input() isCampaignLevel = false
  @ViewChild('menu', {static: false}) menu: ElementRef
  backBtnUrl = `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}`
  selectedCampaignId = ''
  steps = PluginConfigureSteps
  CampaignPluginName = CampaignPluginName
  pluginName: string
  selectedPluginIcon: string
  plugin: RoutePluginName
  currentCampaignName = ''
  campaignList: Campaign[] = []
  campaignsActiveWidgets = {}
  shopType: UserShopType
  subMenuOpen = {}
  RouteHeaderUrl = RouteHeaderUrl
  private subscription$ = new Subscription()

  constructor(
    private router: Router,
    private store: Store<StoreState>,
    private campaignSaveService: CampaignSaveService,
    private allCampaignService: AllCampaignService,
    private campaignDataService: CampaignDataService,
    private userService: UserService,
    private pluginConfigureNavigationService: PluginConfigureNavigationService,
  ) {
  }


  ngOnInit() {
    this.shopType = _.get(this.userService.userInfo, 'shop.type', null)
    this.setPluginName()
    this.subscription$.add(combineLatest([
      this.campaignDataService.asObservable(),
      this.allCampaignService.getAllCampaigns()
      ])
        .subscribe(([selectedCampaign, allCampaigns]) => {
          if (selectedCampaign) {
            this.selectedCampaignId = selectedCampaign.id
            this.currentCampaignName = selectedCampaign.name
            this.setCampaignData(selectedCampaign, allCampaigns)
            if (!this.isCampaignLevel) {
              this.backBtnUrl = `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${selectedCampaign.id}`
            }
          }
        })
    )
  }


  setCampaignData(selectedCampaign, campaigns) {
    if (campaigns.length) {
      this.campaignList = campaigns.filter((campaign => campaign.id !== selectedCampaign.id && campaign.state !== CampaignState.Archived))
      this.campaignList.unshift(selectedCampaign)
      this.campaignList.forEach(campaign => {
        this.subMenuOpen[campaign.id] = false
      })
      this.setActiveWidgets()
    }
  }

  setPluginName() {
    const routeSegmentsArray = this.router.url.split('/')
    const plugin = routeSegmentsArray[routeSegmentsArray.length - 2]
    if (pluginNameMap[plugin]) {
      this.pluginName = pluginNameMap[plugin].name
      this.selectedPluginIcon = pluginNameMap[plugin].icon
    }
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe()
  }

  navigateToPlugin(campaignId: string, widget: string, event) {
    event.stopPropagation()
    const step = this.router.url.split('/').pop() as CampaignRouteStep
    const stepTo = step ? this.stepTransfer(step, widget) : ''
    const url = `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${campaignId}/configure/${widget}/${stepTo}/`
    this.campaignDataService.getCampaign(campaignId)

    this.router.navigate([url]).then(() => {
      const steps = this.pluginConfigureNavigationService.get()
      this.pluginConfigureNavigationService.set(steps)
    })

  }

  stepTransfer(step: string, widget) {
    if (step === 'reward' && widget === RoutePluginName.FortuneWheel) {
      return 'rewards'
    }
    if (step === 'rewards' && widget === RoutePluginName.CouponBox) {
      return 'reward'
    }
    return step
  }

  setActiveWidgets() {
    const list = {}
    this.campaignList.map(campaign => {
      const activeWidgets = []
      if (campaign.state !== CampaignState.Archived) {
        Object.keys(campaign.plugin_types).forEach(plugin => {
          if (campaign[plugin]?.active) {
            activeWidgets.push({
              id: campaign[plugin].id,
              name: pluginMap[plugin].name,
              url: pluginMap[plugin].url,
              icon: pluginMap[plugin].icon,
            })
          }
        })
        list[campaign.id] = activeWidgets
      }
    })
    this.campaignsActiveWidgets = list
  }

  navigateToCampaign(campaignId: string) {
    const campaignToNavigate = this.campaignList.find((campaign => campaign.id === campaignId))
    const activeStep =  this.router.url.split('/').pop() as CampaignRouteStep
    const campaignToNavigateStep = campaignStepsMap[activeStep] ? activeStep : CampaignRouteStep.APPS
    const nextCampaignStepPresent = this.campaignSaveService.shouldCampaignStepBeVisible({
      step: campaignToNavigateStep,
      label: campaignToNavigateStep
    }, campaignToNavigate, !!this.shopType)

    this.store.dispatch(new ShowLoading(campaignId))

    const url = nextCampaignStepPresent ?`/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${campaignId}/${campaignToNavigateStep}` : `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${campaignId}/apps`
    this.campaignDataService.getCampaign(campaignId)
    this.router.navigate([url]).then(() => {
      this.store.dispatch(new HideLoading(campaignId))
    })

  }

  toggleSubMenu(id: string) {
    this.subMenuOpen[id] = !this.subMenuOpen[id]
  }

  collapseSubMenus() {
    Object.keys(this.subMenuOpen).forEach(key => {
      this.subMenuOpen[key] = false
    })
  }

  navigateToCurrentCampaign() {
    if (!this.isCampaignLevel) {
      this.router.navigate([`/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${this.selectedCampaignId}/`])
    }
  }

  adjustMenuWidth(event) {
    event.stopPropagation()
    const MIN_WIDTH = 250
    const breadcrumbsContainerWidth = (document.querySelector('.breadcrumbs') as HTMLElement)?.offsetWidth
    const breadCrumbItems = document.querySelectorAll('.breadcrumbs-item')
    let breadcrumbItemsWidth = 0
    // calculate width of all breadcrumb items
    breadCrumbItems.forEach((element: HTMLElement) => {
      breadcrumbItemsWidth += this.calculateOuterWidth(element)
    })
    this.menu.nativeElement.style.minWidth = `${MIN_WIDTH}px`
    // calculate available width which is left for menu
    const availableWidth = breadcrumbsContainerWidth - breadcrumbItemsWidth + this.calculateOuterWidth(breadCrumbItems[breadCrumbItems.length - 1])
    if (this.menu.nativeElement.offsetWidth > availableWidth) {
      if (MIN_WIDTH > availableWidth) {
        this.menu.nativeElement.style.left = `${availableWidth - MIN_WIDTH}px`
        this.menu.nativeElement.style.width = `${MIN_WIDTH}px`
        return
      }
      this.menu.nativeElement.style.width = `${availableWidth}px`
    }
  }

  calculateOuterWidth(element) {
    let width = element.offsetWidth
    const style = getComputedStyle(element)
    width += parseInt(style.marginLeft) + parseInt(style.marginRight)
    return width
  }

}
