import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { SideNavigationStatusColor } from '../../enums/side-navigation-status-color.enum'

@Component({
    selector: 'pf-side-navigation-bar-sub-headline',
    templateUrl: './side-navigation-bar-sub-headline.component.html',
    styleUrls: ['./side-navigation-bar-sub-headline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SideNavigationBarSubHeadlineComponent {
  // Sub-headline label
  @Input() subHeadline: string;
  @Input() tooltipText: string;

  // Props for status
  @Input() statusLabel: string;
  @Input() statusColor: SideNavigationStatusColor;

  @Input() expanded: boolean;
}
