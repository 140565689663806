import {Injectable} from '@angular/core'
import {environment} from '../../../../environments/environment'
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http'

import {Observable, map} from 'rxjs'
import { UserService } from '../user.service'
import { StripeJotformCheckoutPayload, jotformQidMap } from '../../../pages/cart/models/cart-models'
import { SMSPhoneNumberVerificationStatus } from '../../../pages/sms/models/sms.models'

export interface JotformResponse<T> {
  responseCode: number
  message: string
  content: T
  duration: string
  "limit-left": number
}

export interface JotformAnswer {
  name: string
  order: string
  text: string
  type: string
  answer: {
    text: string
  }
}

export interface JotformSubmission {
  id: string
  form_id: string
  ip: string
  created_at: string
  status: string
  new: string
  flag: string
  notes: string
  updated_at: string
  answers: {
    [key: number]: JotformAnswer
  }
}

export interface JotformSubmissionPutResponse {
  URL: string
  submissionID: string
}

// TODO: move all jotform functionality here and discard their broken api library

@Injectable()
export class ApiJotformService {
  jotformApiUrl = 'https://api.jotform.com'
  constructor(
    private http: HttpClient,
    private userService: UserService,
    handler: HttpBackend) {
      this.http = new HttpClient(handler)
  }

  editSubmission(submissionId, data = {}): Observable<any> {
    return this.http.post(`${this.jotformApiUrl}/submission/${submissionId}?apiKey=${environment.jotFormApiKey}`, JSON.stringify(data))
  }

  readDmarcForm(): Observable<{[key: string]: boolean}> {
    const filter = encodeURI(JSON.stringify({
      fullText: this.userService.userInfo?.id,
      'status:ne': 'DELETED',
    }))
    return this.http.get<JotformResponse<JotformSubmission[]>>(
      `${this.jotformApiUrl}/form/240304364127043/submissions?apiKey=${environment.jotFormApiKey}&filter=${filter}`
    ).pipe(map((response: JotformResponse<JotformSubmission[]>) => {
      const accountId = this.userService.userInfo?.id
      // Get dmarc records only related to the account id
      const relatedDmarcRecords = response?.content?.filter(
        item => Object.values(item?.answers)?.some(
          (answer: any) => answer.name === 'accountId' && answer.answer === accountId
        )
      ).map((item: any) => {
        // Get the answers only and return them as an array
        return Object.values(item?.answers)
      })
      // Create an object with the domain as key and the dmarc status as value
      let domainsDmarcStatuses: {[key: string]: boolean} = {}
      // Loop through the dmarc records and populate the object
      relatedDmarcRecords?.map((record: any) => {
        // Get the domain and the dmarc status
        const domain = record?.find((item: any) => item?.name === 'domain')?.answer
        const status = record?.find((item: any) => item?.name === 'dmarcDns')?.answer === 'True'
        if (domain) {
          domainsDmarcStatuses[domain] = status
        }
      })
      return domainsDmarcStatuses
    }))
  }

  readPopupExtensionForm(): Observable<{ enabled: boolean, submissionId: string }> {
    const accountId = this.userService.userInfo?.id

    const filter = encodeURI(JSON.stringify({
      fullText: accountId,
      'status:ne': 'DELETED',
    }))
    return this.http.get<JotformResponse<JotformSubmission[]>>(
      `${this.jotformApiUrl}/form/240453734613049/submissions?apiKey=${environment.jotFormApiKey}&filter=${filter}`,
    ).pipe(map((response: JotformResponse<JotformSubmission[]>) => {

      const answers = response?.content?.filter(
        item => Object.values(item?.answers)?.some(
          (answer: any) => answer.name === 'accountId' && answer.answer === accountId
        ),
      ).map((item: any) => {
        // Get the answers only and return them as an array
        return Object.values(item?.answers)
      })
      let enabled: boolean = false
      answers?.map((answer: any) => {
        enabled = answer?.find((item: any) => item?.name === 'popUp')?.answer === 'True'
      })
      return { enabled, submissionId: response?.content[0]?.id || null }
    }))
  }

  readStripeCheckout() {
    const accountId = this.userService.userInfo?.id

    const filter = encodeURI(JSON.stringify({
      fullText: accountId,
      'status': 'ACTIVE',
    }))
    return this.http.get<JotformResponse<JotformSubmission[]>>(`${this.jotformApiUrl}/form/240654133750350/submissions?apiKey=${environment.jotFormApiKey}&filter=${filter}`).pipe(
      map((response: JotformResponse<JotformSubmission[]>) => {
        return response?.content
      })
    )
  }

  readSubmissionById(submissionID: string): Observable<JotformResponse<JotformSubmission>> {
    const filter = encodeURI(JSON.stringify({
      fullText: submissionID,
      'status:ne': 'DELETED',
    }))
    return this.http.get<JotformResponse<JotformSubmission>>(`${this.jotformApiUrl}/submission/${submissionID}?apiKey=${environment.jotFormApiKey}&filter=${filter}`)
  }

  writeStripeCheckout(data: StripeJotformCheckoutPayload): Observable<JotformResponse<JotformSubmissionPutResponse>> {
    const payload = {}
    Object.keys(data).forEach(key => {
      payload[jotformQidMap[key]] = { text: data[key] }
    })
    return this.http.put<JotformResponse<JotformSubmissionPutResponse>>(
      `${this.jotformApiUrl}/form/240654133750350/submissions?apiKey=${environment.jotFormApiKey}`,
      [payload]
    )
  }

  normalizeAnswers(answers: {[key: number]: JotformAnswer}, id: string): {[key: string]: JotformAnswer} {
    const normalizedAnswers = {}
    Object.keys(answers)?.forEach((key) => {
      normalizedAnswers[answers[key].name] = answers[key]
    })
    normalizedAnswers['id'] = id
    return normalizedAnswers
  }

  readSMSPhoneNumberForm(): Observable<{ status: SMSPhoneNumberVerificationStatus, submissionId: string }> {
    const accountId = this.userService.userInfo?.id

    const filter = encodeURI(JSON.stringify({
      fullText: accountId,
      'status:ne': 'DELETED',
    }))
    return this.http.get<JotformResponse<JotformSubmission[]>>(
      `${this.jotformApiUrl}/form/240951558710357/submissions?apiKey=${environment.jotFormApiKey}&filter=${filter}`,
    ).pipe(map((response: JotformResponse<JotformSubmission[]>) => {

      const answers = response?.content?.filter(
        item => Object.values(item?.answers)?.some(
          (answer: any) => answer.name === 'accountId' && answer.answer === accountId
        ),
      ).map((item: any) => {
        // Get the answers only and return them as an array
        return Object.values(item?.answers)
      })
      let status  = null
      answers?.forEach((answer: any) => {
        status = answer?.find((item: any) => item?.name === 'status')?.answer || null
      })
      return { status, submissionId: response?.content[0]?.id || null }
    }))
  }

}
