import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'pf-auth-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    standalone: false
})
export class LayoutComponent implements OnInit {
  isShopifyDomain = window.location.hostname === 'shopify.one.store'
  constructor() { }

  ngOnInit() {
  }

}
