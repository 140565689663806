import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import { ListResult } from '@angular/fire/compat/storage/interfaces'
import { Subscription } from 'rxjs'
import { UserService } from '../../../../../core/services/user.service'
import { ImageUploadService } from '../../services/image-upload.service'

@Component({
    selector: 'pf-image-upload-lib',
    styleUrls: ['./image-upload-lib.component.scss'],
    templateUrl: './image-upload-lib.component.html',
    standalone: false
})
export class ImageUploadLibComponent implements OnInit, OnDestroy {
  @Output() selectedImage: EventEmitter<string> = new EventEmitter()
  @Input() path: string = 'images'
  private subscription = new Subscription()
  private userId = this.userService.userInfo.id
  public imgList: string[] = []
  public selectedId: number = null;
  constructor(
    private userService: UserService,
    private imageUploadService: ImageUploadService,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.storage.ref(`${this.userId}/${this.path}`).listAll().subscribe((res: ListResult) => {
        this.imgList = []
        if (res?.items.length) {
          res.items.forEach(item => {
            // const storageRef = this.storage.ref(item.fullPath)
            // this.subscription.add(
            // storageRef.getMetadata().subscribe(data => {
            //   debugger
            // })
            // )
            const bucket = item.bucket === 'cdn.prooffactor.com' ? 'cdn.one.store' : item.bucket
            this.imgList.push(`https://${bucket}/${item.fullPath}`)
          })
        }
      })
    )

    // if user upload and select other image, upload image will be gone until reopen the modal, so we need to keep upload image updated
    this.subscription.add(
      this.imageUploadService.imageUploaded$.subscribe(res => {
        this.imgList.push(res)
        this.selectedId = this.imgList.length - 1
      })
    )
  }

  selected(img: string, index: number) {
    this.selectedId = index
    this.selectedImage.emit(img)
    this.imageUploadService.imageSelected$.next(img)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
