import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { CouponCode } from '../../models/coupon-code'

@Component({
    selector: 'pf-overlay-coupon-code',
    templateUrl: './overlay-coupon-code.component.html',
    styleUrls: ['./overlay-coupon-code.component.scss'],
    standalone: false
})
export class OverlayCouponCodeComponent implements OnInit {
  @Output() closeOverlay = new EventEmitter<CouponCode>()

  constructor() { }

  ngOnInit(): void { }
}
