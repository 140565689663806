import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ApiService } from '../../../../core/services/api/api.service';
import { HideLoading, ShowLoading } from '../../../../store/loading/loading.actions';
import { StoreState } from '../../../../store/store.state';
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface VoteFeatureModalData {
  featureType: FeatureTypeModal
  isWaitingList?: boolean
}

export type CustomersType = 'customers_profiles' 
| 'customers_segments'

export type ProductFeatureType = 'products_text_reviews' 
| 'products_photo_reviews' 
| 'products_video_reviews' 
| 'products_tags_generated_tags' 
| 'products_images_generated_scenes' 
| 'products_images_removed_bg'

export type FeatureTypeModal = 'loyalty_point' 
| 'download_links' 
| 'store_credits' 
| 'whats_app' 
| 'push_notification' 
| 'upsell_one_click_checkout' 
| ProductFeatureType 
| CustomersType 
| 'one_store_copilot' 
| 'sales_channel_tiktok_store'
| 'sales_channel_instagram_store'
| 'sales_channel_youtube_store'
| 'sales_channel_ebay_store'
| 'sales_channel_amazon_store'
| 'sales_channel_google_store'
| 'sales_channel_bing_store'

@Component({
    templateUrl: './vote-feature.modal.component.html',
    styleUrls: ['./vote-feature.modal.component.scss'],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatFormFieldModule,
        MatOptionModule,
        LoadingOverlayComponent,
    ]
})
export class VoteFeatureModalComponent implements OnInit, OnDestroy {
  private subscription = new Subscription()
  requestForm: UntypedFormGroup
  nameMaxLength = 120
  descMaxLength = 280
  descTypeLength = 0
  isCopilot = false
  requestDetails = ''
  betaFeatureTitle = {
    upsell_one_click_checkout: 'Upsell - One-Click Checkout',
    customers_segments: 'Customer Segments',
    one_store_copilot: 'ONE Store Co-Pilot (AI Superhero)',
    sales_channel_tiktok_store: 'Sales Channel TikTok Store',
    sales_channel_instagram_store: 'Sales Channel Instagram Store',
    sales_channel_youtube_store: 'Sales Channel YouTube Store',
    sales_channel_ebay_store: 'Sales Channel eBay Store',
    sales_channel_amazon_store: 'Sales Channel Amazon Store',
    sales_channel_google_store: 'Sales Channel Google Store',
    sales_channel_bing_store: 'Sales Channel Bing Store',
  }

  constructor(
    private store: Store<StoreState>,
    private apiService: ApiService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<VoteFeatureModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VoteFeatureModalData) {
  }

  ngOnInit(): void {
    this.initForm()
    if (this.data && this.data.featureType) {
      this.isCopilot = this.data.featureType === 'one_store_copilot'
      this.getFeatureRequestsDetail(this.data.featureType)
    }
    this.subscription.add(
      this.requestForm.get('description').valueChanges.subscribe(word => {
        this.descTypeLength = word.length
      })
    )
  }

  get waitlistTitle() {
    switch (this.data?.featureType) {
      case 'upsell_one_click_checkout':
        return 'Request'
      default:
        return 'Join Waitlist'
    }
  }

  get waitlistButtonText() {
    switch (this.data?.featureType) {
      case 'upsell_one_click_checkout':
        return 'Request Access'
      default:
        return 'Join Waitlist'
    }
  }
  private getFeatureRequestsDetail(type: string) {
    if (!type) {
      return
    }
    this.store.dispatch(new ShowLoading('getCannyFeatureRequests'))
    this.subscription.add(this.apiService.get(`/v1/me/canny/feature_requests/${type}`)
      .pipe(finalize(() => this.store.dispatch(new HideLoading('getCannyFeatureRequests'))))
      .subscribe(
        res => {
          this.requestDetails = _.get(res, 'data.details', '')
        },
        error => {
          console.error('Unable to getCannyFeatureRequests', error)
        }
      ))
  }

  initForm() {
    this.requestForm = this.fb.group({
      type: [this.data.featureType, Validators.required],
      description: ['', [Validators.maxLength(this.descMaxLength)]],
    })
  }

  sendRequest() {
    this.requestForm.updateValueAndValidity()
    Object.keys(this.requestForm.controls).forEach(key => {
      this.requestForm.get(key).markAsTouched()
      this.requestForm.get(key).markAsDirty()
    })
    if (!this.requestForm.valid) {
      return
    }
    this.dialogRef.close({ request: this.requestForm.value })
  }

  updateType(event: any) {
    const option = event.value
    this.getFeatureRequestsDetail(option)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
