import { Component, OnInit, Inject } from '@angular/core'
import { CrispService } from '../../../core/services/crisp.service';
import { SnackbarType } from './custom-snackbar.service';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'pf-snackbar',
    templateUrl: './custom-snackbar.component.html',
    standalone: false
})

export class CustomSnackbarComponent implements OnInit {
  progress = 100
  remainingDuration = 0
  buttonColor = {
    [SnackbarType.Success]: 'green',
    [SnackbarType.Error]: 'red',
    [SnackbarType.Warning]: 'yellow',
    [SnackbarType.Info]: 'blue'
  }

  constructor(
    private crispService: CrispService,
    public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.duration) {
      this.remainingDuration = this.data.duration
      const interval = setInterval( () => {
        this.progress = this.progress - 5
        if (this.remainingDuration <= 0) {
          clearInterval(interval)
        }
        this.remainingDuration -= this.data.duration / 20
      }, this.data.duration / 20)
    }
  }

  public dismissSnackbar(): void {
    this.snackBarRef.dismiss();
  }

  openCrisp() {
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', this.data.crisp && this.data.crisp.send ? this.data.crisp.send : 'Hello Team, I need some help']])
      if (this.data.crisp && this.data.crisp.show) {
        crisp.push(['do', 'message:show', ['text', this.data.crisp.show]])
      }
    }
  }
}
