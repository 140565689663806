import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core'
import { ProductsListService } from '../../services/products-list.service'
import { Subscription } from 'rxjs'
import {
  ProductsSelectorInterface,
  Product,
  Collection,
  ProductsResponse,
  CollectionsResponse,
  ProductVariant,
} from '../../models/Product'
import { UICouponTargetSelection } from '../../../../../pages/coupon-codes/models/coupon-code'
import * as _ from 'lodash'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../../../store/store.state'
import { ShowLoading, HideLoading, ResetLoading } from '../../../../../store/loading/loading.actions'
import de from '../../../../../../../proof-factor-language-service/locales/time-ago/de'
import { E } from '@angular/cdk/keycodes'
import { MatPaginator, PageEvent } from '@angular/material/paginator'

@Component({
    selector: 'pf-products-output',
    templateUrl: './products-output.component.html',
    styles: [`
    ._last-btn-hidden ::ng-deep  .mat-mdc-paginator-range-label,
    ._last-btn-hidden ::ng-deep  .mat-mdc-paginator-navigation-last {
      display: none;
    }
  `],
    standalone: false
})
export class ProductsOutputComponent implements OnInit, OnDestroy {
  @ViewChild('productsList', {static: true}) productsListRef: ElementRef
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild('paginator', { read: ElementRef }) paginatorRef: ElementRef
  @Output() change = new EventEmitter<ProductsSelectorInterface>()
  @Input() clearable = false
  @Input() displayQuantity = false
  @Input() noValidate = false
  @Input() showOutOfStock = false
  @Input() productsListService: ProductsListService
  @Input() emptyText: string
  @Input() customClass: string = ''
  @Input() hidePagination: boolean = false
  @Input() set setSelection(selection: ProductsSelectorInterface) {
    this.selection = selection
    this.processSelection()
  }
  public itemsPerPage = 20
  public pageSizeOptions = [10, 20]
  public pageIndex = 0
  public totalItems = 0
  public nothingSelectedText = ''
  subscription: Subscription = new Subscription()
  selection: ProductsSelectorInterface
  output: string = ''
  outputList: Product[] | Collection[] | any
  isInvalid: boolean = false
  uiCouponTargetSelection = UICouponTargetSelection
  outOfStockError = null

  constructor(
    private store: Store<StoreState>,
  ) {
  }

  ngOnInit(): void {
    if (this.selection) {
      this.processSelection()
    } else {
      this.subscription.add(
        this.productsListService.selectionAsObservable().subscribe(selection => {
          if (selection) {
            this.selection = selection
            this.processSelection()
          }
        }),
      )
    }
  }

  processSelection() {
    this.productsListService.productsOnly = this.selection.productsOnly || false
    if (this.selection.type === this.uiCouponTargetSelection.entitled_products) {
      this.getProducts(this.selection.ids.products, this.selection.ids.variants, 0, this.itemsPerPage)
      this.paginator._intl.itemsPerPageLabel = 'Products per page'
    } else if (this.selection.type === this.uiCouponTargetSelection.entitled_collections) {
      this.getCollections(this.selection.ids.collections, 0, this.itemsPerPage)
      this.paginator._intl.itemsPerPageLabel = 'Collections per page'
    }
  }

  getProducts(productUniqueIDs: Array<string>, variantUniqueIDs: Array<string>, page: number, limit: number) {
    this.outOfStockError = null
    this.store.dispatch(new ShowLoading('ProductsSelector'))
    this.subscription.add(this.productsListService.getProductsByUniqueID(productUniqueIDs, variantUniqueIDs, page + 1, limit).subscribe(res => {
      this.outputList = (res as ProductsResponse).products

      // remove variant from output list if they are not on variantUniqueIDs array
      // TODO BE returns all variants despite passing variant ids, remove this piece of code after BE fix
      if (variantUniqueIDs?.length) {
        this.outputList.forEach(product => {
          product.variants = product.variants.filter(variant => variantUniqueIDs.includes(variant.unique_id))
        })
      }

      if (this.showOutOfStock && this.outputList.length > 0) {
        const product = this.outputList[0]
        if (product.variants?.length === 0) {
          if (!!product?.inventory_management && product?.inventory_policy === 'deny' && product?.inventory_quantity < 1) {
            this.outOfStockError = 'Product is out of stock'
          }
        } else {
          const outOfStockCount = product?.variants?.filter(variant => !!variant?.inventory_management && variant?.inventory_policy === 'deny' && variant?.inventory_quantity < 1)?.length
          if (outOfStockCount) {
            this.outOfStockError = `${outOfStockCount} variant(s) are out of stock`
          }
        }
      }

      // since we don't have total_count for products, we need to disable next button when we get less items than pageSize
      const nextButton = this.paginatorRef?.nativeElement?.querySelector('.mat-mdc-paginator-navigation-next') as HTMLButtonElement
      if (nextButton) {
        setTimeout(() => {
          nextButton.disabled = this.outputList?.length < this.paginator.pageSize
        })
      }

      this.store.dispatch(new HideLoading('ProductsSelector'))
      this.totalItems = Number.POSITIVE_INFINITY  // total_count will be null for products, since pagination was removed
      this.isInvalid = this.totalItems === 0
      this.scrollToTop()
    }))
  }

  getCollections(collectionUniqueIDs: Array<string>, page: number, limit: number) {
    this.store.dispatch(new ShowLoading('ProductsSelector'))
    this.subscription.add(this.productsListService.getCollectionsByUniqueID(collectionUniqueIDs, page + 1, limit).subscribe(res => {
      this.outputList = (res as CollectionsResponse).collections
      this.store.dispatch(new HideLoading('ProductsSelector'))
      this.totalItems = res.total_count
      this.isInvalid = this.totalItems === 0
      this.scrollToTop()
    }))
  }

  scrollToTop() {
    if (this.productsListRef) {
      this.productsListRef.nativeElement.scroll(0, 0)
    }
  }

  loadPage(page?: PageEvent) {
    if (!this.selection) {
      return false
    }
    if (this.selection.type === this.uiCouponTargetSelection.entitled_products) {
      this.getProducts(this.selection.ids.products, this.selection.ids.variants, page ? page.pageIndex : 0, page ? page.pageSize : this.itemsPerPage)
    } else if (this.selection.type === this.uiCouponTargetSelection.entitled_collections) {
      this.getCollections(this.selection.ids.collections, page ? page.pageIndex : 0, page ? page.pageSize : this.itemsPerPage)
    }
  }

  onRemoveItem(item: Product | Collection, variant?: ProductVariant) {
    if (!this.clearable || !this.selection) {
      return
    }

    const nextSelection = _.cloneDeep(this.selection)

    if (nextSelection.type === UICouponTargetSelection.entitled_collections) {
      if (nextSelection.ids?.collections?.length > 0) {
        const collectionIndex = nextSelection.ids.collections.indexOf(item.unique_id)
        if (collectionIndex !== -1) {
          nextSelection.ids.collections.splice(collectionIndex, 1)
        }
      }
    } else if (nextSelection.type === UICouponTargetSelection.entitled_products) {
      const productToRemove = item as Product
      if (variant) {
        if (nextSelection.ids?.variants) {
          let variantIndex = nextSelection.ids.variants.indexOf(variant.unique_id)
          if (variantIndex !== -1) {
            nextSelection.ids.variants.splice(variantIndex, 1)
          } else if (productToRemove.variants?.length > 1) {
            // if variant unique_id was not in the ids.variants array this means
            // all variants for this product are selected
            // so we need to add remaining ids to the array
            const remainingVariantIds = productToRemove.variants.filter(v => v.unique_id !== variant.unique_id).map(v => v.unique_id)
            nextSelection.ids.variants = [...nextSelection.ids.variants, ...remainingVariantIds]
          }
        }
      } else if (productToRemove.variants?.length > 0 && nextSelection.ids?.variants) {
        // if we only have product & it has variants we should remove them from selection as well
        productToRemove.variants.forEach(v => {
          let variantIndex = nextSelection.ids.variants.indexOf(v.unique_id)
          if (variantIndex !== -1) {
            nextSelection.ids.variants.splice(variantIndex, 1)
          }
        })
      }

      if (productToRemove && nextSelection.ids?.products?.length > 0) {
        const productIndex = nextSelection.ids.products.indexOf(productToRemove.unique_id)
        if (productIndex !== -1) {
          nextSelection.ids.products.splice(nextSelection.ids.products.indexOf(productToRemove.unique_id), 1)
        }
      }
    }
    this.change.emit(nextSelection)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.paginator._intl.itemsPerPageLabel = 'Items per page'
    this.store.dispatch(new ResetLoading())
  }
}
