import { ContactsResponse } from './../../../subscribers/models/subscribers-page.model';
import { Injectable, ViewContainerRef } from '@angular/core'
import { Store } from '@ngrx/store'
import _ from 'lodash'
import { BehaviorSubject, Observable, Subject, of } from 'rxjs'
import { catchError, finalize, switchMap } from 'rxjs/operators'
import { ActiveAppCampaignService } from '../../../../core/services/active-app-campaign.service'
import { ApiCampaignService } from '../../../../core/services/api/api-campaign.service'
import { ApiRewardsService, RewardsResponse } from '../../../../core/services/api/api-rewards.service'
import { CampaignService } from '../../../../core/services/campaign.service'
import { Campaign, CampaignPluginName } from '../../../../shared/models/campaign/campaign'
import { CouponBoxNotificationParams, CouponBoxThemeConfig, CouponBoxThemeConfigDefault, NewsLetterNotificationParams, NewsletterThemeConfig, NewsletterThemeConfigDefault } from '../../../../shared/models/campaign/coupon-box-notification.model'
import { couponBoxCustomCssTemplate } from '../../../../shared/models/templates/coupon-box-custom-css.template'
import { couponBoxCustomJsTemplate } from '../../../../shared/models/templates/coupon-box-custom-js.template'
import { fortuneWheelCustomCssTemplate } from '../../../../shared/models/templates/fortune-wheel-custom-css.template'
import { fortuneWheelCustomJsTemplate } from '../../../../shared/models/templates/fortune-wheel-custom-js.template'
import { newsLetterCustomCssTemplate } from '../../../../shared/models/templates/news-letter-custom-css.template'
import { newsLetterCustomJsTemplate } from '../../../../shared/models/templates/news-letter-custom-js.templdate'
import { SegmentAnalyticsService } from '../../../../shared/services/segment-analytics.service'
import { HideLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import { StoreState } from '../../../../store/store.state'
import { CouponStatus, ShopCouponCodeData } from '../../../coupon-codes/models/coupon-code'
import { RewardsType } from '../../../coupon-codes/models/rewards-model'
import { PluginsComponentAvailablePlugin } from '../../campaign/campaign-plugins/campaign-plugins.component'
import { FortuneWheelParams, FortuneWheelSliceType, FortuneWheelThemeConfig, FortuneWheelThemeConfigDefault } from '../../plugins/fortune-wheel/models/fortune-wheel.model'
import { PluginSetupResult, QuickSetupStep } from '../models/quick-setup.model'
import { MarketingEmailsApiService } from '../../../../shared/modules/marketing-emails/services/marketing-emails-api.service';
import { EmailEditorApiService } from '../../../email-builder/services/email-editor-api.service';
import { MarketingEmail } from '../../../../shared/models/campaign/marketing-email';

@Injectable()
export class QuickSetupService {
  // _initialParams is used to store params$ initial value, so that we can reset params$ to initial value
  private _initialParams: FortuneWheelParams | CouponBoxNotificationParams | NewsLetterNotificationParams
  public set initialParams (val: FortuneWheelParams | CouponBoxNotificationParams | NewsLetterNotificationParams) {
    this._initialParams = val
  }
  public get initialParams (): FortuneWheelParams | CouponBoxNotificationParams | NewsLetterNotificationParams {
    return _.cloneDeep(this._initialParams)
  }

  public steps: QuickSetupStep
  public currentStep: string
  public parentContainer: ViewContainerRef
  public childContainer: ViewContainerRef

  public akcsTotal$: BehaviorSubject<ContactsResponse> = new BehaviorSubject(null)
  public componentLoaded$: Subject<any> = new Subject()
  public closeDialog$: Subject<PluginSetupResult> = new Subject()
  public nextButtonVisible$: BehaviorSubject<boolean> = new BehaviorSubject(true)
  public backButtonVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public submitButtonVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public params$: BehaviorSubject<FortuneWheelParams | CouponBoxNotificationParams | NewsLetterNotificationParams> = new BehaviorSubject(null)

  public defaultThemes = {
    [CampaignPluginName.CouponBoxNotification]: 'generic_alt_53',
    [CampaignPluginName.NewsLetterNotification]: 'generic_alt_53',
    [CampaignPluginName.FortuneWheel]: 'generic_alt_53',
  }
  public currentPlugin: CampaignPluginName
  public currentTheme: CouponBoxThemeConfigDefault | NewsletterThemeConfigDefault | FortuneWheelThemeConfigDefault
  public currentCampaign: Campaign
  public campaignPluginItem: PluginsComponentAvailablePlugin

  constructor(
    private store: Store<StoreState>,
    private apiCampaignService: ApiCampaignService,
    private campaignService: CampaignService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private apiRewardsService: ApiRewardsService,
    private activeAppCampaignService: ActiveAppCampaignService,
    private marketingEmailsApiService: MarketingEmailsApiService,
    private emailEditorApiService: EmailEditorApiService,
  ) { }

  public loadComponentByPath(path: string): void {
    let component = this._getComponentByPath(path)
    if (component) {
      this.loadComponent(component)
    } else {
      console.error('Component not found for path: ', path)
    }
  }

  public loadComponent(component: any, container: ViewContainerRef = this.parentContainer): void {
    container.clear()
    // In case it fails to work after upgrade to v19, try this suggestion from migration guide:
    // When using createComponent API and not passing content for the first ng-content, 
    // provide document.createTextNode('') as a projectableNode to prevent rendering the default fallback content.
    container.createComponent(component)
    this.componentLoaded$.next(null) // imitating navigationEnd event, to update footer buttons
  }

  public setParams(params: FortuneWheelParams | CouponBoxNotificationParams | NewsLetterNotificationParams): void {
    this.params$.next(this._convertDefaultParamsForQuickSetup(params))
  }

  private _convertDefaultParamsForQuickSetup(params: FortuneWheelParams | CouponBoxNotificationParams | NewsLetterNotificationParams): any {
    const _params = _.cloneDeep(params)
    // remove phone input
    delete _params.config.form_config?.phone
    // remove name input
    delete _params.config.form_config?.name
    // hide terms
    _.set(_params, 'config.elements_styling.idle_state.terms.visible', false)
    // show decline button
    _.set(_params, 'config.texts.prompt.decline_button.show', true) // FIXME: ?
    return _params
  }

  private _getComponentByPath(path: string): any {
    let component
    // if path is a root path of plugin
    this.steps.children.forEach(step => {
      if (step.path === path) {
        component = step.component
      }
    })
    // go through all children of current plugin
    let popupSteps = this.steps.children.find(child => child.path === this.currentPlugin)?.children
    popupSteps?.forEach(step => {
      if (step.path === path) {
        component = step.component
      }
    })
    return component
  }

  public loadChildStep(stepPath: string): void {
    if (this.currentStep !== stepPath) {
      this.currentStep = stepPath
    }
    // Find component for current step
    const parent = this.steps.children.find(step => step.path === this.currentPlugin)
    const component = parent?.children?.find(step => step.path === stepPath)?.component
    if (component) {
      // Load component
      this.loadComponent(component, this.childContainer)
    } else {
      console.error('Component not found for child step: ', stepPath)
    }
  }

  setBCBDefaultPromptCopy(params: CouponBoxNotificationParams, reward): void {
    params.config.texts.prompt.header = 'Subscribe to Get {{label}}!'
    params.config.texts.prompt.body.line_1 = 'Join our list to be the first to know about updates and offers.'
    const data: ShopCouponCodeData = reward.data
    // logic as rewardConditionAdaptor discount_value
    if (data?.value_type === 'percentage' && data?.value > 0) {
      params.config.texts.prompt.header = `Get ${data?.value}% OFF on your first order!`
    }
    if (data?.value_type === 'fixed_amount' && data?.value > 0) {
      params.config.texts.prompt.header = `Get $${data?.value} OFF on your first order!`
    }
    if (data?.target_type === 'shipping_line') {
      params.config.texts.prompt.header = 'Get Free Shipping on your first order!'
    }
    if (data?.target_type === 'line_item' && data?.prerequisite_quantity_range_min && data?.customer_get_quantity && data?.customer_get_percentage === 1) {
      params.config.texts.prompt.header = `Buy ${data?.prerequisite_quantity_range_min} Get ${data?.customer_get_quantity} Free on your first order!`
    }
    if (data?.target_type === 'line_item' && data?.prerequisite_quantity_range_min && data?.customer_get_quantity && data?.customer_get_percentage !== 1) {
      params.config.texts.prompt.header = `Buy ${data?.prerequisite_quantity_range_min} Get ${data?.customer_get_quantity} at ${data?.customer_get_percentage * 100}% OFF on your first order!`
    }
    if (data?.target_type === 'line_item' && data?.prerequisite_subtotal_min && data?.customer_get_quantity && data?.customer_get_percentage === 1) {
      params.config.texts.prompt.header = `Spend $${data?.prerequisite_subtotal_min} Get ${data?.customer_get_quantity} Free on your first order!`
    }
    if (data?.target_type === 'line_item' && data?.prerequisite_subtotal_min && data?.customer_get_quantity && data?.customer_get_percentage !== 1) {
      params.config.texts.prompt.header = `Spend $${data?.prerequisite_subtotal_min} Get ${data?.customer_get_quantity} at ${data?.customer_get_percentage * 100}% OFF on your first order!`
    }
  }

  // public loadNextStep(): void {
  //   // Find current step index
  //   const currentStepIndex = this.steps[this.currentPlugin].children.findIndex(step => step.path === this.currentStep)
  //   // Check if next step exists
  //   if (!!this.steps[this.currentPlugin].children[currentStepIndex + 1]) {
  //     // Set next step name as current step
  //     this.currentStep = this.steps[this.currentPlugin].children[currentStepIndex + 1].path
  //     // Load next step
  //     this.loadComponentByPath(this.currentStep)
  //   }
  // }

  // public loadPrevStep(): void {
  //   // Find current step index
  //   const currentStepIndex = this.steps[this.currentPlugin].children.findIndex(step => step.path === this.currentStep)
  //   // Check if prev step exists
  //   if (!!this.steps[this.currentPlugin].children[currentStepIndex - 1]) {
  //     // Set prev step name as current step
  //     this.currentStep = this.steps[this.currentPlugin].children[currentStepIndex - 1].path
  //     // Load prev step
  //     this.loadComponentByPath(this.currentStep)
  //   }
  // }

  // public updateFooterButtons(pluginType: CampaignPluginName): void {
  //   const currentStepIndex = this.steps[this.currentPlugin].children.findIndex(step => step.path === this.currentStep)
  //   if (currentStepIndex === 0) {
  //     this.backButtonVisible$.next(false)
  //     this.nextButtonVisible$.next(true)
  //     this.submitButtonVisible$.next(false)
  //   } else if (currentStepIndex === this.steps[this.currentPlugin].children.length - 1) {
  //     this.backButtonVisible$.next(true)
  //     this.nextButtonVisible$.next(false)
  //     this.submitButtonVisible$.next(true)
  //   } else {
  //     this.backButtonVisible$.next(true)
  //     this.nextButtonVisible$.next(true)
  //     this.submitButtonVisible$.next(false)
  //   }
  // }

  fetchTheme(): Observable<FortuneWheelThemeConfig | NewsletterThemeConfig | CouponBoxThemeConfig> {
    this.store.dispatch(new ShowLoading('QuickSetupThemes'))
    const theme = this.defaultThemes[this.currentPlugin]
    switch (this.currentPlugin) {
      case CampaignPluginName.CouponBoxNotification:
        return this.apiCampaignService.getCBThemeById(theme).pipe(
          finalize(() => this.store.dispatch(new HideLoading('QuickSetupThemes')))
        ) as Observable<CouponBoxThemeConfig>
      case CampaignPluginName.NewsLetterNotification:
        return this.apiCampaignService.getNewsletterThemeById(theme).pipe(
          finalize(() => this.store.dispatch(new HideLoading('QuickSetupThemes')))
        ) as Observable<NewsletterThemeConfig>
      case CampaignPluginName.FortuneWheel:
        return this.apiCampaignService.getFWThemeById(theme).pipe(
          finalize(() => this.store.dispatch(new HideLoading('QuickSetupThemes')))
        ) as Observable<FortuneWheelThemeConfig>
    }
  }

  fetchCoupons(): Observable<RewardsResponse> {
    this.store.dispatch(new ShowLoading('QuickSetupCoupons'))
    return this.apiRewardsService.getRewards({
      page: 1,
      limit: 25,
      status: CouponStatus.active,
      reward_type: RewardsType.coupon_code
    }).pipe(
      finalize(() => this.store.dispatch(new HideLoading('QuickSetupCoupons')))
    )
  }

  // Save default params on popup init, so that we can use them later as a fallback
  public saveDefaultParams(): void {
    this.initialParams = _.cloneDeep(this.params$.value)
  }

  public quickSetupEvent(str: string, skipPopupType?: boolean) {
    const popupTypeMap = {
      [CampaignPluginName.CouponBoxNotification]: 'Popup w Reward',
      [CampaignPluginName.NewsLetterNotification]: 'Popup wo Reward',
      [CampaignPluginName.FortuneWheel]: 'Spin Wheel',
    }
    this.segmentAnalyticsService.track(
      skipPopupType ? str : `${str} - ${popupTypeMap[this.currentPlugin]}`,
      {
        state: {
          onboarding: false,
          step: 'plugins',
          group: 'campaign',
        },
        metadata: {
          feature: this.campaignPluginItem?.value,
        },
      }
    )
  }

  updateTheme(pluginType: CampaignPluginName) {
    const plugin = _.cloneDeep(this.currentCampaign[pluginType])
    switch (pluginType) {
      case CampaignPluginName.CouponBoxNotification:
        plugin.config.theme[this.currentTheme.identifier] = (this.currentTheme as any).config
        plugin.config.active_theme = this.currentTheme.identifier
        _.set(plugin, 'config.advanced.custom_css', this.currentTheme.css || couponBoxCustomCssTemplate)
        _.set(plugin, 'config.advanced.custom_js', couponBoxCustomJsTemplate)
        break
      case CampaignPluginName.NewsLetterNotification:
        plugin.config.theme[this.currentTheme.identifier] = (this.currentTheme as any).config
        plugin.config.active_theme = this.currentTheme.identifier
        _.set(plugin, 'config.advanced.custom_css', this.currentTheme.css || newsLetterCustomCssTemplate)
        _.set(plugin, 'config.advanced.custom_js', newsLetterCustomJsTemplate)
        break
      case CampaignPluginName.FortuneWheel:
        plugin.config.theme = {
          identifier: this.currentTheme.identifier,
          name: this.currentTheme.name,
          images: this.currentTheme['config']['images'],
          css: this.currentTheme['css'],
          variables: this.currentTheme['config']['variables'],
        } as any
        _.set(plugin, 'config.advanced.custom_css', fortuneWheelCustomCssTemplate)
        _.set(plugin, 'config.advanced.custom_js', fortuneWheelCustomJsTemplate)
        break
    }
    // workaround since we rename url to src and send it to vue imageExists(imageName) function
    _.set(plugin, 'config.theme.images.pattern.url', _.get(plugin, 'config.theme.images.pattern.src'))
    this.setParams(plugin as any)
  }

  public submit(email): Observable<any> {
    const item = this.campaignPluginItem
    item.selected = true
    item.locked = true
    const data = {
      active: true,
      config: { ...this.params$.value.config },
      capture_configs_attributes: undefined
    }
    if (this.currentPlugin === CampaignPluginName.FortuneWheel) {
      const slices = (this.params$.value as FortuneWheelParams).slices
      slices.forEach(slice => {
        // percentage is not being stored on the backend
        delete slice.percentage
        // code_status is not being stored on the backend
        delete slice['code_status']
        // reward.title and reward.data are not being stored on the backend
        delete slice.reward?.title
        delete slice.reward?.data
        // if reward.id is null, then it's a no prize slice
        if (!slice.reward.id) {
          slice.type = FortuneWheelSliceType.NO_PRIZE
          slice.label = 'Sorry, next time!'
        }
        // if slice.type is NO_PRIZE, then reward should be an empty object
        if (slice.type === FortuneWheelSliceType.NO_PRIZE) {
          (slice.reward as any) = {}
        }
      })
      data['slices'] = slices
    }
    if (this.currentPlugin === CampaignPluginName.CouponBoxNotification) {
      data['reward'] = (this.params$.value as CouponBoxNotificationParams).reward
    }
    data['sms_keyword'] = null
    data['sync_with_shopify'] = true
    data['unlayer_email_template'] = {html: "", json: {}}
    this.store.dispatch(new ShowLoading('UpdatePluginConfig'))

    // return this.apiCampaignService.putPlugin(this.currentCampaign.id, this.currentPlugin, data).pipe(finalize(() => {
    //   item.locked = false
    //   this.store.dispatch(new HideLoading('UpdatePluginConfig'))
    // }))

    // FIXME: Temporary workaround because of a BE limitation
    // we need to update the plugin active status first, then update the config
    // activate request should be removed once the BE is fixed
    // Just remove what's below and uncomment the code above
    const activeStateData = {
      active: true,
      config: { ...this.currentCampaign[this.currentPlugin].config },
      capture_configs_attributes: undefined
    }
    return this.apiCampaignService.putPlugin(
      this.currentCampaign.id,
      this.currentPlugin,
      activeStateData
    ).pipe(
      switchMap(() => this.apiCampaignService.putPlugin(this.currentCampaign.id, this.currentPlugin, data)),
      switchMap(() => this.saveMarketingEmail(email, this.currentPlugin, this.currentCampaign.id)),
      switchMap(() => this.campaignService.setCampaignActive(this.currentCampaign.id)),
      catchError((err) => {
        item.selected = false
        throw err
      }),
      finalize(() => {
        item.locked = false
        this.activeAppCampaignService.refreshTotals()
        this.store.dispatch(new HideLoading('UpdatePluginConfig'))
      })
    )
  }

  private saveMarketingEmail(email, pluginType, campaignId): Observable<MarketingEmail> {
    if (email && email.id) {
        const payload = this.preparePayload(email)
        return this.marketingEmailsApiService.update(
          campaignId, pluginType, email.id, payload
        )
    } else {
      return of(null)
    }
  }

  private preparePayload(data: any) {
    const values = _.cloneDeep(data)
    // Quick setup viewed as initial setup, so we replace the template data like it is new created email
    values.template_json = this.emailEditorApiService.replaceTemplateData({ json: values.template_json })?.json

    const payload = {
      preview_text: values.preview_text,
      from_name: values.from_name,
      reply_to: values.reply_to,
      subject: values.subject,
      address_id: values.address_id,
      ...(values.reward?.id) && {
        reward: {
          id: values.reward?.id,
          reward_type: values.reward.reward_type
        }
      },
      scheduling_value: values.scheduling_value,
      active: values.active,
      type: values.type,
      template_id: values.template_id,
      template_json: values.template_json,
      template_html: values.template_html,
      scheduling_date: values.scheduling_date,
    }

    /**
     * For marketing email [PUT] save method, we always have
     * reward: { reward_type: coupon_code, id: null }
     * so
     * will need to redesign this part of the code
     * for now we just filter out reward from payload here
     **/

    return payload
  }
}

