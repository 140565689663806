import { Component } from '@angular/core'
import { FooterbarNavigationService } from './footerbar-navigation.service'

@Component({
    selector: 'pf-footerbar-navigation',
    templateUrl: './footerbar-navigation.component.html',
    styleUrls: ['./footerbar-navigation.component.scss'],
    standalone: false
})
export class FooterbarNavigationComponent {
  primaryBtnText$ = this.footerbarNavigationService.primaryBtnText$;
  primaryBtnDisabled$ = this.footerbarNavigationService.primaryBtnDisabled$;
  secondaryBtnText$ = this.footerbarNavigationService.secondaryBtnText$;
  secondaryBtnDisabled$ = this.footerbarNavigationService.secondaryBtnDisabled$;
  tertiaryBtnText$ = this.footerbarNavigationService.tertiaryBtnText$;
  tertiaryBtnDisabled$ = this.footerbarNavigationService.tertiaryBtnDisabled$;
  exitBtnText$ = this.footerbarNavigationService.exitBtnText$;
  exitBtnDisabled$ = this.footerbarNavigationService.exitBtnDisabled$;
  buttonSize = window.matchMedia('(max-width: 600px)')?.matches ? 'sm' : 'md'

  constructor(private footerbarNavigationService: FooterbarNavigationService) { }

  primaryBtnAction(): void {
    this.footerbarNavigationService.primaryBtnAction();
  }

  secondaryBtnAction(): void {
    this.footerbarNavigationService.secondaryBtnAction();
  }

  tertiaryBtnAction(): void {
    this.footerbarNavigationService.tertiaryBtnAction();
  }

  exitBtnAction(): void {
    this.footerbarNavigationService.exitBtnAction();
  }
}
