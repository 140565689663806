import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import _ from 'lodash'
import { Subscription } from 'rxjs'
import { environment } from '../../../../../../environments/environment';
import { GettingStartedService } from '../../../../../core/services/getting-started.service';
import { UserService } from '../../../../../core/services/user.service';
import { JFHelperType, JFQuestionType, JFWidgetType } from '../../models/jotform.model';
import { JotFormService } from '../../services/jotform.service';
import { SegmentAnalyticsService } from '../../../../services/segment-analytics.service'
import { GettingStartedGroupName } from '../../../../models/getting-started/getting-started-group-name.model'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

declare const $crisp: any

@Component({
    selector: 'pf-access-form-modal',
    templateUrl: './access-form-modal.component.html',
    styleUrls: ['./access-form-modal.component.scss'],
    standalone: false
})
export class AccessFormModalComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription()
  jotFormApi = null
  activeForm: any = {}
  questionType = JFQuestionType
  widgetType = JFWidgetType
  loading: boolean = false
  submissionError: string = null
  formCompleted: boolean = false
  allowRefill: boolean = !environment.production
  isShopifySmsAccessForm: boolean = this.data?.preset?.group === GettingStartedGroupName.ShopifySmsAccessForm

  constructor(
    private jotFormService: JotFormService,
    private gettingStartedService: GettingStartedService,
    private userService: UserService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    public dialogRef: MatDialogRef<AccessFormModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { formId?: string, preset: any },
  ) {
    this.dialogRef.addPanelClass(['_no-padding'])
    this.activeForm = _.get(this.data, 'preset', { id: this.data.formId })
    if (this.activeForm?.group) {
      this.gettingStartedService.setStatusGroupVisited(this.activeForm.group)
      this.formCompleted = _.get(this.gettingStartedService.status, `${this.activeForm.group}.completed`)
    }
  }

  ngOnInit() {
    if (this.formCompleted) {
      this.dialogRef.updateSize('500px')
    } else {
      this.initForm()
    }
    if (this.isShopifySmsAccessForm) {
      this.segmentAnalyticsService.track('Viewed Modal - Form to Request Send SMS to Shopify Subs')
    }
  }

  initForm() {
    this.loading = true
    if (window['JF']) {
      this.initjotFormApi()

      this.jotFormApi.getFormQuestions(this.activeForm.id, (res) => {
        if (_.isEmpty(res)) {
          this.usePresetForm()
        } else {
          const values = Object.values(res)
          const header = values.find((v: any) => v.type === JFHelperType.ControlHead)
          const description = values.find((v: any) => v.type === JFHelperType.ControlText)
          if (header) {
            this.activeForm.header = {
              title: _.get(header, 'text'),
              subtitle: _.get(header, 'subHeader')
            }
          }
          if (description) {
            this.activeForm.description = _.get(description, 'text')
          }
          this.activeForm.questions = _.values(res).filter((q) => {
            if (q['name'] === 'prefilled') {
              this.activeForm.prefilled.qid = q.qid
            }
            return _.values(JFQuestionType).includes(q.type)
          })
          .sort((a, b) => a['order'] - b['order'])
          .map((q: any) => {
            const question: any = {
              qid: q.qid,
              name: q.name,
              type: q.type,
              text: q.text,
              description: q.description,
              required: q.required === 'Yes',
              hint: q.hint,
              error: false,
              hidden: q['hidden'] === 'Yes'
            }
            if (question.name && typeof question.name === 'string') {
              // jotform doesn't allow to upload files from api
              // so this is a quick workaround for sending urls
              // to a text field with specific name pattern instead
              if(_.includes(question.name, 'uploadFiles')) {
                question.type = JFQuestionType.ControlFileUpload
              }
              // custom hidden field that we'll popupate with user data
              if (question.name === 'prefilledUserData') {
                question.type = JFQuestionType.ControlPrefilledUserData
              }
            }
            switch (question.type) {
              case JFQuestionType.ControlFullname:
                question.placeholder = {
                  first: _.get(q, 'sublabels.first', 'First Name'),
                  last: _.get(q, 'sublabels.last', 'Last Name')
                }
                question.entryLimit = 20
                question.control = this.jotFormService.createFullNameControl(question)
                break
              case JFQuestionType.ControlEmail:
                question.hint = _.get(q, 'confirmationHint')
                question.entryLimit = q.size || 100
                question.control = this.jotFormService.createEmailControl(question)
                break
              case JFQuestionType.ControlPhone:
                question.hint = _.get(q, 'confirmationHint')
                question.control = this.jotFormService.createPhoneControl()
                break
              case JFQuestionType.ControlTextbox:
                question.validation = q.validation
                question.entryLimit = q.size || 100
                question.control = this.jotFormService.createTextControl(question)
                break
              case JFQuestionType.ControlTextarea:
                question.entryLimitMin = q.entryLimitMin ? (+q.entryLimitMin.split('-')[1] || 0) : 0
                question.entryLimit = q.entryLimit ? (+q.entryLimit.split('-')[1] || 0) : 0
                question.control = this.jotFormService.createTextareaControl(question)
                break
              case JFQuestionType.ControlNumber:
                question.maxValue = +q.maxValue || 0
                question.minValue = +q.minValue || 0
                question.control = this.jotFormService.createNumberControl(question)
                break
              case JFQuestionType.ControlCheckbox:
                question.otherText = q.allowOther === 'Yes' ? q.otherText : null
                question.spreadCols = +q.spreadCols || 1
                question.options = q.options.split('|').map(option => ({ value: option, checked: false }))
                if (q.allowOther === 'Yes') {
                  question.options.push({ type: 'other', placeholder: q.otherText || 'Other...', value: '', checked: false})
                }
                break
              case JFQuestionType.ControlWidget:
                question.widgetType = q.widgetType
                if (q.widgetType === JFWidgetType.Field) {
                  question.value = q.acceptedText || 'Accepted'
                  question.label = q.termsText
                  question.checked = false
                }
                break
              case JFQuestionType.ControlFileUpload:
                question.images = []
                question.description = _.get(q, 'subLabel')
            }
            return question
          })
          this.loading = false
        }
      }, (err) => {
        this.usePresetForm()
        console.error('JotForm', 'failed to fetch questions:', err)
      })
    } else {
      this.usePresetForm()
      console.error('JotForm', 'api is undefined')
    }
  }

  initjotFormApi() {
    if (window['JF']) {
      this.jotFormApi = window['JF']
      this.jotFormApi.initialize({ apiKey: environment.jotFormApiKey })
    }
  }

  usePresetForm() {
    this.activeForm.questions && this.activeForm.questions.forEach((question, i) => {
      switch (question.type) {
        case JFQuestionType.ControlFullname:
          this.activeForm.questions[i].control = this.jotFormService.createFullNameControl(question)
          break
        case JFQuestionType.ControlEmail:
          this.activeForm.questions[i].control = this.jotFormService.createEmailControl(question)
          break
        case JFQuestionType.ControlPhone:
          this.activeForm.questions[i].control = this.jotFormService.createPhoneControl()
          break
        case JFQuestionType.ControlTextbox:
          this.activeForm.questions[i].control = this.jotFormService.createTextControl(question)
          break
        case JFQuestionType.ControlTextarea:
          this.activeForm.questions[i].control = this.jotFormService.createTextareaControl(question)
          break
        case JFQuestionType.ControlNumber:
          this.activeForm.questions[i].control = this.jotFormService.createNumberControl(question)
          break
      }
    })
    this.loading = false
  }


  checkOption(event, option, question) {
    event.stopPropagation()
    if (option.type === 'other') {
      // if user clicked inside the input always set to true
      if (event.pointerType) {
        if (_.get(event, 'target.nodeName') === 'INPUT') {
          option.checked = true
        } else {
          option.checked = !option.checked
        }
      }
    } else {
      option.checked = !option.checked
    }
    question.error = question.required && question.options.find(o => o.checked) === undefined
  }

  checkTerms(question) {
    question.checked = !question.checked
    question.error = question.required && !question.checked
  }

  setImages(question, images) {
    question.images = images
    question.error = question.required && images.length === 0
  }

  submitForm() {
    if (this.isShopifySmsAccessForm) {
      this.segmentAnalyticsService.track('Clicked Submit - Form to Request Send SMS to Shopify Subs')
    }
    this.loading = true
    const payload = {}
    let hasErrors = false
    let firstInvalidControlId = null
    this.activeForm.questions && this.activeForm.questions.forEach((question, i) => {
      switch (question.type) {
        case JFQuestionType.ControlFullname:
          question.control.markAllAsTouched()
          question.error = question.control.status !== 'VALID'
          hasErrors ||= question.error
          if (!firstInvalidControlId && question.error) {
            firstInvalidControlId = question.qid
          }
          payload[question.qid] = {
            first: question.control.get('first').value,
            last: question.control.get('last').value
          }
          break
        case JFQuestionType.ControlEmail:
        case JFQuestionType.ControlPhone:
        case JFQuestionType.ControlTextbox:
        case JFQuestionType.ControlTextarea:
        case JFQuestionType.ControlNumber:
          question.control.markAsTouched()
          question.error = question.control.status !== 'VALID'
          hasErrors ||= question.error
          if (!firstInvalidControlId && question.error) {
            firstInvalidControlId = question.qid
          }
          payload[question.qid] = question.control.value
          break
        case JFQuestionType.ControlCheckbox:
          if (question.required) {
            question.error = question.options.find(o => o.checked) === undefined
            hasErrors ||= question.error
            if (!firstInvalidControlId && question.error) {
              firstInvalidControlId = question.qid
            }
          }
          payload[question.qid] = question.options.reduce((res, option) => {
            if (option.checked) {
              res.push(option.value || 'Other')
            }
            return res
          }, [])
          break
        case JFQuestionType.ControlWidget:
          if (question.widgetType === JFWidgetType.Field) {
            question.error = question.required && !question.checked
            hasErrors ||= question.error
            if (!firstInvalidControlId && question.error) {
              firstInvalidControlId = question.qid
            }
            payload[question.qid] = question.checked ? question.value : null
          }
          break
        case JFQuestionType.ControlFileUpload:
          question.error = question.required && question.images.length === 0
          hasErrors ||= question.error
          if (!firstInvalidControlId && question.error) {
            firstInvalidControlId = question.qid
          }
          payload[question.qid] = question.images.reduce((res, image) => {
            res += `${image.path}\n`
            return res
          }, '')
          break
        case JFQuestionType.ControlPrefilledUserData:
          if (this.userService.userInfo) {
            const userInfo = this.userService.userInfo
            payload[question.qid] = `User ID: ${userInfo.id}\n`
            payload[question.qid] += `User Email: ${userInfo.email}\n`
            if (_.get(userInfo, 'subscription.plan.payment_gateway_plan_identifier')) {
              payload[question.qid] += `User Plan: ${_.get(userInfo, 'subscription.plan.payment_gateway_plan_identifier')}\n`
            }
            if (_.get(userInfo, 'shop.profile.plan_name')) {
              payload[question.qid] += `Shopify Plan: ${_.get(userInfo, 'shop.profile.plan_name')}\n`
            }
            if (_.get(userInfo, 'shop.profile.name')) {
              payload[question.qid] += `Shop Name: ${_.get(userInfo, 'shop.profile.name')}\n`
            }
            if (_.get(userInfo, 'shop.store_url')) {
              payload[question.qid] += `Shop URL: ${_.get(userInfo, 'shop.store_url')}\n`
            }
          }
          break
        }
    })
    if (hasErrors) {
      this.loading = false
      document.getElementById(firstInvalidControlId) && document.getElementById(firstInvalidControlId).scrollIntoView({ behavior: 'smooth', block: 'center' })
      return
    }

    if (this.jotFormApi) {
      this.jotFormApi.createFormSubmissions(this.activeForm.id, JSON.stringify([payload]), (res) => {
        if (_.has(res, '[0].submissionID')) {
          this.sendFormDataToCrisp(_.get(res, '[0].submissionID'))
        }
        this.notifyCrisp()
        if (this.activeForm?.group) {
          this.gettingStartedService.completeStatusGroup(this.activeForm.group)
        }
        if (this.activeForm.closeOnSubmit) {
          this.dialogRef.close(true)
        } else {
          this.formCompleted = true
          this.loading = false
        }
      }, (err) => {
        this.submissionError = 'Something went wrong, please try again later'
        this.loading = false
        console.error('JotForm', 'submit error:', err)
      })
    } else {
      this.initjotFormApi()
      this.submissionError = 'Something went wrong, please try again later'
      this.loading = false
    }
  }

  sendFormDataToCrisp(submissionId) {
    if (typeof $crisp !== 'undefined' && submissionId) {
      $crisp.push(['set', 'session:data', [`request_shopify_sms_form`, `https://www.jotform.com/edit/${submissionId}`]])
    }
  }

  notifyCrisp() {
    if (typeof $crisp !== 'undefined' && this.activeForm.crisp) {
      $crisp.push(['do', 'chat:open'])
      $crisp.push(['do', 'message:send', ['text', this.activeForm.crisp]])
    }
  }

  retakeForm() {
    this.initForm()
    this.dialogRef.updateSize('700px')
    this.formCompleted = false
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

}
