import { GiftCardStatus } from './../../models/gift-card';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { filter } from 'rxjs/operators'
import * as _ from 'lodash'

import { getUserInfo } from '../../../../store/user/user.selectors'
import { StoreState } from '../../../../store/store.state'
import { UserShopType } from '../../../../shared/models/user/user-shop-type.model'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { NewCouponCodesService } from '../../../coupon-codes/services/new-coupon-code.service'
import { GiftCardsService } from '../../services/gift-cards.service'
import { HideLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import { HttpErrorResponse } from '@angular/common/http'
import { GiftCard, GiftCardSummary } from '../../models/gift-card'
import { UserInfo } from '../../../../store/user/user.state'
import { Subscription } from 'rxjs'
import { ApiRewardsService } from '../../../../core/services/api/api-rewards.service'
import { RewardsType } from '../../../coupon-codes/models/rewards-model'
import { RewardsService } from '../../../../core/services/rewards-service.service'
import { RedirectService } from '../../../../core/services/redirect.service'
import { RouteHeaderUrl } from '../../../../shared/components/one-header/header-navigation.model';
import { CustomSnackbarService } from '../../../../shared/modules/custom-snackbar/custom-snackbar.service';

interface Unit {
  value: UnitValue
  name: string
}
type UnitValue = 'days' | 'years'

@Component({
    selector: 'pf-new-gift-card',
    templateUrl: './new-gift-card.component.html',
    styleUrls: ['./new-gift-card.component.scss'],
    standalone: false
})
export class NewGiftCardComponent implements OnInit, OnDestroy {
  @Input() isOverlay = false
  @Output() closeOverlay = new EventEmitter<GiftCard>()
  @ViewChild('timeInput') timeInput: ElementRef;

  userInfo: UserInfo
  currency: string
  shopType: string
  isEditMode = false
  userShopType = UserShopType
  formGroup: UntypedFormGroup
  randomCodeString: string
  summary: GiftCardSummary
  prefixInputFocused = false
  codeSpecialError = false
  subscription$ = new Subscription()
  createdGiftCard: GiftCard
  formattedMaxValue: string
  readonly maxGiftCardChars = 20
  unitOptions: Unit[] = [
    { value: 'days', name: 'day (s)' },
    { value: 'years', name: 'year (s)' }
  ]
  selectedTime = 5
  selectedUnit: UnitValue = 'years'
  editValue: number
  editOption: UnitValue

  constructor(
    private store: Store<StoreState>,
    private fb: UntypedFormBuilder,
    private giftCardService: GiftCardsService,
    private redirectService: RedirectService,
    private router: Router,
    private route: ActivatedRoute,
    private rewardsService: RewardsService,
    private apiRewardsService: ApiRewardsService,
    private snackbarService: CustomSnackbarService,
  ) { }

  ngOnInit() {
    this.subscription$.add(this.store.pipe(
      select(getUserInfo),
      filter(next => !!next),
    ).subscribe((userInfo) => {
      this.userInfo = userInfo
      this.currency = _.get(userInfo, 'shop.profile.currency')
      this.shopType = _.get(userInfo, 'shop.type')
    }))

    this.subscription$.add(this.route.data
      .subscribe((resolved: { data }) => {
        if (resolved && resolved.data) {
          this.isEditMode = true
          this.initForm(resolved.data)
        } else {
          this.initForm()
        }
      }))

    this.subscription$.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.updateSummary(this.randomCodeString)
      })
    )

  }

  ngOnDestroy() {
    this.subscription$.unsubscribe()
  }

  private _updateRandomString(val) {
    this.randomCodeString = NewCouponCodesService.randomString(parseInt(val, 10))
    this.updateSummary(this.randomCodeString)
  }

  private initForm(giftCard = null) {
    this.formGroup = this.fb.group({
      id: _.get(giftCard, 'id', null),
      title: [_.get(giftCard, 'title', ''), Validators.required],
      prefix_code: [ _.get(giftCard, 'prefix_code', '')],
      code_length: [_.get(giftCard, 'code_length', 8), [Validators.min(6), Validators.max(16)]],
      expires_after_enabled: [{
        value:  _.get(giftCard, 'expires_after_enabled', false),
        disabled: this.isEditMode
      }],
      expires_after_years: [{
        value: _.get(giftCard, 'expires_after_years', this.selectedTime),
        disabled: this.isEditMode,
      }],
      expires_after_days: [{
        value: _.get(giftCard, 'expires_after_days', 0),
        disabled: this.isEditMode,
      }],
      value: [{
        value: _.get(giftCard, 'value', 0),
        disabled: this.isEditMode,
      }, [Validators.min(1), Validators.required]],
    }, { validators: [this.prefixValidator] })
    this.checkState()
    this._updateRandomString(_.get(this.formGroup.get('code_length'), 'value', 8))
  }

  prefixValidator(group: UntypedFormGroup) {
    const prefixCtrl = group.controls['prefix_code']
    const codeLengthCtrl = group.controls['code_length']
    const totalCodeLength = prefixCtrl.value.length + codeLengthCtrl.value
    if (totalCodeLength < 8 || totalCodeLength > 20) {
      return { codeLengthError: true }
    } else {
      return null
    }
  }

  updateSummary(randomString) {
    const raw = this.formGroup.getRawValue()
    this.summary = this.giftCardService.updateSummary(raw, randomString, this.currency)
  }

  checkState() {
    const enabled = this.formGroup.get('expires_after_enabled')
    const days = this.formGroup.get('expires_after_days')?.value
    const years = this.formGroup.get('expires_after_years')?.value
    if (enabled) {
      this.editValue = days || years || this.selectedTime
      const key = days ? 'days' : years ? 'years' : this.selectedUnit
      this.editOption = key
    }
  }

  cancel() {
    if (!this.isOverlay) {
      this.navigateAfterSave()
    } else {
      this.closeOverlay.emit(null)
    }
  }

  submit() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched()
      return
    } else {
      const formValue = this.formGroup.getRawValue()

      const isInvalid = !/^[A-Za-z0-9]+$/.test(formValue.prefix_code)
      if (formValue.prefix_code && isInvalid) {
        this.codeSpecialError = true
        this.snackbarService.showError({ title: 'Remove special character(s)', text: 'Code prefix field cannot contain special characters', duration: 5000 })
        return
      }

      this.store.dispatch(new ShowLoading('new-gift-card-code'))
      this.submitGiftCardRequest(formValue)
    }
  }

  navigateAfterSave() {
    if (!this.isOverlay) {
      this.redirectService.confirmRoute()
      const redirectUrl = this.redirectService.check()
      if (redirectUrl) {
        this.router.navigate([redirectUrl])
      } else {
        this.router.navigate([`/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.gift_cards}`])
      }
    } else {
      this.closeOverlay.emit(this.createdGiftCard)
    }
  }

  submitGiftCardRequest(value) {
    if (this.isEditMode) {
      this.subscription$.add(this.apiRewardsService.putRewards(value, RewardsType.gift_card)
        .subscribe(res => {
            this.navigateAfterSave()
            this.updateGiftCardList()
            this.store.dispatch(new HideLoading('new-gift-card-code'))

          },
          (res: HttpErrorResponse) => {
            window.scroll(0, 0)
            this.store.dispatch(new HideLoading('new-gift-card-code'))
          })
      )
    } else {
      this.subscription$.add(this.apiRewardsService.postRewards(value, RewardsType.gift_card)
        .subscribe(res => {
            this.createdGiftCard = res
            this.navigateAfterSave()
            this.updateGiftCardList()
            this.store.dispatch(new HideLoading('new-gift-card-code'))
          },
          (res: HttpErrorResponse) => {
            this.store.dispatch(new HideLoading('new-gift-card-code'))
            res.error.errors.forEach(err => {
              if (err.field === 'value') {
                const maxValue = err.code.replace(/[^0-9]/g, '')
                this.formattedMaxValue = parseInt(maxValue, 10).toLocaleString('en-US', {minimumFractionDigits: 2})
                this.formGroup.get('value').setErrors({
                  maxAmount: true,
                })
              }
            })
          }),
      )
    }
  }

  onTimeFocus(unit: UnitValue) {
    this.formGroup.get(`expires_after_${unit}`).markAsTouched()
  }

  onNumberInputClick(event) {
    // when clicking on the number spinners in FF browser focus is not triggered, so need to do it here
    const isFirefoxBrowser = window.navigator.userAgent.toLowerCase().includes('firefox')
    if (isFirefoxBrowser) {
      event.target.focus()
    }
  }

  onUnitChange(unit) {
    const time = this.timeInput.nativeElement.value
    this.onTimeChange(unit, time)
  }

  onTimeChange(unit: UnitValue, value: any) {
    const input = parseInt(value, 10)
    if (!input) {
      this.timeInput.nativeElement.value = 0
      this.setExpiresError({ min: true }, null)
      return
    }
    if (unit === 'days') {
      if (input < 1) {
        this.setExpiresError({ min: true }, null)
      } else {
        this.patchExpiresValue(input, 0)
      }
    }
    if (unit === 'years') {
      if (input < 1) {
        this.setExpiresError(null, { min: true })
      } else {
        this.patchExpiresValue(0, input)
      }
    }
  }

  setExpiresError(days: object | null, years: object | null) {
    this.formGroup.get('expires_after_days').setErrors(days)
    this.formGroup.get('expires_after_years').setErrors(years)
  }

  patchExpiresValue(days: number, years: number) {
    this.formGroup.get('expires_after_days').patchValue(days)
    this.formGroup.get('expires_after_years').patchValue(years)
  }

  updateGiftCardList() {
    this.rewardsService.getGiftCardCodes({
      page: 1,
      limit: 25,
      status: GiftCardStatus.active,
    })
  }

  onCodeLengthChange(event: Event) {
    const value = (event.target as HTMLInputElement).value
    const parsedValue = parseInt(value, 10)
    if (parsedValue !== parsedValue || parsedValue < 6) {
      this._updateRandomString(6)
      this.formGroup.get('code_length').patchValue(6)
    } else if (parsedValue > 16) {
      this._updateRandomString(16)
      this.formGroup.get('code_length').patchValue(16)
    } else {
      this._updateRandomString(value)
    }
  }

  onPrefixCodeFocus() {
    this.prefixInputFocused = true
  }

  onPrefixCodeFocusOut() {
    this.prefixInputFocused = false
    const prefix_code = this.formGroup.get('prefix_code').value
    if (prefix_code) {
      this.codeSpecialError = !/^[A-Za-z0-9]+$/.test(prefix_code)
    }
  }

  validateInput(event) {
    const isInvalid = !/^[A-Za-z0-9]+$/.test(event.key)
    if (isInvalid) {
      return false
    } else {
      return true
    }
  }
}
