import { Component, OnInit, Inject } from '@angular/core'
import { ProductsSelectorInterface } from './models/Product'
import { UICouponTargetSelection } from '../../../pages/coupon-codes/models/coupon-code'
import { ProductsListService } from './services/products-list.service'
import * as _ from 'lodash'
import { UserService } from '../../../core/services/user.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'pf-products-selector',
    templateUrl: './products-selector.component.html',
    standalone: false
})

export class ProductsSelectorComponent implements OnInit {
  userInfo: any
  selectedList: ProductsSelectorInterface
  title = ''
  description = ''
  uiCouponTargetSelection = UICouponTargetSelection
  tooManyCollections = false
  tooManyProducts = false
  constructor(
    private userService: UserService,
    private productsListService: ProductsListService,
    public dialogRef: MatDialogRef<ProductsSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProductsSelectorInterface,
  ) {
    /**
    * Since backend stores selected variants and products differently
    * we need to verify on each init that selected products and variants are being populated properly
    */
    if (this.data.type === this.uiCouponTargetSelection.entitled_products && !this.productsListService.productsOnly) {
      const correctedSets = this.productsListService.correctSelectedIfNeeded(
        new Set(this.data.ids.products || []),
        new Set(this.data.ids.variants || []),
        this.data.selectMultiple,
        _.get(this.data, 'selectMultipleVariants', this.data.selectMultiple)
      )
      this.data.ids.products = Array.from(correctedSets.products)
      this.data.ids.variants = Array.from(correctedSets.variants)
    }
  }

  ngOnInit(): void {
    this.userInfo = this.userService.userInfo

    if (this.data.type === this.uiCouponTargetSelection.entitled_products) {
      this.title = `Select Product${this.data.selectMultiple ? 's' : ''}`
      if (!this.data.selectMultiple) {
        this.description = 'You can only select 1 product at this time'
      }
      if (this.data.maxSelectionCount) {
        this.description = `You can only select up to ${this.data.maxSelectionCount} products`
      }
    } else if (this.data.type === this.uiCouponTargetSelection.entitled_collections) {
      this.title = `Select Collection${this.data.selectMultiple ? 's' : ''}`
    }
  }

  updateList(list: ProductsSelectorInterface) {
    this.selectedList = list
  }

  buttonDisabled() {
    const selectionEmpty = _.get(this.selectedList, 'ids.products', []).length === 0
                        && _.get(this.selectedList, 'ids.variants', []).length === 0
                        && _.get(this.selectedList, 'ids.collections', []).length === 0
    this.tooManyCollections = _.get(this.selectedList, 'ids.collections', []).length > (this.data.maxSelectionCount || 100)
    if (this.data.maxSelectionCount) {
      this.tooManyProducts = _.get(this.selectedList, 'ids.products', []).length > this.data.maxSelectionCount
      return this.tooManyProducts || selectionEmpty
    }
    return selectionEmpty || this.tooManyCollections
  }

  onSelectClick() {
    // Special handling for Products
    if (this.data.type === this.uiCouponTargetSelection.entitled_products) {
      const alteredData = this.productsListService.prepareProductsForSubmit(this.selectedList)
      this.dialogRef.close(alteredData)
    } else if (this.data.type === this.uiCouponTargetSelection.entitled_collections) {
      const alteredData = this.productsListService.prepareCollectionsForSubmit(this.selectedList)
      this.dialogRef.close(alteredData)
    }
  }
}
