import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  HostListener,
  OnDestroy,
  OnInit,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core'
import { isEqual } from 'lodash'
import { environment } from '../../../../environments/environment'
import { ScalePreviewsService } from '../../services/scale-previews/scale-previews.service'
import { Subscription } from 'rxjs'
import { CampaignPluginName } from '../../models/campaign/campaign'
import { WidgetNotificationParams } from '../../models/widget/notification.model'
import { CampaignDesignVariables } from '../../modules/social-proof-design-step/models/campaign-design.models'
import { CommonModule } from '@angular/common'

@Component({
    selector: 'pf-preview-notification',
    templateUrl: './preview-notification.component.html',
    styles: [`
    :host {
      display: block;
    }
    ::ng-deep svg {
      vertical-align: unset;
    }
  `],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
    ]
})
export class PreviewNotificationComponent implements OnChanges, OnDestroy, OnInit {
  @Input() data: WidgetNotificationParams | any
  @Input() previewSize: { width: number, height: number }
  @Input() styles: string
  @Input() autoScale: boolean
  @Input() pluginName: CampaignPluginName
  @Input() variables: CampaignDesignVariables
  @ViewChild('preview', { static: true }) preview

  subscription = new Subscription()
  debounceTimeout

  @HostListener('window:resize', ['$event']) onResize() {
    if (this.autoScale) {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.updateScale(true)
      }, 250)
    }
  }

  constructor(
    private hostElement: ElementRef,
    private scalePreviewsService: ScalePreviewsService,
  ) {
  }

  notLoaded: boolean = true
  previewData: { data: WidgetNotificationParams, previewSize: { width: number, height: number }, styles: string, variables: CampaignDesignVariables }
  production = environment.production

  ngOnInit() {
    if (this.autoScale) {
      this.subscription.add(
        this.scalePreviewsService.getScale().subscribe(res => {
          this.preview.nativeElement.style.transform = `scale(${res.scale})`
          this.preview.nativeElement.style.transformOrigin = `50% 0%`
        }),
      )
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.previewData) {
      this.previewData = {} as any
    }
    if (changes.data && !isEqual(this.previewData.data, changes.data.currentValue)) {
      this.previewData.data = changes.data.currentValue
    }
    if (changes.previewSize && !isEqual(this.previewData.previewSize, changes.previewSize.currentValue)) {
      this.previewData.previewSize = changes.previewSize.currentValue
    }
    if (changes.styles && !isEqual(this.previewData.styles, changes.styles.currentValue)) {
      this.previewData.styles = changes.styles.currentValue
      if (this.autoScale) {
        setTimeout(() => {
          this.updateScale()
        }, 100)
      }
    }
    if (changes.variables && !isEqual(this.previewData.variables, changes.variables.currentValue)) {
      this.previewData.variables = { ...changes.variables.currentValue }
    }
    if (window['PROOF_FACTOR_PREVIEW_LOADED']) {
      this.notLoaded = false
    }
  }

  updateScale(resize = false) {
    this.scalePreviewsService.setScale(
      this.hostElement.nativeElement,
      this.preview.nativeElement,
      this.previewData.variables,
      this.pluginName,
      resize,
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.scalePreviewsService.destroy()
  }
}
