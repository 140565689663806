import {Injectable} from '@angular/core'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http'
import {Observable, throwError} from 'rxjs'
import {catchError} from 'rxjs/operators'
import * as Sentry from '@sentry/browser'
import { GlobalErrorModalComponent } from '../../shared/components/modals/global-error/global-error.component'
import { AccountLockedModalComponent } from '../../shared/components/modals/account-locked.modal/account-locked.component'
import { UpgradePlanModalComponent } from '../../shared/components/modals/upgrade-plan.modal/upgrade-plan.modal.component'
import { ActiveAppCampaigns } from '../../shared/models/app-campaign-type.model'
import { UserService } from '../services/user.service'
import { PricingUpdatesModalComponent } from '../../shared/components/modals/pricing-updates.modal/pricing-updates.modal.component'
import { CustomSnackbarService } from '../../shared/modules/custom-snackbar/custom-snackbar.service'
import { InvoiceNotPaidError, RoleBasedAccessDeniedError } from './role-based.interceptor'
import { UnauthorizedModalComponent } from '../../shared/components/modals/unauthorised/unauthorised-modal.component'
import { ResetLoading } from '../../store/loading/loading.actions'
import { Store } from '@ngrx/store'
import { StoreState } from '../../store/store.state'
import {
  CustomPricePlanModalType,
  CustomPricePlanReminderModal,
} from '../../shared/components/modals/custom-price-plan-reminder-modal/custom-price-plan-reminder-modal.component'
import { OverlayContainer } from '@angular/cdk/overlay'
import * as LogRocket from 'logrocket'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  dialogRef: MatDialogRef<GlobalErrorModalComponent>

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private store: Store<StoreState>,
    private snackbarService: CustomSnackbarService,
    private overlay: OverlayContainer,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof RoleBasedAccessDeniedError) {
            // Unauthorized access handling when role is not allowed
            this.showRoleAccessDeniedModal(error)
            this.store.dispatch(new ResetLoading())
            return throwError(error)
          }
          if (error instanceof InvoiceNotPaidError) {
            this.showInvoiceReminderModal()
            this.store.dispatch(new ResetLoading())
            return throwError(error)
          }
          let errorMessage = ''
          if (!(error.error instanceof ErrorEvent)) {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
            Sentry.addBreadcrumb({
              type: 'HttpErrorResponse',
              message: errorMessage,
              data: error.error,
            })
          }
          /* Capture Errors With LogRocket */
          LogRocket.captureException(error, {
            extra: {
              url: request.url
            }
          })

          if (error.status && error.status === 500) {
            // Turning off 500 modal for analytics route, to release the urgent feature PF-3524
            // should be turned back on when PF-3524 is fixed on backend
            // Also turning it off for products creation for PF-3516 to avoid oops error because of permissions
            // should be turned back on once error codes are put into place
            if (!error.url.includes('v1/dashboard/analytics') && !error.url.includes('v1/me/shop/products')) {
              this.showAlertModal(error)
            }
          }
          if (error?.status === 401) {
            if (error?.error?.message === "Account Locked") {
              this.showLockedModal(error)
            }
            if (error?.error?.error_code === 'shopify_app_not_installed') {
              this.showNotInstalledToast()
            }
          }
          if (error?.status === 400) {
            if (error?.error?.error_code === 'exceeds_max_active_campaigns') {
              this.showUpgradePlanModal(error?.url)
            } else if (error?.error?.error_code === 'exceeds_max_active_customers') {
              this.showUpgradePlanModal(error?.url, 'customers')
            } else if (['budding_past_deadline', 'free_plan_high_email_activity'].includes(error?.error?.error_code)) {
              this.showPricingDeadlineModal()
            }
          }

          return throwError(error)
        }),
      )
  }

  showNotInstalledToast() {
    this.snackbarService.showError({
      title: 'App Not Installed',
      text: 'It looks like you don\'t have this app installed on your store. Please install before selecting a pricing plan.',
      persist: true,
      persist_forever: true,
    })
  }

  showAlertModal(err: HttpErrorResponse) {
    if (this.dialog.openDialogs.length === 0) {
      this.dialogRef = this.dialog.open(GlobalErrorModalComponent, {
        width: '50%',
        data: {error: err},
      })
    }
  }

  showRoleAccessDeniedModal(err: HttpErrorResponse) {
    this.dialog.closeAll()
    // Unauthorized access handling
    this.dialog.open(UnauthorizedModalComponent, {
      width: '400px',
    })
  }

  showInvoiceReminderModal() {
    this.dialog.closeAll()
    // in case user removes the cdk-overlay-container element from the DOM, append it back
    if (!document.querySelector('.cdk-overlay-container')) {
      const cdkContainer = this.overlay.getContainerElement()
      document.body.appendChild(cdkContainer)
    }
    this.dialog.open(CustomPricePlanReminderModal, {
      width: '410px',
      disableClose: true,
      data: {
        type: CustomPricePlanModalType.invoiceReminder,
        accountLocked: window.location.href?.includes('/signup/returning/subscriptions'), // if user is on returning plans page, show account locked info instead
      },
    })

  }


  showLockedModal(err: HttpErrorResponse) {
    if (this.dialog.openDialogs.length === 0) {
      this.dialog.open(AccountLockedModalComponent, {
        maxWidth: '500px',
        data: {error: err},
      })
    }
  }

  showUpgradePlanModal(url = null, reason = 'campaigns') {
    let type: ActiveAppCampaigns = ActiveAppCampaigns.Popup
    if (url) {
      if (url.includes('sms_automations')) {
        type = ActiveAppCampaigns.SmsAutomation
      } else if (url.includes('broadcast_sms')) {
        type = ActiveAppCampaigns.SmsBroadcast
      } else if (url.includes('broadcast_emails')) {
        type = ActiveAppCampaigns.EmailBroadcast
      } else if (url.includes('email_automations')) {
        type = ActiveAppCampaigns.EmailAutomation
      } else if (url.includes('upsell')) {
        type = ActiveAppCampaigns.PostPurchaseUpsell
      }
    }
    // for budding users show upgrade deadline modal instead
    // TODO remove this check once BE switches to budding_past_deadline for budding users
    if (this.userService.isLegacyBuddingPlanUser) {
      this.showPricingDeadlineModal()
    } else  {
      this.dialog.open(UpgradePlanModalComponent, {
        maxWidth: '580px',
        data: { type, reason }
      })
    }
  }

  showPricingDeadlineModal() {
    if (!this.dialog?.openDialogs?.find(dialog => dialog.componentInstance instanceof PricingUpdatesModalComponent)) {
      this.dialog.open(PricingUpdatesModalComponent, {
        width: '560px',
        disableClose: true,
        data: {}
      })
    }
  }

}
