import { CommonModule } from '@angular/common'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { LoadingOverlayComponent } from '../../../../shared/components/loading-overlay/loading-overlay.component'
import { ABTestingPromoService } from '../../../../shared/modules/ab-testing-promo/services/ab-testing-promo.service'
import { TutorialVideoItem } from '../../models/tutorials.model'
import { TutorialsAnalyticsService } from '../../services/tutorials-analytics.service'
import { VideoPlayerComponent } from '../player/video-player.component'
import { UserService } from './../../../../core/services/user.service'
import { VideoTutorialTriggerButtonComponent } from '../trigger-button/video-tutorial-trigger-button.component'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'


@Component({
    templateUrl: './video-tutorial-modal.component.html',
    styleUrls: ['./video-tutorial-modal.component.scss'],
    imports: [
        VideoTutorialTriggerButtonComponent,
        VideoPlayerComponent,
        CommonModule,
        LoadingOverlayComponent
    ],
    providers: [
        TutorialsAnalyticsService,
        ABTestingPromoService,
    ]
})

export class VideoTutorialModalComponent implements OnInit, OnDestroy {
  header = ''
  videoLoaded = false
  youtubeURL
  cloudflareURL
  isShopify = this.userService.isShopifyShop

  constructor(
    public dialogRef: MatDialogRef<VideoTutorialModalComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: {tutorialVideoItem: TutorialVideoItem},
  ) {
    this.header = data.tutorialVideoItem.title
  }

  ngOnInit(): void {
    this.setTriggerVisibility(false)
    this.youtubeURL = this.data.tutorialVideoItem.id
    this.cloudflareURL = this.data.tutorialVideoItem.cloudflare.id
  }

  close() {
    this.dialogRef.close()
  }

  ngOnDestroy() {
    this.setTriggerVisibility(true)
  }

  private setTriggerVisibility(visible) {
    // if (this.videoTutorialService.shouldShowTrigger) {
    //   this.videoTutorialService.setTriggerVisibility(visible)
    // }
  }
}
