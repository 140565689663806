import { Component, EventEmitter, Output } from '@angular/core'
import { GiftCard } from '../../models/gift-card'

@Component({
    selector: 'pf-overlay-gift-card',
    templateUrl: './overlay-gift-card.component.html',
    styleUrls: ['./overlay-gift-card.component.scss'],
    standalone: false
})
export class OverlayGiftCardComponent {
  @Output() closeOverlay = new EventEmitter<GiftCard>()
  constructor() { }

}
