import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { LimitBlockerService } from '../../services/enforcement/limit-blocker.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { RouterModule } from '@angular/router';
import { ShopifyLimitEnforcementService } from '../../services/enforcement/shopify-limit-enforcement.service';

@Component({
    selector: 'one-limit-blocker',
    imports: [
        CommonModule,
        RouterModule,
    ],
    templateUrl: './limit-blocker.component.html',
    styleUrl: './limit-blocker.component.scss'
})
export class LimitBlockerComponent implements OnInit, OnDestroy {
  private subscription = new Subscription()
  @Input({required: true}) id: string
  @Input({required: false}) blockerMessage: TemplateRef<any>
  blockerResolved = false
  featureBlocked = false

  constructor(
    private limitBlockerService: LimitBlockerService,
    private shopifyLimitEnforcementService: ShopifyLimitEnforcementService,
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.resolveBlocker()
  }
  
  resolveBlocker() {
    if (this.userService.isStripeCustomer) {
      this.subscription.add(
        this.limitBlockerService.resolveFeature(this.id).subscribe({
          next: feature => {
            this.featureBlocked = typeof feature?.blocked !== 'undefined' ? feature.blocked : false
            this.blockerResolved = true
          },
          error: err => {
            this.blockerResolved = true
          }
        })
      )
    } else {
      this.subscription.add(
        this.shopifyLimitEnforcementService.enforced$.subscribe(enforced => {
          this.featureBlocked = enforced
          this.blockerResolved = true
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
