import { CommonModule } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { BrandingService } from '../../../core/services/branding.service'
import { CrispService } from '../../../core/services/crisp.service'
import { MessageBoxComponent } from '../message-box/message-box.component'

@Component({
    selector: 'pf-support-boxs',
    templateUrl: './support-boxs.component.html',
    styleUrls: ['./support-boxs.component.scss'],
    imports: [
        CommonModule,
        MessageBoxComponent,
    ]
})
export class SupportBoxsComponent implements OnInit, OnDestroy {
  @Input() hasCalendly = true
  @Input() hasContactSupport = true
  @Input() hasRequestFeature = true
  calendlyScript
  constructor(
    private crispService: CrispService,
    private sanitizer: DomSanitizer,
    private brandingService: BrandingService,
  ) {
    this.calendlyScript = this.sanitizer.bypassSecurityTrustHtml(`<link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet">
    <style>.calendly-popup { max-height: unset!important; }</style>
    <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>`)
  }

  ngOnInit() {

  }

  public openCalendlyLink() {
    if (window && window['Calendly'] && window['Calendly'].initPopupWidget) {
      window['Calendly'].initPopupWidget({ url: 'https://calendly.com/one-csm' })
    }
  }

  public contactSupport() {
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
    }
  }

  public requestFeature() {
    window.open(`${this.brandingService.getBrandingData().canny}/feature-requests`, '_blank')
  }

  ngOnDestroy() {
  }
}
