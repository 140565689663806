import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { StoreState } from '../../store/store.state'
import { ShowLoading, ResetLoading } from '../../store/loading/loading.actions'
import { first } from 'rxjs/operators'
import { Subscription } from 'rxjs'
import { ApiService } from '../../core/services/api/api.service'

export interface CannyParams {
  companyID: string,
  redirect: string
}

@Component({
    styleUrls: ['./canny.component.scss'],
    templateUrl: './canny.component.html',
    standalone: false
})
export class CannyLoginComponent implements OnDestroy {
  domain = ''

  errors: string[] = []
  messages: string[] = []

  isLoading = false
  subscription = new Subscription()

  constructor(
    private activatedRoute: ActivatedRoute,
    protected router: Router,
    private store: Store<StoreState>,
    private apiService: ApiService,
  ) {
    this.store.dispatch(new ShowLoading('CANNY_SSO'))
    this.subscription.add(this.activatedRoute.queryParams.pipe(first())
      .subscribe(queryParams => {
        this.handleCannyParams(queryParams as CannyParams)
      }))
  }

  handleCannyParams(params: CannyParams) {
    this.subscription.add(this.apiService.post(`/v1/me/canny/generate_sso_token`)
      .subscribe((resp) => {
        const redirectURL = 'https://canny.io/api/redirects/sso?companyID=' + params.companyID + '&ssoToken=' + resp['sso_token'] + '&redirect=' + params.redirect
        window.location.assign(redirectURL)
      }))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.store.dispatch(new ResetLoading())
  }
}
