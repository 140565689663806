import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { Step } from './interfaces/step.interface'
import { SMSStepperStep } from '../sms-onboarding/models/sms-onboarding.model'
import { SidebarStep } from '../../../core/modules/sidebar-stepper/sidebar-stepper.interface'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs/operators'
import { Subscription } from 'rxjs'

@Component({
    selector: 'pf-steps-panel',
    templateUrl: './steps-panel.component.html',
    styleUrls: ['./steps-panel.component.scss'],
    standalone: false
})
export class StepsPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() steps: Step[] | SMSStepperStep[] | SidebarStep[] = [];
  @Input() expanded: boolean;
  @Input() nonSequentialNav: boolean = false;
  @Input() stepsClickable: boolean = true;
  @Input() multiline: boolean = false;
  currentStep: any = {}
  subscription = new Subscription()

  constructor(private router: Router) {
    this.subscription.add(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => this.selectCurrentStep())
    )

  }

  ngOnInit() {
    this.selectCurrentStep()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.steps?.currentValue) {
      this.selectCurrentStep()
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  onStepClick(selectedStep) {
    this.selectCurrentStep(selectedStep?.url)
  }

  private selectCurrentStep(_url = null) {
    const url = _url || this.router.url.split('/').pop()
    this.steps?.forEach((step, i) => {
      const shortenedPath = step.url.split('/').pop()
      if (url === step.url || url === shortenedPath) {
        this.currentStep = { ...step, index: i }
      }
    })
  }
}
