import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    templateUrl: './image-upload-modal.component.html',
    styleUrls: ['./image-upload-modal.component.scss'],
    standalone: false
})
export class ImageUploadModalComponent implements OnInit {
  selectedImage: string
  imageTypeError = false
  constructor(
    public dialogRef: MatDialogRef<ImageUploadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { path: string, type?: string | 'unlayer'},
  ) { }

  ngOnInit(): void { }

  setImage(img: string) {
    this.selectedImage = img
  }

  apply() {
    this.dialogRef.close(this.selectedImage)
  }
}
