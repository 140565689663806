import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { RouterModule } from '@angular/router'
import { BrandingService } from '../../../../core/services/branding.service'
import { gsap } from 'gsap'
import { SideNavService } from '../services/one-side-nav.service'
import { Subscription } from 'rxjs'
import { BreakpointService } from '../../../../core/services/breakpoint.service'

@Component({
    selector: 'one-main-logo',
    templateUrl: 'one-main-logo.component.html',
    styleUrls: ['one-main-logo.component.scss'],
    imports: [
        CommonModule,
        RouterModule,
    ]
})

export class OneMainLogoComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isMobile: boolean = false
  private subscription = new Subscription()

  brandingLogoPath: string
  brandingLogoClass: string
  brandingName: string

  sideNavPinned: boolean = this.sideNavService.pinnedSideNav$.getValue()
  sideBarForcedActive = this.breakpointService.bp$.getValue()?._1920?.active
  sideBarExpanded = this.sideNavPinned || this.sideBarForcedActive
  constructor(
    private sideNavService: SideNavService,
    private brandingService: BrandingService,
    private breakpointService: BreakpointService,
  ) {
    const brandingData = this.brandingService.getBrandingData()
    this.brandingLogoPath = brandingData?.logo
    this.brandingLogoClass = brandingData?.class
    this.brandingName = brandingData?.name
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.setSVGInitialStyles()
    if (!this.isMobile) {
      this.initAnimation()
    }
  }

  setSVGInitialStyles() {
    gsap.set('.one-app path', {
      opacity: 0,
      x: -200,
    })
    gsap.set('.one-ai path', {
      opacity: 0,
      y: -40,
    })
    gsap.set('.one-store path', {
      opacity: 0,
      y: 40,
    })
    // gsap.set('.one-decision path', {
    //   opacity: 0,
    //   y: -40,
    // })
  }

  initAnimation() {
    const tl = gsap.timeline({paused: !this.sideBarExpanded})

    // APP
    tl.to('.one-app path', {
      duration: 0.2,
      opacity: 1,
      x: 0,
      stagger: {
        amount: -0.3,
      },
    })
    tl.to('.one-app path', {
      duration: 0.3,
      opacity: 0,
      y: 40,
      stagger: {
        amount: -0.3,
      },
      delay: 1
    }, 'entry')

    // AI
    tl.to('.one-ai path', {
      duration: 0.3,
      opacity: 1,
      y: 0,
      stagger: {
        amount: -0.3,
      },
    })
    tl.to('.one-ai path', {
      duration: 0.3,
      opacity: 0,
      y: 40,
      stagger: {
        amount: -0.3,
      },
      delay: 1
    })

    // STORE
    tl.to('.one-store path', {
      duration: 0.3,
      opacity: 1,
      y: 0,
      stagger: {
        amount: -0.3,
      },
    })
    // tl.to('.one-store path', {
    //   duration: 0.3,
    //   opacity: 0,
    //   y: -40,
    //   stagger: {
    //     amount: -0.3,
    //   },
    //   delay: 1
    // })

    // DECISION
    // tl.to('.one-decision path', {
    //   duration: 0.3,
    //   opacity: 1,
    //   y: 0,
    //   stagger: {
    //     amount: -0.3,
    //   },
    // })
    // tl.to('.one-decision path', {
    //   duration: 0.3,
    //   opacity: 0,
    //   y: 40,
    //   stagger: {
    //     amount: -0.3,
    //   },
    //   delay: 1
    // })

    this.subscription.add(
      this.sideNavService.toggleActive$.subscribe((value) => {
        if (!this.sideBarExpanded) {
          if (value) {
            tl.seek(0)
            tl.play()
          } else {
            tl.seek('entry')
            tl.reverse()
          }
        }
      })
    )
    this.subscription.add(
      this.sideNavService.pinnedSideNav$.subscribe((value) => {
        this.sideNavPinned = value
        this.sideBarExpanded = value || this.sideBarForcedActive
      })
    )
    this.subscription.add(
      this.breakpointService.bp$.subscribe((bp) => {
        this.sideBarExpanded = bp?._1920?.active || this.sideNavPinned
        // if sidebar is pinned than the animation was already played so skip it
        if (!this.sideNavPinned) {
          // for cases when user changes screen size from >1920 and back
          if (bp?._1920?.active && !this.sideBarForcedActive ) { // if user changes from smaller screen to >1920 play animation
            tl.seek(0)
            tl.play()
          } else if (this.sideBarForcedActive && !bp?._1920?.active) { // if user changes from >1920 to smaller screen reset animation
            tl.seek('entry')
            tl.reverse()
          }
        }
        this.sideBarForcedActive = bp?._1920?.active
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
