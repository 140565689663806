import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces'
import _ from 'lodash'
import { Subscription } from 'rxjs'
import { FileStorageService } from '../../../../../core/services/file-storage.service'
import { UserService } from '../../../../../core/services/user.service'
import { ImageUploadService } from '../../services/image-upload.service'

@Component({
    selector: 'pf-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
    standalone: false
})

export class ImageUploadComponent implements OnInit, OnDestroy {
  @Output() selectedImage: EventEmitter<string> = new EventEmitter()
  @Output() imageTypeError: EventEmitter<boolean> = new EventEmitter()
  @Input() path: string = 'images'
  private subscription = new Subscription()
  private userId = this.userService.userInfo.id
  public selectedImageSrc: string
  public file: any

  constructor(
    private userService: UserService,
    private imageUploadService: ImageUploadService,
    private fileStorageService: FileStorageService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.imageUploadService.imageSelected$.subscribe(res => {
        this.selectedImageSrc = res
      })
    )
  }

  fileSelected(event) {
    const isValidFile = this.imageUploadService.validateImageFileType(event)
    this.imageTypeError.emit(!isValidFile)
    if (!isValidFile) {
      return
    }
    const file = event.target.files[0]
    const newFileName = FileStorageService.generateFileName(file)
    const filePath = `${this.userId}/${this.path}/${newFileName}`
    if (this.userId) {
      this.file = null
      this.subscription.add(
        this.fileStorageService.upload(filePath, file, (data: UploadTaskSnapshot) => {
          const bucket = data.ref.bucket === 'cdn.prooffactor.com' ? 'cdn.one.store' : data.ref.bucket
          const path = `https://${bucket}/${data.ref.fullPath}`
          this.selectedImage.emit(path)
          this.imageUploadService.imageUploaded$.next(path)
          this.imageUploadService.imageSelected$.next(path)
        }).subscribe()
      )
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
