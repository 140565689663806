import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { trigger } from '@angular/animations'
import { animationCarousel } from '../../animations/promotion-cards-animations'
import { AutomationTypes, HolidayTypes } from '../../models/promotion.models'
import { SegmentAnalyticsService } from '../../../../services/segment-analytics.service'
import { CommonModule } from '@angular/common'
import { OneIconComponent } from '../../../one-icon/one-icon.component'
import { LimitBlockerService } from '../../../../../core/services/enforcement/limit-blocker.service'

@Component({
    selector: 'pf-featured-apps',
    templateUrl: './featured-apps.component.html',
    styleUrls: ['./featured-apps.component.scss'],
    animations: [
        trigger('animImageSlider', animationCarousel),
    ],
    imports: [
        CommonModule,
        OneIconComponent,
    ]
})
export class FeaturedAppsComponent implements OnInit {
  @Input() featuredApps = []
  @Output() onShowModal = new EventEmitter<HolidayTypes | AutomationTypes >()
  slideControl: any[]
  featuredAppsCounter = 0
  private segmentEventNames = {
    [AutomationTypes.CartRecovery]: 'Clicked Featured Apps Banner - Cart Abandon',
    [AutomationTypes.CheckoutUpsell]: 'Clicked Featured Apps Banner - Upsell'
  }

  constructor(
    private limitBlockerService: LimitBlockerService,
    private segmentAnalyticsService: SegmentAnalyticsService
  ) { }

  ngOnInit(): void {
    this.slideControl = this.featuredApps.map((app, index) => (index ? 'outleft' : 'inleft'))
  }


  slide(direction: string) {
    const incr = direction == 'right' ? -1 : +1
    const prev = this.featuredAppsCounter
    this.featuredAppsCounter =
      (this.featuredAppsCounter + this.featuredApps.length + incr) % this.featuredApps.length
    this.onSlide(this.featuredAppsCounter, prev, direction)
    if (direction === 'left') {
      this.segmentAnalyticsService.track('Next Arrow in Featured Apps Banner')
    }
  }


  onSlide(current, prev, direction) {
    this.slideControl[current] = 'in' + direction
    this.slideControl[prev] = 'out' + direction
  }


  showHolidayPromotionModal(id: HolidayTypes | AutomationTypes) {
    this.onShowModal.next(id)
  }

  showUpgradePopup() {
    this.limitBlockerService.showUpgradePopup()
  }


  onGetStartedClick(app) {
    if (this.segmentEventNames[app.id]) {
      this.segmentAnalyticsService.track(this.segmentEventNames[app.id])
    }
    app.action ? app.action() : this.showHolidayPromotionModal(app.id)
  }
}
