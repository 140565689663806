import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
    selector: 'pf-side-navigation-bar-headline',
    templateUrl: './side-navigation-bar-headline.component.html',
    styleUrls: ['./side-navigation-bar-headline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SideNavigationBarHeadlineComponent {
  @Input() headline: string = 'Some Mocked Headline';
  @Input() expanded: boolean = false;
}
