import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    selector: 'pf-no-table-data',
    templateUrl: './no-table-data.component.html',
    styleUrls: ['./no-table-data.component.scss'],
    imports: [
        CommonModule,
    ]
})
export class NoTableDataComponent implements OnInit {
  @Input() text: string
  @Input() buttonText: string
  @Output() buttonClick: EventEmitter<null> = new EventEmitter<null>()

  constructor() { }

  ngOnInit(): void {
  }

}
