import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { RouterModule } from '@angular/router'
import { OneIconComponent } from '../one-icon/one-icon.component'
import { PageTitleService } from './services/page-title.service'

@Component({
    selector: 'one-page-title',
    imports: [
        CommonModule,
        RouterModule,
        OneIconComponent,
    ],
    templateUrl: './page-title.component.html',
    styleUrl: './page-title.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnePageTitleComponent implements OnInit {
  @Input({required: true}) title: string = null
  @Input() backBtnUrl: string = null
  @Input() clickMethod: Function

  constructor(
    public pageTitleService: PageTitleService,
  ) { }

  ngOnInit(): void {
    this.pageTitleService.showTitle()
    this.pageTitleService.hideBackBtn()
  }

}
