import { CommonModule } from '@angular/common'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import _ from 'lodash'
import { Subscription } from 'rxjs'
import { UpsellListItem } from '../../../../pages/upsell/models/upsell-page.models'
import { UpsellService } from '../../../../pages/upsell/services/upsell.service'
import { SegmentAnalyticsService } from '../../../services/segment-analytics.service'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'

@Component({
    templateUrl: './new-upsell.modal.component.html',
    styleUrls: ['./new-upsell.modal.component.scss'],
    providers: [UpsellService],
    imports: [
        CommonModule,
        MatDialogModule,
    ]
})

export class NewUpsellModalComponent implements OnInit, OnDestroy {
  subscription$ = new Subscription()

  presets = [
    {
      name: 'Order Confirmation Page Upsell',
      description: 'Increase your average order value by suggesting a related product.',
      image: 'apps/post-checkout-upsell.svg',
      path: 'upsell'
    }
  ]

  constructor(
    public dialogRef: MatDialogRef<NewUpsellModalComponent>,
    private upsellService: UpsellService,
    private router: Router,
    private segmentAnalyticsService: SegmentAnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: { hideBroadcasts: boolean, hideAutomations: boolean },
  ) {
  }

  ngOnInit(): void {
    this.dialogRef.addPanelClass(['_no-padding', '_custom-scrollbar'])
  }

  getBgImage(image) {
    return `url(/app_assets/${image})`
  }

  select(result) {
    this.subscription$.add(this.upsellService.createUpsellOffer({name: 'Untitled'} as UpsellListItem).subscribe(res => {
      if (res && res.id) {
        this.router.navigate([`upsell/${res.id}/offer`])
        this.dialogRef.close()
        this.segmentAnalyticsService.track('Create Upsell Offer', {
          upsell: {
            id: res.id,
            name: res.name,
          }
        })
      }
    }, err => {
      this.router.navigate([`upsell`])
      this.dialogRef.close()
    }))
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe()
  }
}
