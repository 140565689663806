import { Component, Inject, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UserService } from '../../../../core/services/user.service'
import { CrispService } from '../../../../core/services/crisp.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    templateUrl: 'unauthorized-modal.component.html',
    styleUrls: ['unauthorized-modal.component.scss'],
    imports: [
        CommonModule,
    ]
})

export class UnauthorizedModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<UnauthorizedModalComponent>,
    private userService: UserService,
    private crispService: CrispService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  ngOnInit() { }

  contactSupport(event: any) {
    event.preventDefault()
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', `Hi ONE Team,\n\nI am having trouble accessing and editing part of my account, can you help?`]])
    }
  }

  openCalendlyWidget() {
    if (window && window['Calendly'] && window['Calendly'].initPopupWidget) {
      const userInfo = this.userService?.userInfo
      const name = userInfo?.shop_user?.shopify_shop_user?.first_name ? `${userInfo?.shop_user?.shopify_shop_user?.first_name} ${userInfo?.shop_user?.shopify_shop_user?.last_name || ''}` : userInfo?.profile?.first_name ? `${userInfo?.profile?.first_name} ${userInfo?.profile?.last_name || ''}` : ''
      const email = userInfo?.shop_user?.shopify_shop_user?.email || userInfo?.email || ''
      const phone = userInfo?.shop_user?.shopify_shop_user?.phone || ''
      const storeUrl = userInfo?.shop?.store_url  || ''
      window['Calendly'].initPopupWidget({
        url: 'https://calendly.com/one-csm',
        prefill: {
          name: name,
          email: email,
          customAnswers: {
            a1: phone,
            a2: storeUrl,
          }
        },
      })
    }
    this.dialogRef.close()
  }
}
