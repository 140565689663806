import { Component, Input, OnInit } from '@angular/core'
import { VideoTutorialModalComponent } from '../modal/video-tutorial-modal.component'
import { UserService } from '../../../../core/services/user.service'
import { SegmentAnalyticsService } from '../../../../shared/services/segment-analytics.service'
import { TutorialsService } from '../../services/tutorials.service'
import { TutorialVideoItem } from '../../models/tutorials.model'
import { CommonModule } from '@angular/common'
import { OneIconComponent } from '../../../../shared/components/one-icon/one-icon.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
    imports: [
        CommonModule,
        OneIconComponent,
    ],
    selector: 'one-video-tutorial-trigger-button',
    templateUrl: './video-tutorial-trigger-button.component.html',
    styleUrls: ['./video-tutorial-trigger-button.component.scss']
})
export class VideoTutorialTriggerButtonComponent implements OnInit {
  @Input() videoId: string
  triggerVisible = false
  tutorialVideoItem: TutorialVideoItem

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private tutorialsService: TutorialsService,
  ) {
    const isShopify = this.userService.userInfo?.shop?.type === 'ShopifyShop'
    const notCN = this.userService.userInfo?.country_code !== 'CN'
    this.triggerVisible = isShopify && notCN

  }

  ngOnInit() {
    this.tutorialVideoItem = this.tutorialsService.getVideoById(this.videoId)
  }

  showModal() {
    this.dialog.open(VideoTutorialModalComponent, {
      width: '800px',
      data: {
        tutorialVideoItem: this.tutorialVideoItem,
      }
    })

    this.segmentAnalyticsService.track(`Clicked ${this.tutorialVideoItem.eventName} Tutorial Trigger`)
  }
}
