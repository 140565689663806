import {Component, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core'
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms'
import { FilterOption } from '../../../pages/coupon-codes/models/rewards-model'

@Component({
    selector: 'pf-filter-select',
    templateUrl: './filter-select.component.html',
    styleUrls: ['./filter-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FilterSelectComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class FilterSelectComponent implements ControlValueAccessor {
  @ViewChild('filterRef', { static: true }) filterRef
  @Input() title: string = 'Filter by'
  @Input() width: string
  @Input() options: { value: string, title: string, group?: string }[] = []
  @Output() change = new EventEmitter<FilterOption>()

  groupArr: { group: string, groupItem: { value: string, title: string }[] }[] = []
  value: string
  private _onChange: Function

  constructor() {
  }

  writeValue(value) {
    if (value === undefined || value === null) {
      return
    }
    this.value = value

    // Set the group array
    this.groupArr = this.options.reduce((r, { group }) => {
      if (!r.some(o => o.group === group)) {
        r.push({ group, groupItem: this.options.filter(v => v.group === group) });
      }
      return r;
    }, [])
  }

  registerOnChange(fn) {
    this._onChange = fn
  }

  registerOnTouched(fn) {
    // this._onTouched = fn
  }

  filterChanged(event) {
    this.value = event.target.value
    this._onChange && this._onChange(this.value)
  }
}
