import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { CouponsImportPagination, CouponsImportResponse, ImportedCoupon } from './models/coupons-import';
import { CouponsImportService } from './services/coupons-import.service';
import { Subscription } from 'rxjs'
import _ from 'lodash';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'pf-coupons-import',
    templateUrl: './coupons-import.component.html',
    styleUrls: ['./coupons-import.component.scss'],
    standalone: false
})
export class CouponsImportComponent implements OnInit, OnDestroy {
  @ViewChild('CouponsListRef', {static: true}) couponsListRef: ElementRef
  coupons: ImportedCoupon[]
  pagination: CouponsImportPagination
  loading: boolean = false
  showResult: boolean = false
  buttonCounter: string = ''
  selectedCoupons: Set<ImportedCoupon['id']> = new Set()
  destination: string = 'Coupons Table'
  subscription = new Subscription()
  searchValue: string = ''

  constructor(
    private couponsImportService: CouponsImportService,
    public dialogRef: MatDialogRef<CouponsImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.onSearchKeyUp = _.debounce(this.onSearchKeyUp, 200)
  }

  ngOnInit() {
    this.loadPage()
    if (this.data && this.data.dest) {
      this.destination = this.data.dest
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  loadPage(params?: {before?: string, after?: string, title?: string}) {
    this.loading = true
    this.subscription.add(this.couponsImportService.getCoupons(params).subscribe(
      (res: CouponsImportResponse) => {
        this.coupons = res.coupons.map( coupon => {
          coupon.selected = this.selectedCoupons.has(coupon.id)
          return coupon
        })
        this.pagination = res.metadata
        this.loading = false
      }
    ))
  }

  onSearchKeyUp(): void {
    this.loadPage({title: this.searchValue})
    this.pagination = null
  }

  onImportClicked() {
    this.loading = true
    this.subscription.add(this.couponsImportService.importCoupons(Array.from(this.selectedCoupons)).subscribe( res => {
      this.couponsListRef.nativeElement.scrollTo(0, 0)
      this.loading = false
      this.showResult = true
    }))
  }

  selectAllChecked(event: any) {
    this.coupons.forEach( coupon => {
      if (!coupon.imported) {
        this.updateSelected(coupon, event.target.checked)
      }
    })
  }

  updateSelected(coupon: ImportedCoupon, forcedState?: boolean) {
    if (typeof forcedState !== 'undefined') {
      coupon.selected = forcedState
    } else {
      coupon.selected = !coupon.selected
    }
    if (coupon.selected) {
      this.selectedCoupons.add(coupon.id)
    } else {
      this.selectedCoupons.delete(coupon.id)
    }
    this.updateButtonLabel()
  }

  updateButtonLabel() {
    const plural = this.selectedCoupons.size === 1 ? 'COUPON' : 'COUPONS'
    this.buttonCounter = this.selectedCoupons.size ? ` ${this.selectedCoupons.size} ${plural}` : ''
  }

  prevPageClick() {
    this.loadPage({before: this.pagination.before})
  }

  nextPageClick() {
    this.loadPage({after: this.pagination.after})
  }

  onCloseClick() {
    if (this.showResult) {
      this.dialogRef.close({imported: true, count: this.selectedCoupons.size})
      return
    }
    this.dialogRef.close(false)
  }
}
