import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { PhoneInputCountry } from '../models/phone-input.model'
import { COUNTRIES_LIST } from '../../../../countriesList'

@Component({
    selector: 'pf-country-codes',
    templateUrl: './country-codes.component.html',
    standalone: false
})
export class CountryCodesComponent implements OnInit, OnChanges {
  @ViewChild('dropdownRef', {static: true}) dropdown
  @Output() onCountrySelected: EventEmitter<{country: PhoneInputCountry, emitOnChange: boolean}> = new EventEmitter()
  @Input() countryCode: string
  @Input() disabled: boolean = false

  constructor() { }
  public countries: PhoneInputCountry[]
  public selectedCountry: PhoneInputCountry
  private defaultCountryIso2: string = 'US'

  public ngOnInit() {
    this.countries = COUNTRIES_LIST.map((country: any[], index: number) => ({
      name: country[0],
      index: index,
      iso2: `${country[1]}`.toUpperCase(),
      dialCode: country[2],
      priority: country[3] || 0,
      areaCodes: country[4] || null
    }))
    this._selectDefaultCountry()
  }

  ngOnChanges(changes: SimpleChanges) {
    const value = changes?.countryCode?.currentValue
    if (value && this.countries) {
      const match = this._findFirstMatch(value, 'iso2', true)
      if (match) {
        this.selectedCountry = match
        this.onCountrySelect()
      }
    }
  }

  public onCountrySelect(emitOnChange = true) {
    this.onCountrySelected.emit({country: this.selectedCountry, emitOnChange})
  }

  public customSearchFn(term: string, item: PhoneInputCountry) {
    term = term.toLocaleLowerCase()
    return item.iso2.toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1
  }

  private _findFirstMatch(
    str: string,
    param: string,
    exact: boolean = false
  ): PhoneInputCountry {
    let countryMatch
    this.countries.some((country: any, index: number) => {
      let condition = false
      if (exact) {
        condition = country[param].toLowerCase() === str.toLowerCase()
      } else {
        condition = country[param].toLowerCase().startsWith(str.toLowerCase())
      }
      if (condition) {
        countryMatch = country
      }
      return condition
    })
    return countryMatch
  }

  private _selectDefaultCountry() {
    const match = this._findFirstMatch(this.countryCode || this.defaultCountryIso2, 'iso2', true)
    if (match) {
      this.selectedCountry = match
      this.onCountrySelect(false)
    }
  }
}



