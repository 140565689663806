import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    selector: 'pf-side-navigation-bar-pin-button',
    templateUrl: './side-navigation-bar-pin-button.component.html',
    styleUrls: ['./side-navigation-bar-pin-button.component.scss'],
    standalone: false
})
export class SideNavigationBarPinButtonComponent implements OnInit {
  @Input() sideBarPinned: boolean;
  @Input() expanded: boolean;
  @Output() pinSideBar = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

}
