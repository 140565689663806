import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces'
import _ from 'lodash'
import { Subscription } from 'rxjs'
import { FileStorageService } from '../../../../../core/services/file-storage.service'
import { UserService } from '../../../../../core/services/user.service'

@Component({
    selector: 'pf-multi-upload',
    templateUrl: './multi-upload.component.html',
    styleUrls: ['./multi-upload.component.scss'],
    standalone: false
})

export class MultiUploadComponent implements OnInit, OnDestroy {
  @Output() selectedImages: EventEmitter<any> = new EventEmitter()
  @Input() path: string = 'images'
  @Input() buttonText: string = '+ Upload New Image'
  private subscription = new Subscription()
  private userId = this.userService.userInfo.id
  public uploadedImages = []
  public file: any

  constructor(
    private userService: UserService,
    private fileStorageService: FileStorageService,
  ) { }

  ngOnInit(): void {
  }

  fileSelected(event) {
    const file = event.target.files[0]
    const newFileName = FileStorageService.generateFileName(file)
    const filePath = `${this.userId}/${this.path}/${newFileName}`
    if (this.userId) {
      this.file = null
      this.subscription.add(
        this.fileStorageService.upload(filePath, file, (data: UploadTaskSnapshot) => {
          const image = {
            name: data.ref.name,
            path: `https://${data.ref.bucket}/${data.ref.fullPath}`,
            size: _.get(data, 'size')
          }
          this.uploadedImages.push(image)
          this.selectedImages.emit(this.uploadedImages)
        }).subscribe()
      )
    }
  }

  removeImage(index) {
    this.uploadedImages.splice(index, 1)
    this.selectedImages.emit(this.uploadedImages)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
