import { CurrencyPipe } from '@angular/common'
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import _ from 'lodash'
import { forkJoin, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { FooterbarNavigationService } from '../../../../../../core/modules/footerbar-navigation/footerbar-navigation.service'
import { ApiCampaignService } from '../../../../../../core/services/api/api-campaign.service'
import { UserService } from '../../../../../../core/services/user.service'
import { SavableComponent } from '../../../../../../shared/components/savable/savable.component'
import { CustomSnackbarService } from '../../../../../../shared/modules/custom-snackbar/custom-snackbar.service'
import { ProductsSelectorInterface } from '../../../../../../shared/modules/products-selector/models/Product'
import { ProductsSelectorComponent } from '../../../../../../shared/modules/products-selector/products-selector.component'
import { ProductsListService } from '../../../../../../shared/modules/products-selector/services/products-list.service'
import { Step, StepLabels } from '../../../../../../shared/modules/steps-panel/interfaces/step.interface'
import { FormsHelperService } from '../../../../../../shared/services/forms.service'
import { SegmentAnalyticsService } from '../../../../../../shared/services/segment-analytics.service'
import { PFValidators } from '../../../../../../shared/services/validators'
import { HideLoading, ShowLoading } from '../../../../../../store/loading/loading.actions'
import { StoreState } from '../../../../../../store/store.state'
import { UICouponTargetSelection } from '../../../../../coupon-codes/models/coupon-code'
import { SavableUpsellModel } from '../../../../models/savable-upsell.model'
import { UpsellDiscountType, UpsellFormatType, UpsellListItem, UpsellShippingType, UpsellStatus, UpsellTriggerType } from '../../../../models/upsell-page.models'
import { UpsellBuilderStepsService } from '../../../../services/upsell-builder-steps.service'
import { UpsellService } from '../../../../services/upsell.service'
import { MatDialog } from '@angular/material/dialog'


@Component({
    templateUrl: './upsell-offer-step.component.html',
    styleUrls: ['./upsell-offer-step.component.scss'],
    providers: [FormsHelperService],
    standalone: false
})
export class UpsellOfferStepComponent extends SavableComponent implements SavableUpsellModel, OnInit, OnDestroy {
  @ViewChild('formRef', {static: false}) formRef: ElementRef

  upsellId = _.get(this.route, 'snapshot.data.upsell.upsellId', null)
  upsell = _.get(this.route, 'snapshot.data.upsell.upsell', null)
  upsellActive = this.upsell?.status ===  UpsellStatus.Live
  variantId = _.get(this.route, 'snapshot.data.upsell.variantId')
  variant = _.get(this.route, 'snapshot.data.upsell.variant', null)
  isRootVariant = _.get(this.variant, 'root_variant', true)

  steps: Step[] = [
    {
      label: StepLabels.Offer,
      url: 'offer',
      passed: false,
      active: true,
      disabled: this.upsell?.status !== UpsellStatus.Live,
    },
    {
      label: StepLabels.Design,
      url: 'design',
      passed: false,
      active: false,
      disabled: this.upsell?.status !== UpsellStatus.Live,
    }
  ]

  form: UntypedFormGroup

  triggerType = UpsellTriggerType
  triggerTypeOptions = [
    {
      label: 'Specific Products',
      value: UpsellTriggerType.Products
    },
    {
      label: 'Specific Collections',
      value: UpsellTriggerType.Collections
    },
    {
      label: 'All Products',
      value: UpsellTriggerType.All
    },
  ]

  discountType = UpsellDiscountType
  discountTypeOptions = [
    {
      label: 'Percentage',
      value: UpsellDiscountType.Percentage
    },
    {
      label: 'Fixed Amount',
      value: UpsellDiscountType.Fixed
    },
  ]

  triggerProductSelection: ProductsSelectorInterface = {
    type: UICouponTargetSelection.entitled_products,
    selectMultiple: true,
    productsOnly: false,
    ids: {
      products: [],
      variants: []
    },
  }
  triggerCollectionSelection: ProductsSelectorInterface = {
    type: UICouponTargetSelection.entitled_collections,
    selectMultiple: true,
    ids: {
      collections: [],
    },
  }

  targetProductSelection: ProductsSelectorInterface = {
    type: UICouponTargetSelection.entitled_products,
    selectMultiple: false,
    selectMultipleVariants: true,
    productsOnly: false,
    ids: {
      products: [],
      variants: []
    },
  }

  skipError = false
  forceNavigation = true

  productIsFree = false

  userCurrency = this.userService.userInfo?.shop?.profile?.currency || 'USD'
  userCurrencySymbol = this.currencyPipe.transform(1, this.userCurrency).replace(/\d*\.?\d*/g, '')

  subscription$ = new Subscription()

  get triggerTypeDescription() {
    if (!this.form || !this.form.get('trigger')) {
      return ''
    }
    switch (this.form.get('trigger').value) {
      case UpsellTriggerType.Products:
        return 'Select which product(s) will trigger the upsell offer.'
      case UpsellTriggerType.Collections:
        return 'Select which collection(s) will trigger the upsell offer.'
      case UpsellTriggerType.All:
        return 'Any product will trigger the upsell offer.'
    }
  }

  constructor(
    private stepService: UpsellBuilderStepsService,
    private footerbarNavigationService: FooterbarNavigationService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public productsListService: ProductsListService,
    private formsHelperService: FormsHelperService,
    private upsellService: UpsellService,
    public dialog: MatDialog,
    private snackbarService: CustomSnackbarService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private apiCampaignService: ApiCampaignService,
    private userService: UserService,
    private currencyPipe: CurrencyPipe,
    private store: Store<StoreState>,
  ) {
    super()
    this.stepService.steps$.next(this.steps)
    this.stepService.status$.next(this.upsell?.status)
    this.setFooterbarButtons()
  }

  ngOnInit(): void {
    this.initForm()
  }

  initForm() {
    const triggerProductIds = _.get(this.upsell, 'product_ids', [])
    this.triggerProductSelection.ids.products = triggerProductIds
    const triggerVariantIds = _.get(this.upsell, 'product_variant_ids', [])
    this.triggerProductSelection.ids.variants = triggerVariantIds
    const triggerCollectionIds = _.get(this.upsell, 'collection_ids', [])
    this.triggerCollectionSelection.ids.collections = triggerCollectionIds

    const targetProductIds = _.get(this.variant, 'target_product_ids', [])
    this.targetProductSelection.ids.products = targetProductIds
    const targetVariantIds = _.get(this.variant, 'target_product_variant_ids', [])
    this.targetProductSelection.ids.variants = targetVariantIds

    let name = _.get(this.variant, 'name', '')
    if (this.isRootVariant) {
      name = _.get(this.upsell, 'name', '')
      // if this is a new upsell make name empty
      if (name === 'Untitled' && this.upsell?.variants?.length === 0) {
        name = ''
      }
    }

    this.form = this.formBuilder.group({
      name: [name, [Validators.required, Validators.maxLength(255), PFValidators.noSpaceValidator()]],
      trigger: [{ value: _.get(this.upsell, 'trigger', UpsellTriggerType.Products), disabled: !this.isRootVariant}],
      trigger_products: [{ value: triggerProductIds, disabled: false }],
      trigger_variants: [{ value: triggerVariantIds, disabled: false }],
      trigger_collections: [{ value: triggerCollectionIds, disabled: false }],
      target_products: [{ value: targetProductIds, disabled: false}],
      target_variants: [{ value: targetVariantIds, disabled: false}],
      discounted: _.get(this.variant, 'discounted', false),
      discount_type: _.get(this.variant, 'discount_type', UpsellDiscountType.Percentage),
      discount_value: _.get(this.variant, 'discount_value', 0),
      no_repeat: this.variant?.no_repeat,
    }, {validators: [this.triggerProductValidator, this.targetProductValidator]})
    this.updateDiscountValidators()
    // pre-fetch products (~1st page) to cache them beforehand
    this.productsListService.preCacheProducts()
    // pre-fetch all the products that are already selected
    if (triggerProductIds.length || triggerVariantIds.length || targetProductIds.length || targetVariantIds.length) {
      this.productsListService.preCacheSpecificProducts([...triggerProductIds, ...targetProductIds], [...triggerVariantIds, ...targetVariantIds])
    }
    this.setSavableData()
    this.subscription$.add(
      this.form.valueChanges.subscribe(() => {
        this.checkIfChanged()
      })
    )
    this.checkIfChanged()
    this.checkIfProductIsFree(false)
  }

  onShowTriggerProductSelectorClick() {
    const data: ProductsSelectorInterface = {
      type: UICouponTargetSelection.entitled_products,
      productsOnly: false,
      selectMultiple: true,
      ids: {
        products: this.form.get('trigger_products').value,
        variants: this.form.get('trigger_variants').value
      },
    }
    this.productsListService.setSelection(data)

    const dialogRef = this.dialog.open(ProductsSelectorComponent, {
      width: '530px',
      data: data,
    })
    this.subscription$.add(dialogRef.afterClosed().pipe(filter((result) => !!result))
      .subscribe((result: ProductsSelectorInterface) => {
        if (result) {
          this.updateTriggerProductSelection(result)
        }
      }))
  }

  updateTriggerProductSelection(data) {
    this.productsListService.setSelection(data)
    this.triggerProductSelection = data
    this.form.get('trigger_products').patchValue(data.ids.products)
    this.form.get('trigger_variants').patchValue(data.ids.variants)
    this.checkIfChanged()
    this.showRepeatErrorIfNeeded()
  }

  onShowTriggerCollectionSelectorClick() {
    const data: ProductsSelectorInterface = {
      type: UICouponTargetSelection.entitled_collections,
      productsOnly: false,
      selectMultiple: true,
      ids: {
        collections: this.form.get('trigger_collections').value,
      },
    }
    this.productsListService.setSelection(data)

    const dialogRef = this.dialog.open(ProductsSelectorComponent, {
      width: '530px',
      data: data,
    })
    this.subscription$.add(dialogRef.afterClosed().pipe(filter((result) => !!result))
      .subscribe((result: ProductsSelectorInterface) => {
        if (result) {
          this.updateTriggerCollectionSelection(result)
        }
      }))
  }

  updateTriggerCollectionSelection(data) {
    this.productsListService.setSelection(data)
    this.triggerCollectionSelection = data
    this.form.get('trigger_collections').patchValue(data.ids.collections)
    this.checkIfChanged()
  }

  onShowTargetProductSelectorClick() {
    const data: ProductsSelectorInterface = {
      type: UICouponTargetSelection.entitled_products,
      productsOnly: false,
      selectMultiple: false,
      selectMultipleVariants: true,
      ids: {
        products: this.form.get('target_products').value,
        variants: this.form.get('target_variants').value,
      },
    }
    this.productsListService.setSelection(data)

    const dialogRef = this.dialog.open(ProductsSelectorComponent, {
      width: '530px',
      data: data,
    })
    this.subscription$.add(dialogRef.afterClosed().pipe(filter((result) => !!result))
      .subscribe((result: ProductsSelectorInterface) => {
        if (result) {
          this.updateTargetProductSelection(result)
        }
      }))
  }

  updateTargetProductSelection(data) {
    this.productsListService.setSelection(data)
    this.targetProductSelection = data
    this.form.get('target_products').patchValue(_.get(data, 'ids.products', []))
    this.form.get('target_variants').patchValue(_.get(data, 'ids.variants', []))

    // If new product or variant for existing upsell selected, reset custom text and title
    this.resetCustomTextIfNeeded()

    this.checkIfProductIsFree()
    this.checkIfChanged()
    this.showRepeatErrorIfNeeded()
  }

  resetCustomTextIfNeeded() {
    if (!this.variant) {
      return
    }
    const savedVariantIDs = this.variant.target_product_variant_ids
    const savedProductIDs = this.variant.target_product_ids
    const selectionChanged = !_.isEqual(this.form.get('target_products').value, savedProductIDs) || !_.isEqual(this.form.get('target_variants').value, savedVariantIDs)
    // only reset if format was set to custom, default & truncated options should persist
    if (selectionChanged) {
      if (this.variant.description_format === UpsellFormatType.Custom) {
        this.variant.description_custom = ''
        this.variant.description_format = UpsellFormatType.Truncated
      }
    }
  }

  public triggerProductValidator(group: UntypedFormGroup) {
    if (group) {
      if (group.get('trigger') && group.get('trigger_products') && group.get('trigger_variants') && group.get('trigger_collections')) {
        group.get('trigger_products').setErrors(null)
        group.get('trigger_variants').setErrors(null)
        group.get('trigger_collections').setErrors(null)
        switch (group.get('trigger').value) {
          case UpsellTriggerType.Products:
            if (!group.get('trigger_products').value?.length && !group.get('trigger_variants').value?.length) {
              group.get('trigger_products').setErrors({required: true})
              group.get('trigger_variants').setErrors({required: true})
              return {trigger_error: true}
            }
            return null
          case UpsellTriggerType.Collections:
            if (!group.get('trigger_collections').value?.length) {
              group.get('trigger_collections').setErrors({required: true})
              return {trigger_error: true}
            }
            return null
        }
        return null
      }
      return null
    }
  }

  public targetProductValidator(group: UntypedFormGroup) {
    if (group) {
      if (group.get('target_products') && group.get('target_variants')) {
        group.get('target_products').setErrors(null)
        group.get('target_variants').setErrors(null)
        if (!group.get('target_products').value?.length && !group.get('target_variants').value?.length) {
          group.get('target_products').setErrors({required: true})
          group.get('target_variants').setErrors({required: true})
          return {target_error: true}
        }
        return null
      }
      return null
    }
  }

  updateDiscountValidators(validate = false) {
    let validators = []
    if (this.form.get('discounted').value) {
      validators.push(Validators.required)
      validators.push(Validators.min(1))
      if (this.form.get('discount_type').value === UpsellDiscountType.Percentage) {
        if (this.form.get('discount_value').value > 100) {
          this.form.get('discount_value').patchValue(20)
        }
        validators.push(Validators.max(100))
      } else {
        validators.push(Validators.max(999999))
      }
      if (this.variant) {
        this.variant.label = true
      }
    } else {
      validators = null
    }
    this.form.get('discount_value').setValidators(validators)
    if (validate) {
      this.form.get('discount_value').updateValueAndValidity()
      this.checkIfProductIsFree()
    }
  }

  save(navigate = false) {
    this.submit(!navigate)
  }

  submit(skipNavigation = false): void {
    this.form.updateValueAndValidity()
    if (this.form.invalid || this.skipError) {
      this.formsHelperService.touchAll(this.form)
      this.formRef.nativeElement.dispatchEvent(new Event('scrollToInvalid'))
      this.snackbarService.showWarning({text: 'Please make sure all required fields are filled correctly before saving!'})
      return
    }
    const values = this.form.getRawValue()
    const upsellPayload: any = {
      trigger: values.trigger
    }

    // if accept button text value is not appropriate for the given product/discount/shipping combination
    // override it with appropriate one
    let acceptButtonOverride = this.variant?.accept_button_text
    const productAndShippingFree = this.productIsFree && _.get(this.variant, 'shipping_type', UpsellShippingType.Free) === UpsellShippingType.Free
    if (productAndShippingFree && acceptButtonOverride?.indexOf('Add Now') === -1) {
      acceptButtonOverride = 'Add Now · Free'
    } else if (!productAndShippingFree && acceptButtonOverride?.indexOf('Add Now') !== -1) {
      acceptButtonOverride = 'Pay Now'
    }

    const variantPayload: any = {
      name: values.name,
      target_product_ids: values.target_products,
      target_product_variant_ids: values.target_variants,
      discounted: values.discounted,
      discount_type: values.discount_type,
      discount_value: values.discount_value,
      label: this.variant?.label && values.discounted,
      no_repeat: values.no_repeat,
      accept_button_text: acceptButtonOverride,
    }

    if (this.isRootVariant) {
      upsellPayload.name = values.name
    }

    if (values.trigger === UpsellTriggerType.Products) {
      upsellPayload.product_ids = values.trigger_products
      upsellPayload.product_variant_ids = values.trigger_variants
    } else if (values.trigger === UpsellTriggerType.Collections) {
      upsellPayload.collection_ids = values.trigger_collections
    }

    const updateRequests = [
      this.upsellService.updateUpsell(this.upsellId, {...this.upsell, ...upsellPayload})
    ]

    if (this.variantId) {
      updateRequests.push(this.upsellService.updateVariant(this.upsellId, this.variantId, {...this.variant, ...variantPayload}))
    } else {
      updateRequests.push(this.upsellService.createUpsellVariant(this.upsellId, {...variantPayload,
        banner_title: 'Add this special offer to your order',
        timer: 5,
        timer_text: 'Exclusive offer expires in:',
        quantity: 1,
        label: values.discounted,
        description_format: UpsellFormatType.Truncated,
        description_length: 240,
        accept_button_text: acceptButtonOverride,
        decline_button_text: 'Decline Offer',
        shipping_amount: 5.99,
        locale: 'en',
        image_view: 'default',
      }))
    }

    this.store.dispatch(new ShowLoading('UpsellSubmit'))
    this.subscription$.add(
      forkJoin(updateRequests).subscribe(([upsellRes, variantRes]) => {
        this.variantId = variantRes.id
        this.snackbarService.showSuccess({title: 'Saved', text: 'Upsell Configuration'})
        if (!skipNavigation) {
          if (this.isRootVariant) {
            this.router.navigateByUrl(`/upsell/${this.upsellId}/design`)
          } else {
            this.router.navigateByUrl(`/upsell/${this.upsellId}/variant/${variantRes.id}/design`)
          }
        }
        this.upsell = upsellRes
        this.variant = variantRes
        this.variantId = variantRes.id
        this.setSavableData()
        this.checkIfChanged()
        this.store.dispatch(new HideLoading('UpsellSubmit'))
        this.segmentAnalyticsService.track(`Saved Upsell ${this.isRootVariant ? 'Offer' : 'Variant'}`, {
          upsell: {
            id: upsellRes.id,
            name: upsellRes.name,
          },
          variant: {
            id: variantRes.id,
            name: variantRes.name,
            root_variant: variantRes.root_variant
          }
        })
      },
      err => {
        this.processError(err)
        this.store.dispatch(new HideLoading('UpsellSubmit'))
      })
    )
  }

  next() {
    if (!this.form.valid) {
      this.formsHelperService.touchAll(this.form)
      this.formRef.nativeElement.dispatchEvent(new Event('scrollToInvalid'))
      this.snackbarService.showWarning({text: 'Please make sure all required fields are filled correctly before saving!'})
      return
    }
    if (this.isRootVariant) {
      this.router.navigateByUrl(`/upsell/${this.upsellId}/design`)
    } else {
      // if variantId is not set force navigation through submit
      if (!this.variantId) {
        this.forceNavigation = true
      }
      this.router.navigateByUrl(`/upsell/${this.upsellId}/variant/${this.variantId}/design`)
    }
  }

  processError(err) {
    const error = _.get(err, 'error.errors[0]', {})
    let text = 'Something went wrong, '

    if (error?.field === 'variants' && error.code === `can't be blank`) {
      text = 'Upsell offer not set up'
    } else if (error?.field === 'products' && error.code === `can't be blank`) {
      text = 'No trigger product(s) selected'
    } else if (error?.field === 'collection_ids' && error.code === `can't be blank`) {
      text = 'No trigger collection(s) selected'
    } else if (error?.field === 'target' && error.code === `blank`) {
      text = 'Please select offer product'
    } else if (error?.code) {
      text = error.code
    }
    this.snackbarService.showError({text})
  }

  checkIfProductIsFree(showWarning = true) {
    const targetProductIds = this.form.get('target_products').value
    const targetVariantIds = this.form.get('target_variants').value
    if (this.form.get('discounted').value) {
      if (this.form.get('discount_type').value === UpsellDiscountType.Percentage) {
        this.productIsFree = this.form.get('discount_value').value >= 100
        if (this.productIsFree && showWarning) {
          this.snackbarService.showWarning({title: 'Discount is 100%  off', text: 'The user will receive the upsell product for free', persist: true})
        }
      }
      if (this.form.get('discount_type').value === UpsellDiscountType.Fixed
          && (targetProductIds.length > 0 || targetVariantIds.length > 0)) {
        const discountValue = this.form.get('discount_value').value
        this.subscription$.add(this.apiCampaignService.getShopProductsByUniqueID(targetProductIds, targetVariantIds, 1, 20).subscribe(res => {
          if (res?.products?.length > 0) {
            const product = res.products[0]
            if (targetVariantIds?.length > 1 && product?.variants?.length > 1) {
              const hasPaidVariant = !!res.products[0].variants.find(v => v.price > discountValue)
              this.productIsFree = !hasPaidVariant
              if (showWarning) {
                const freeVariantCount = res.products[0].variants.filter(v => v.price <= discountValue).length
                if (freeVariantCount > 0) {
                  this.snackbarService.showWarning({
                    title: 'Discount is more than the product price',
                    text: `${freeVariantCount} of the upsell product variants will be free for customers.`,
                    persist: true
                  })
                }
              }
            } else {
              const price = product.variants?.[0]?.price || parseFloat(product?.price || '0')
              const difference = price - discountValue
              this.productIsFree = difference <= 0
              if (this.productIsFree && showWarning) {
                this.snackbarService.showWarning({
                  title: difference === 0 ? 'Discount is equal to the product price' : 'Discount is more than the product price',
                  text: 'Customers will receive the upsell product for free.',
                  persist: true
                })
              }
            }
          }
        }))
      }
    } else if (targetProductIds.length > 0 || targetVariantIds.length > 0) {
      this.subscription$.add(this.apiCampaignService.getShopProductsByUniqueID(targetProductIds, targetVariantIds, 1, 20).subscribe(res => {
        if (res?.products?.length > 0) {
          const product = res.products[0]
          if (targetVariantIds.length > 1 && product?.variants?.length > 1) {
            const hasPaidVariant = !!res.products[0].variants.find(v => v.price !== 0)
            this.productIsFree = !hasPaidVariant
            if (showWarning) {
              const freeVariantCount = res.products[0].variants.filter(v => v.price === 0).length
              if (freeVariantCount > 0) {
                this.snackbarService.showWarning({
                  title: `Price of ${freeVariantCount} product variants is ${this.currencyPipe.transform(0, this.userCurrency)}`,
                  text: `${freeVariantCount} of the upsell product variants will be free for customers.`,
                  persist: true
                })
              }
            }
          } else {
            const price = product.variants?.[0]?.price || parseFloat(product?.price || '0')
            this.productIsFree = price === 0
            if (this.productIsFree && showWarning) {
              this.snackbarService.showWarning({
                title: `Product price is ${this.currencyPipe.transform(0, this.userCurrency)}`,
                text: 'Customers will receive the upsell product for free.',
                persist: true
              })
            }
          }

        }
      }))
    } else {
      this.productIsFree = false
    }
  }

  showRepeatErrorIfNeeded() {
    if (this.form.get('trigger').value === UpsellTriggerType.Products
        && (this.form.get('target_variants').value?.length > 1 || this.form.get('target_products').value?.length > 0)
        && this.form.get('no_repeat').value
        && (this.form.get('trigger_variants').value?.length === 1 || this.form.get('trigger_products').value?.length === 1)) {
      const targetProduct = this.form.get('target_products').value?.[0]
      const targetVariant = this.form.get('target_variants').value?.[0]
      const targetVariantRepeats = targetVariant && this.form.get('trigger_variants').value?.length === 1
                                    && this.form.get('trigger_products').value?.length === 0
                                    && this.form.get('trigger_variants').value?.includes(targetVariant)
      const targetProductRepeats = !targetVariant && this.form.get('target_product').value
                                    && this.form.get('trigger_products').value?.length === 1
                                    && this.form.get('trigger_variants').value?.length === 0
                                    && this.form.get('trigger_products').value?.includes(targetProduct)
      if (targetProductRepeats || targetVariantRepeats) {
        this.skipError = true
        this.snackbarService.showError({
          title: 'Upsell Will Never Be Shown',
          text: `Deselect the "Skip Upsell" checkbox or choose a different Trigger or Upsell Product so they're not identical.`,
          persist: true
        })
      } else {
        this.skipError = false
        this.snackbarService.dismiss()
      }
    } else {
      this.skipError = false
      this.snackbarService.dismiss()
    }
  }

  private exit() {
    this.router.navigate([
      `/upsell`
    ])
  }

  private setFooterbarButtons() {
    this.footerbarNavigationService.clearData()

    this.footerbarNavigationService.setPrimaryBtnText('Next')
    this.footerbarNavigationService.setPrimaryBtnDisabled(false)
    this.footerbarNavigationService.setPrimaryBtnAction(this.next.bind(this))

    this.footerbarNavigationService.setTertiaryBtnText('Save')
    this.footerbarNavigationService.setTertiaryBtnDisabled(false)
    this.footerbarNavigationService.setTertiaryBtnAction(this.save.bind(this))

    this.footerbarNavigationService.setSecondaryBtnText('Exit')
    this.footerbarNavigationService.setSecondaryBtnDisabled(false)
    this.footerbarNavigationService.setSecondaryBtnAction(this.exit.bind(this))
  }

  setSavableData() {
    this.storeSavableData({
      ...this.form.getRawValue()
    })
  }

  checkIfChanged() {
    this.compareSavableData({
      ...this.form.getRawValue()
    })
  }

  onNumberInputClick(event) {
    // when clicking on the number spinners in FF browser focus is not triggered, so need to do it here
    const isFirefoxBrowser = window.navigator.userAgent.toLowerCase().includes('firefox')
    if (isFirefoxBrowser) {
      event.target.focus()
    }
  }

  ngOnDestroy(): void {
      this.subscription$.unsubscribe()
      this.footerbarNavigationService.clearData()
  }
}
