import { Component, Inject, OnInit } from '@angular/core'
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms'
import { BroadcastNewsletterEmailType } from '../../../../pages/email-builder/models/email-editor.models'
import { AutomationTypes, HolidayTypes } from '../../promotion-cards/models/promotion.models'
import { AutomationType } from '../../../../pages/sms/modules/sms-automations/models/automation';
import { CampaignPluginName } from '../../../models/campaign/campaign'
import { EmailTypeEnum } from '../../../../pages/emails/enums/email-type.model'
import { SegmentAnalyticsService } from '../../../services/segment-analytics.service'
import { CommonModule } from '@angular/common'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';


@Component({
    templateUrl: './holiday-promotion-modal.component.html',
    styleUrls: ['./holiday-promotion-modal.component.scss'],
    imports: [
        CommonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
    ]
})

export class HolidayPromotionModalComponent implements OnInit {

  pluginTypes = CampaignPluginName
  emailTypes = BroadcastNewsletterEmailType
  popupSelect = new UntypedFormControl(this.pluginTypes.FortuneWheel)
  emailSelect = new UntypedFormControl(this.emailTypes.broadcastCoupon)
  broadcastPresets = [
    {
      name: 'Newsletter Email (With Reward)',
      broadcastType: BroadcastNewsletterEmailType.broadcastCoupon,
      description: {
        shopify: 'Offer discount or gift card to incentivize customers and leads to buy!',
        otherShop: 'Offer discount to incentivize customers and leads to buy!',
        nonShop: 'Offer discount or download link to incentivize customers and leads to take action!'
      },
      subject: 'Surprise! Here’s a VIP Discount',
      image: 'themes/emails/presets/discount.svg',
      couponRequired: true,
      promotion: this.data,
      type: EmailTypeEnum.BroadcastEmail
    },
    {
      name: 'Newsletter Email (No Reward)',
      broadcastType: BroadcastNewsletterEmailType.broadcastNewsletter,
      description: 'Give subscribers product and other business updates!',
      subject: 'Hello! Thank you for your interest.',
      image: 'themes/emails/presets/newsletter.svg',
      couponRequired: false,
      promotion: this.data,
      type: EmailTypeEnum.BroadcastEmail
    },
  ]

  left: any = {
    [HolidayTypes.Double11]: {
      title: 'Singles\' Day 11.11 Pop ups',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Double 11', '光棍节'],
      image: 'promotion-cards/double_11_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.Thanksgiving]: {
      title: 'Thanksgiving Pop ups',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Thanksgiving', 'Turkey Day'],
      image: 'promotion-cards/thanksgiving_popups.svg',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.Halloween]: {
      title: 'Halloween Pop ups',
      subtitle: 'Get in the spirit with spooky pop up designs.',
      tags: ['Halloween'],
      image: 'promotion-cards/halloween_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.BlackFriday]: {
      title: 'Black Friday Pop ups',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Black Friday', 'Cyber Monday', 'Cyber Week'],
      image: 'promotion-cards/black_friday_popups.svg',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.CyberMonday]: {
      title: 'Cyber Monday Pop ups',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Cyber Monday', 'Cyber Week'],
      image: 'promotion-cards/cyber_monday_popups.svg',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.CyberWeek]: {
      title: 'Cyber Week Pop ups',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Cyber Week'],
      image: 'promotion-cards/cyber_week_popups.svg',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.Christmas]: {
      title: 'Christmas Pop ups',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Christmas'],
      image: 'promotion-cards/christmas_popups.svg',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.Hanukkah]: {
      title: 'Hanukkah Popups',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Hanukkah'],
      image: 'promotion-cards/hanukkah_popups.svg',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.NewYear]: {
      title: 'New Year Pop ups',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['New Year', 'Lunar New Year', 'Chinese New Year'],
      image: 'promotion-cards/new_year_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.LunarNewYear]: {
      title: 'Lunar New Year Pop ups',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Lunar New Year'],
      image: 'promotion-cards/lunar_new_year_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.ValentinesDay]: {
      title: `Valentine's Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Valentine's Day`],
      image: 'promotion-cards/valentines_day_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.LanternFestival]: {
      title: `Lantern Festival Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Lantern Festival`],
      image: 'promotion-cards/lantern_festival_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.StPatricksDay]: {
      title: `St. Patrick's Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`St. Patrick's Day`],
      image: 'promotion-cards/st_patricks_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.WomensDay]: {
      title: `Women's Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Women's Day`],
      image: 'promotion-cards/womens_day_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.HoliFestival]: {
      title: `Holi Festival Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Holi Festival`],
      image: 'promotion-cards/holi_festival_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.MothersDay]: {
      title: `Mother's Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Mother's Day`],
      image: 'promotion-cards/mothers_day_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.Easter]: {
      title: `Easter Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Easter`],
      image: 'promotion-cards/easter_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.Ramadan]: {
      title: `Ramadan Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Ramadan`],
      image: 'promotion-cards/ramadan_popups.svg',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.LaborDay]: {
      title: `Labor Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Labor Day`],
      image: 'promotion-cards/labor_day_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.CincoDeMayo]: {
      title: `Cinco de Mayo Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Cinco de Mayo`],
      image: 'promotion-cards/cinco_de_mayo_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.FathersDay]: {
      title: `Father's Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Father's Day`],
      image: 'promotion-cards/fathers_day_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.MemorialDay]: {
      title: `Memorial Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Memorial Day`],
      image: 'promotion-cards/memorial_day_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.ChildrensDay]: {
      title: `Children's Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Children's Day`],
      image: 'promotion-cards/childrens_day_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.FirstDayOfSummer]: {
      title: `First Day of Summer Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Summer`],
      image: 'promotion-cards/summer_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.IndependenceDay]: {
      title: `Independence Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Independence Day`],
      image: 'promotion-cards/independence_day_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.EidAlAdha]: {
      title: `Eid al-Adha Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Eid al-Adha`],
      image: 'promotion-cards/eid_al_adha_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.PrideMonth]: {
      title: `Pride Month Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Pride Month`],
      image: 'promotion-cards/pride_month_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.IndiaIndependenceDay]: {
      title: `India Independence Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Independence Day (India)`],
      image: 'promotion-cards/india_independence_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.PakistanIndependenceDay]: {
      title: `Pakistan Independence Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Independence Day (Pakistan)`],
      image: 'promotion-cards/pakistan_independence_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.RakshaBandhanDay]: {
      title: `Raksha Bandhan Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Raksha Bandhan`],
      image: 'promotion-cards/raksha_bandhan_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.UkraineIndependenceDay]: {
      title: `Ukraine Independence Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Independence Day (Ukraine)`],
      image: 'promotion-cards/ukraine_independence_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.MidAutumnFestival]: {
      title: `Mid-Autumn Festival Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Mid-Autumn Festival`],
      image: 'promotion-cards/mid_autumn_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.NavratriFestival]: {
      title: `Navratri Festival Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Navratri`],
      image: 'promotion-cards/navratri_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.NigeriaIndependenceDay]: {
      title: `Nigeria Independence Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Independence Day (Nigeria)`],
      image: 'promotion-cards/nigeria_independence_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.EidMiladUnNabi]: {
      title: `Eid Milad-un-Nabi Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Eid Milad-un-Nabi`],
      image: 'promotion-cards/eid_milad_un_nabi_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.DoubleTenthDay]: {
      title: `Double Tenth National Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Double Tenth National Day`],
      image: 'promotion-cards/double_tenth_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.Diwali]: {
      title: `Diwali Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Diwali`],
      image: 'promotion-cards/diwali_popups.png',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.Mashujaa]: {
      title: `Mashujaa Day Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Mashujaa Day`],
      image: 'promotion-cards/mashujaa_popups.svg',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.DiaDeMuertos]: {
      title: `Día de Muertos Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Día de Muertos`],
      image: 'promotion-cards/dia-de-muertos_popups.svg',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [HolidayTypes.SuperBowl]: {
      title: `Super Bowl Pop ups`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Super Bowl`],
      image: 'promotion-cards/super_bowl_popups.svg',
      popupDropdown: true,
      action: this.clickPopup.bind(this)
    },
    [AutomationTypes.CartRecovery]: {
      title: 'Cart Recovery Emails',
      subtitle: 'Reduce cart abandonment and drive sales with a reminder email.',
      tags: ['Cart Recovery'],
      image: 'promotion-cards/cart_recovery_emails.png',
      hideDropdown: true,
      buttonText: 'Create',
      action: this.clickEmailAutomation.bind(this)
    },
    [AutomationTypes.Upsell]: {
      title: 'Upsell Emails',
      subtitle: 'Recommend a product to the customer based on what they purchased.',
      tags: ['Upsell'],
      image: 'promotion-cards/upsell_emails.png',
      hideDropdown: true,
      buttonText: 'Create',
      action: this.clickEmailAutomation.bind(this)
    },
    [AutomationTypes.ThankYou]: {
      title: 'Thank You Email (Shopify Subscribers)',
      subtitle: 'Thank new customers who subscribed through Shopify footer section, checkout, password, or coming soon pages.',
      tags: ['Thank you for subscribing'],
      image: 'promotion-cards/thank-you-emails.svg',
      hideDropdown: true,
      buttonText: 'Create',
      action: this.clickEmailAutomation.bind(this)
    },
    [AutomationTypes.CheckoutUpsell]: {
      title: 'One-click Checkout Upsell',
      subtitle: 'Increase your average order value by suggesting a related product that your customers can add to their order with just one click.',
      tags: ['Post-Purchase Upsell'],
      image: 'apps/post-checkout-upsell.svg',
      hideDropdown: true,
      buttonText: 'Create',
      action: this.closeWithType.bind(this)
    }
  }
  right: any = {
    [HolidayTypes.Double11]: {
      title: 'Singles\' Day 11.11 Emails',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Double 11', '光棍节'],
      image: 'promotion-cards/double_11_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.Thanksgiving]: {
      title: 'Thanksgiving Emails',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Thanksgiving', 'Turkey Day'],
      image: 'promotion-cards/thanksgiving_emails.svg',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.Halloween]: {
      title: 'Halloween Emails',
      subtitle: 'Offer spooky product updates and promotions.',
      tags: ['Halloween'],
      image: 'promotion-cards/halloween_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.BlackFriday]: {
      title: 'Black Friday Emails',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Black Friday', 'Cyber Monday', 'Cyber Week'],
      image: 'promotion-cards/black_friday_emails.svg',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.CyberMonday]: {
      title: 'Cyber Monday Emails',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Cyber Monday', 'Cyber Week'],
      image: 'promotion-cards/cyber_monday_emails.svg',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.CyberWeek]: {
      title: 'Cyber Week Emails',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Cyber Week'],
      image: 'promotion-cards/cyber_week_emails.svg',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.Christmas]: {
      title: 'Christmas Emails',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Christmas'],
      image: 'promotion-cards/christmas_emails.svg',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.Hanukkah]: {
      title: 'Hanukkah Emails',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Hanukkah'],
      image: 'promotion-cards/hanukkah_emails.svg',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.NewYear]: {
      title: 'New Year Emails',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['New Year', 'Lunar New Year', 'Chinese New Year'],
      image: 'promotion-cards/new_year_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.LunarNewYear]: {
      title: 'Lunar New Year Emails',
      subtitle: 'Are you ready for the sales event of the year?',
      tags: ['Lunar New Year'],
      image: 'promotion-cards/lunar_new_year_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.ValentinesDay]: {
      title: `Valentine's Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Valentine's Day`],
      image: 'promotion-cards/valentines_day_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.LanternFestival]: {
      title: `Lantern Festival Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Lantern Festival`],
      image: 'promotion-cards/lantern_festival_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.StPatricksDay]: {
      title: `St. Patrick's Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`St. Patrick's Day`],
      image: 'promotion-cards/st_patricks_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.WomensDay]: {
      title: `Women's Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Women's Day`],
      image: 'promotion-cards/womens_day_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.HoliFestival]: {
      title: `Holi Festival Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Holi Festival`],
      image: 'promotion-cards/holi_festival_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.MothersDay]: {
      title: `Mother's Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Mother's Day`],
      image: 'promotion-cards/mothers_day_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.Easter]: {
      title: `Easter Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Easter`],
      image: 'promotion-cards/easter_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.Ramadan]: {
      title: `Ramadan Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Ramadan`],
      image: 'promotion-cards/ramadan_emails.svg',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.LaborDay]: {
      title: `Labor Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Labor Day`],
      image: 'promotion-cards/labor_day_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.CincoDeMayo]: {
      title: `Cinco de Mayo Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Cinco de Mayo`],
      image: 'promotion-cards/cinco_de_mayo_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.FathersDay]: {
      title: `Father's Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Father's Day`],
      image: 'promotion-cards/fathers_day_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.MemorialDay]: {
      title: `Memorial Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Memorial Day`],
      image: 'promotion-cards/memorial_day_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.ChildrensDay]: {
      title: `Children's Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Children's Day`],
      image: 'promotion-cards/childrens_day_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.FirstDayOfSummer]: {
      title: `First Day of Summer Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Summer`],
      image: 'promotion-cards/summer_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.IndependenceDay]: {
      title: `Independence Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Independence Day`],
      image: 'promotion-cards/independence_day_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.EidAlAdha]: {
      title: `Eid al-Adha Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Eid al-Adha`],
      image: 'promotion-cards/eid_al_adha_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.PrideMonth]: {
      title: `Pride Month Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Pride Month`],
      image: 'promotion-cards/pride_month_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.IndiaIndependenceDay]: {
      title: `India Independence Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Independence Day (India)`],
      image: 'promotion-cards/india_independence_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.PakistanIndependenceDay]: {
      title: `Pakistan Independence Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Independence Day (Pakistan)`],
      image: 'promotion-cards/pakistan_independence_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.RakshaBandhanDay]: {
      title: `Raksha Bandhan Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Raksha Bandhan`],
      image: 'promotion-cards/raksha_bandhan_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.UkraineIndependenceDay]: {
      title: `Ukraine Independence Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Independence Day (Ukraine)`],
      image: 'promotion-cards/ukraine_independence_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.MidAutumnFestival]: {
      title: `Mid-Autumn Festival Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Mid-Autumn Festival`],
      image: 'promotion-cards/mid_autumn_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.NavratriFestival]: {
      title: `Navratri Festival Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Navratri`],
      image: 'promotion-cards/navratri_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.NigeriaIndependenceDay]: {
      title: `Nigeria Independence Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Independence Day (Nigeria)`],
      image: 'promotion-cards/nigeria_independence_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.EidMiladUnNabi]: {
      title: `Eid Milad-un-Nabi Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Eid Milad-un-Nabi`],
      image: 'promotion-cards/eid_milad_un_nabi_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.DoubleTenthDay]: {
      title: `Double Tenth National Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Double Tenth National Day`],
      image: 'promotion-cards/double_tenth_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.Diwali]: {
      title: `Diwali Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Diwali`],
      image: 'promotion-cards/diwali_emails.png',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.Mashujaa]: {
      title: `Mashujaa Day Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Mashujaa Day`],
      image: 'promotion-cards/mashujaa_emails.svg',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.DiaDeMuertos]: {
      title: `Día de Muertos Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Día de Muertos`],
      image: 'promotion-cards/dia-de-muertos_emails.svg',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [HolidayTypes.SuperBowl]: {
      title: `Super Bowl Emails`,
      subtitle: 'Are you ready for the sales event of the year?',
      tags: [`Super Bowl`],
      image: 'promotion-cards/super_bowl_emails.svg',
      emailDropdown: true,
      action: this.clickBroadcastEmail.bind(this)
    },
    [AutomationTypes.CartRecovery]: {
      title: 'Cart Recovery SMS',
      subtitle: 'Reduce cart abandonment with 98% text message open rates.',
      tags: ['Cart Recovery'],
      image: 'promotion-cards/cart_recovery_sms.png',
      buttonText: 'Create',
      action: this.clickSmsAutomation.bind(this)
    },
    [AutomationTypes.Upsell]: {
      title: 'Upsell SMS',
      subtitle: 'Recommend a product to the customer based on what they purchased.',
      tags: ['Upsell'],
      image: 'promotion-cards/upsell_sms.png',
      buttonText: 'Create',
      action: this.clickSmsAutomation.bind(this)
    },
  }
  promotions = [
    this.left,
    // this.right
    ]

  constructor(
    public dialogRef: MatDialogRef<HolidayPromotionModalComponent>,
    private segmentAnalyticsService: SegmentAnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: HolidayTypes
  ) { }

  ngOnInit(): void {
    this.dialogRef.addPanelClass(['_no-padding', '_custom-scrollbar'])
  }

  clickPopup() {
    this.trackClickCreateEvent('Clicked Create Holiday', this.popupSelect.value)
    this.dialogRef.close({ type: 'popup', data: this.popupSelect.value })
  }

  trackClickCreateEvent(eventName: string, value: string) {
    this.segmentAnalyticsService.track(eventName, {
      metadata: {
        value: value
      }
    })
  }

  clickBroadcastEmail() {
    this.trackClickCreateEvent('Clicked Create Holiday', this.emailSelect.value)
    const preset = this.broadcastPresets.find(_preset => _preset.broadcastType === this.emailSelect.value)
    this.dialogRef.close({ data: preset, type: 'email' })
  }

  clickEmailAutomation(type) {
    if (type === AutomationTypes.CartRecovery) {
      this.trackClickCreateEvent('Clicked Create in Featured Apps Banner - Cart Abandon', type)
      this.dialogRef.close({data: '/email-automation-builder/cart-recovery/theme', type: 'redirect'})
    } else if (type === AutomationTypes.Upsell) {
      this.dialogRef.close({data: '/email-automation-builder/upsell/theme', type: 'redirect'})
    } else if (type === AutomationTypes.ThankYou) {
      this.dialogRef.close({data: '/email-automation-builder/welcome-email/thank-you/theme', type: 'redirect'})
    }
  }

  clickSmsAutomation(type) {
    if (type === AutomationTypes.CartRecovery) {
      this.trackClickCreateEvent('Clicked Create in Featured Apps Banner - Cart Abandon', type)
      this.dialogRef.close({data: `messaging/sms/automations/new?type=${AutomationType.cart}`, type: 'redirect'})
    } else if (type === AutomationTypes.Upsell) {
      this.dialogRef.close({data: `/messaging/sms/automations/new?type=${AutomationType.upsell}`, type: 'redirect'})
    }
  }

  closeWithType(type) {
    this.trackClickCreateEvent('Clicked Create in Featured Apps Banner - Upsell', type)
    this.dialogRef.close({type})
  }
}
