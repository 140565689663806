import { Component, Inject, type OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { JsonViewModule } from 'nxt-json-view'

@Component({
    imports: [
        JsonViewModule,
    ],
    templateUrl: './json-viewer-modal.component.html',
    styleUrl: './json-viewer-modal.component.scss'
})

export class JsonViewerModalComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<JsonViewerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void { }

}
